import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage } from 'react-intl';
import { Message, formatHelpers, PageHeader } from '@vaultit/stv-utils-frontend';
import backToWaitingOrdersBtnClicked from '../../actions/actionCreators/cards/BackToWaitingOrdersBtnClicked.js';
import ReadyToActivateCard from "../common/ReadyToActivateCard.jsx";
import ShowPinCode from "../common/ShowPinCode.jsx";



class CardOrdersList extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {

        let orderInfo = this.state.pendingCardOrdersStore.cardOrdersInfo;
        let orderInfoLoaded = this.state.pendingCardOrdersStore.orderInfoLoaded;
        let hasOrdersToAction = this.state.pendingCardOrdersStore.readyToActivateCardOrdersList.length !== 0 || this.state.pendingCardOrdersStore.pendingCardOrdersList.length !== 0;

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="pendingCardOrder.orders"
                    sharedStore={this.state.shared}
                />

                { orderInfo && orderInfo.hasOrders
                ?
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="pendingCardOrder.companyName" defaultMessage="Company name" />
                                </th>
                                <th>
                                    <FormattedMessage id="pendingCardOrder.modifiedDate" defaultMessage="Modified" />
                                </th>
                                <th>
                                    <FormattedMessage id="pendingCardOrder.cardValidFrom" defaultMessage="Valid from" />
                                </th>
                                <th>
                                    <FormattedMessage id="pendingCardOrder.cardValidUntil" defaultMessage="Valid until" />
                                </th>
                                <th>
                                    <FormattedMessage id="pendingCardOrder.cardStatus" defaultMessage="Card status" />
                                </th>
                                <th>
                                    <FormattedMessage id="pendingCardOrder.pinCode" defaultMessage="Pin code" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderInfo.orders.length
                                ?
                                orderInfo.orders.map((order, index) => {
                                    let id = 'pending_card_orders_table_' + index;

                                    let statusTranslation = order.status;
                                    let trasnlationKeyForCardStatus = formatHelpers.formatStatusToTranslationKey('cardapi.cardStatus.', order.status);
                                    let trasnlationKeyForPersonalCardStatus = formatHelpers.formatStatusToTranslationKey('cardapi.cardStatus.personal.', order.status);

                                    if (this.context.intl.messages[trasnlationKeyForPersonalCardStatus]) {
                                        statusTranslation = this.context.intl.messages[trasnlationKeyForPersonalCardStatus];
                                    } else if (this.context.intl.messages[trasnlationKeyForCardStatus]) {
                                        statusTranslation = this.context.intl.messages[trasnlationKeyForCardStatus];
                                    }

                                    let orderModificationDate = order.statusModified ? order.statusModified.substring(0,10) : order.statusModified;

                                    return (
                                        <tr id={id} key={index} onClick={order.handleRowClick} role="button">
                                            <td>{order.orgName}</td>
                                            <td>{orderModificationDate}</td>
                                            <td>{order.validFrom}</td>
                                            <td>{order.validUntil}</td>
                                            <td>
                                                <ReadyToActivateCard order={order} statusTranslation={statusTranslation} />
                                            </td>
                                            <td>
                                                <ShowPinCode order={order} />
                                            </td>
                                        </tr>
                                    );
                                })
                                : null
                            }
                        </tbody>
                    </table>
                : null
                }

                { orderInfoLoaded && orderInfo && !orderInfo.hasOrders
                ?
                    <Message id="pending_card_order_no_order_msg" type="info" className="mt-6">
                        <FormattedMessage
                            id="pendingCardOrder.noOrders"
                            defaultMessage="You have no pending card orders"
                        />.
                    </Message>
                : null
                }
                { orderInfoLoaded && hasOrdersToAction
                ?
                    <button
                        id="view_all_card_orders_back"
                        className="btn btn-lg mr-3 btn-primary"
                        role="button"
                        onClick={backToWaitingOrdersBtnClicked}
                    >
                        <FormattedMessage id="viewCardOrder.backToDashboard" defaultMessage="Back to Dashboard" />
                    </button>
                : null
                }
            </div>
        );
    }


}
export default CardOrdersList;
