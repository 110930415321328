import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let signInBtnClicked = function() {
    dispatcher.dispatch({
        type: ActionTypes.SIGN_IN_BTN_CLICKED
    });
};

export default signInBtnClicked;