import React from 'react';
import { Router, Route, IndexRoute, Redirect, hashHistory } from 'react-router';
import routeOnEnterHook from './helpers/RouteOnEnterHook.js';

import MasterPage from './components/MasterPage.jsx';
import PageLoader from './components/PageLoader.jsx';
import Http404 from './components/Http404.jsx';
import ProblemReport from './components/ProblemReport.jsx';
import GeneralError from './components/GeneralError.jsx';

import CardReviewLandingPage from './components/cards/CardReviewLandingPage.jsx';
import PendingCardOrders from './components/cards/PendingCardOrders.jsx';
import CardStatusChangePage from './components/cards/CardStatusChangePage.jsx';
import CardsWaitingActionsPage from './components/cards/CardsWaitingActionsPage.jsx';
import CardOrderList from './components/cards/CardOrdersList.jsx';
import OrganizationCardList from './components/cards/OrganizationCardList.jsx';
import VisitManualIDVerifier from './components/cards/VisitManualIDVerifier.jsx';
import CardsGlobalSearchUser from './components/cards/CardsGlobalSearchUser.jsx';

class Routes extends React.Component {

    render() {
        return (
            <Router history={hashHistory} onUpdate={() => window.scrollTo(0, 0)}>
                <Route path="/" component={ MasterPage }>
                    <IndexRoute onEnter={routeOnEnterHook} component={ PageLoader } />
                    <Route onEnter={routeOnEnterHook} path="error/:key" component={ GeneralError } />
                    <Route onEnter={routeOnEnterHook} path="report" component={ ProblemReport } />
                    <Route onEnter={routeOnEnterHook} path="confirm-order(/:cardSupplier)" component={ CardReviewLandingPage } />
                    <Route onEnter={routeOnEnterHook} path="search-users-globally/user-cards-list/:userId" component={ CardsGlobalSearchUser } />
                    <Route onEnter={routeOnEnterHook} path="open-my-cards(/:cardSupplier)" />
                    <Route onEnter={routeOnEnterHook} path="open-my-cards-after-authentication" />
                    <Redirect from="cards" to="cards/waiting-orders" />
                    <Route onEnter={routeOnEnterHook} path="cards">
                        <Route onEnter={routeOnEnterHook} path="order(/:orderId)" component={ PendingCardOrders } />
                        <Route onEnter={routeOnEnterHook} path="view-order/:orderId" component={ PendingCardOrders } />
                        <Route onEnter={routeOnEnterHook} path="reject-card-oder/:orderId" component={ CardStatusChangePage } />
                        <Route onEnter={routeOnEnterHook} path="suspend-card-order/:orderId" component={ CardStatusChangePage } />
                        <Route onEnter={routeOnEnterHook} path="terminate-card-order/:orderId" component={ CardStatusChangePage } />
                        <Route onEnter={routeOnEnterHook} path="reactivate-card-order/:orderId" component={ CardStatusChangePage } />
                        <Route onEnter={routeOnEnterHook} path="waiting-orders(/:status)" component={ CardsWaitingActionsPage } />
                        <Route onEnter={routeOnEnterHook} path="all" component={CardOrderList} />
                        <Route onEnter={routeOnEnterHook} path="visit-manual-id-partner" component={ VisitManualIDVerifier } />
                    </Route>
                    <Route path="/:organizationCode/">
                        <IndexRoute onEnter={routeOnEnterHook} component={ PageLoader } />
                        <Redirect from="home" to="/:organizationCode/" />
                        <Redirect from="organization" to="organization/cards" />
                        <Route path="organization">
                            <Route onEnter={routeOnEnterHook} path="cards/view-order/:orderId(/:hideBack)" component={ PendingCardOrders } />
                            <Route onEnter={routeOnEnterHook} path="cards/suspend-card-order/:orderId" component={ CardStatusChangePage } />
                            <Route onEnter={routeOnEnterHook} path="cards/terminate-card-order/:orderId" component={ CardStatusChangePage } />
                            <Route onEnter={routeOnEnterHook} path="cards/reactivate-card-order/:orderId" component={ CardStatusChangePage } />
                            <Route onEnter={routeOnEnterHook} path="cards(/:pageNumber)" component={ OrganizationCardList } />
                            <Route onEnter={routeOnEnterHook} path=":cpbOrganizationId" >
                                <Route onEnter={routeOnEnterHook} path="cards" component={ OrganizationCardList } />
                                <Route onEnter={routeOnEnterHook} path="cards/view-order/:orderId" component={ PendingCardOrders } />
                            </Route>
                        </Route>
                    </Route>
                    <Route onEnter={routeOnEnterHook} path="*" component={ Http404 } />
                </Route>
            </Router>
        );
    }
}

export default Routes;
