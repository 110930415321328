import cookie from 'react-cookie';
import constants from '../Constants.js';
import BaseStore from './base/BaseStore.js';
import prepareUrl from '../helpers/PrepareUrl.js';
import redirectToUrl from '../actions/actionCreators/navigation/RedirectToUrl.js';
import redirectToRoute from '../actions/actionCreators/navigation/RedirectToRoute.js';

class PageLoaderStore extends BaseStore {

    getInitialState() {
        return {
            userVisitedManualIdPartnerPage: null
        };
    }

    onAnyAction(state, action, sharedStoreState) {

        if (sharedStoreState.authInfo
            && !sharedStoreState.loadingAuthInfo
            && !sharedStoreState.savingUsedLanguage) {

            let isInHomePage = sharedStoreState.currentRoute.fullPath === '/';
            let uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
            let servicePortalCode = uiConfiguration['servicePortalServiceCode'];
            let servicePortalUrl = uiConfiguration.services[servicePortalCode].url;

            let redirectToServicePortal = this.userHasPermission(sharedStoreState, 'SEPPO_REDIRECT_TO_SERVICE_PORTAL');

            state.userVisitedManualIdPartnerPage = cookie.load(constants.cookieNames.userVisitedManualIdPartnerPage);

            if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VISIT_MANUAL_ID_PARTNER') && !state.userVisitedManualIdPartnerPage) {
                cookie.save(constants.cookieNames.userVisitedManualIdPartnerPage, true);
                state.userVisitedManualIdPartnerPage = cookie.load(constants.cookieNames.userVisitedManualIdPartnerPage)
                redirectToRoute("/cards/visit-manual-id-partner");
            } else if (redirectToServicePortal) {
                redirectToUrl(servicePortalUrl, true, true);
            } else if (isInHomePage) {
                if (sharedStoreState.isSignedIn) {
                    if (!this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VISIT_MANUAL_ID_PARTNER') && state.userVisitedManualIdPartnerPage) {
                        cookie.remove(constants.cookieNames.userVisitedManualIdPartnerPage);
                        state.userVisitedManualIdPartnerPage = false;
                    }

                    let isInCompanyContext = sharedStoreState.representedOrganizationCode;
                    
                    if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_FORCE_REVIEW_PENDING_CARD_ORDERS')) {
                        redirectToRoute("/cards/waiting-orders");
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_FORCE_USER_VISIT_CARD_LANDING_PAGE')) {
                        let cardsLandingPageUrl = prepareUrl.prepareAbsoluteUrl('/cards/confirm-order');
                        redirectToUrl(cardsLandingPageUrl);
                    }  else {
                        if (isInCompanyContext) {
                            redirectToRoute("/organization/cards", true, false, sharedStoreState.representedOrganizationCode);
                        } else {
                            let relativeUrl = '/cards/my-cards';
                            let absoluteUrl = prepareUrl.prepareAbsoluteUrl(relativeUrl);
                            redirectToUrl(absoluteUrl);
                        }
                    }
                } else {
                    let urlFromCfg = uiConfiguration.urlToRedirectAnonymousUsers;
                    sharedStoreState.showOnlyLoadingIndicator = true;
                    if (urlFromCfg) {
                        redirectToUrl(urlFromCfg, false, true);
                    } else {
                        redirectToUrl(prepareUrl.getSignInRelativeUrl());
                    }
                }
            }
        }
    }
}

export default PageLoaderStore;
