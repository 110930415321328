import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let confirmCardOrder = function (orderId, data) {
    postToBackend(`/cards/confirm-card-order/${orderId}`,
        ActionTypes.CONFIRM_CARD_ORDER_SUCCESS,
        ActionTypes.CONFIRM_CARD_ORDER_ERROR,
        data
    );
};
export default confirmCardOrder;
