import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let hideCardHistoryBtnClicked = function(event, cardId, index) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.HIDE_CARD_HISTORY_BTN_CLICKED,
        cardId: cardId,
        index: index
    });
};

export default hideCardHistoryBtnClicked;
