import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let showCardHistoryBtnClicked = function(event, cardId, index) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.SHOW_CARD_HISTORY_BTN_CLICKED,
        cardId: cardId,
        index: index
    });
};

export default showCardHistoryBtnClicked;
