import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let orgCardOrderRowClickedHandler = function(orderId, orgId=false, redirectToCpbList=false) {
    let cardOrderRowClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.ORGANIZATION_CARD_ORDER_LIST_ROW_CLICKED,
            orderId: orderId,
            orgId: orgId,
            redirectToCpbList: redirectToCpbList
        });
    };

    return cardOrderRowClicked;
};

export default orgCardOrderRowClickedHandler;