import log from './Log.js';

class RedirectManager {

    constructor() {
        this.redirectingTo = null;
    }

    registerNeededRedirect(url, withoutAbilityToGoBack, inNewWindow, reloadAppAfterwards, withoutWaiting) {
        if (!this.neededRedirect) {
            this.neededRedirect = {
                url: url,
                withoutAbilityToGoBack: withoutAbilityToGoBack,
                inNewWindow: inNewWindow,
                reloadAppAfterwards: reloadAppAfterwards,
                withoutWaiting: withoutWaiting
            };
        }
    }

    redirectIfPossible(sharedStoreState) {
        if (this.neededRedirect) {
            if (this.redirectingTo !== this.neededRedirect.url) {
                let shouldRedirect = this.neededRedirect.withoutWaiting;

                if (!shouldRedirect) {
                    shouldRedirect = sharedStoreState.authInfo && !sharedStoreState.loadingAuthInfo;
                }

                if (shouldRedirect) {
                    this.redirectingTo = this.neededRedirect.url;
                    this.actuallyDoRedirect(this.neededRedirect.url, this.neededRedirect.withoutAbilityToGoBack, this.neededRedirect.inNewWindow, this.neededRedirect.reloadAppAfterwards);
                    this.neededRedirect = null;
                }
            }
        } else {
            this.redirectingTo = null;
        }
    }

    actuallyDoRedirect(url, withoutAbilityToGoBack, inNewWindow, reloadAppAfterwards) {

        console.log('Redirecting to:');
        console.log(url);

        if (inNewWindow) {
            log.addLocationChange(url, 'window.open(...)');
            let newWindow = window.open(url, '_blank');
            if (newWindow.opener) {
                newWindow.opener = null;
            }
        } else if (withoutAbilityToGoBack) {
            log.addLocationChange(url, 'window.location.replace(...)');
            window.location.replace(url);

            if (reloadAppAfterwards) {
                window.location.reload();
            }
        } else {
            log.addLocationChange(url, 'window.location.href = ...');
            window.location.href = url;

            if (reloadAppAfterwards) {
                window.location.reload();
            }
        }
        this.redirectingTo = null;
    }
}

let redirectManager = new RedirectManager();

export default redirectManager;
