import cookie from 'react-cookie';
import constants from '../../Constants.js';
import BaseStore from '../base/BaseStore.js';
import ActionTypes from '../../actions/ActionTypes.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import prepareUrl from '../../helpers/PrepareUrl.js';
import log from '../../singletons/Log.js';

class CardReviewLandingPageStore extends BaseStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false,
            moreInfoLink: '',
            signInWithBankLink: '',
            signInWithDokobitLink: '',
            signInWithSmsLink: '',
            signInWithGluu: '',
            personDataPolicyLink: '',
            configuration: {},
            showButtonTextForSignedInUser: false
        };
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/confirm-order(/:cardSupplier)',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CONFIRM_CARD_ORDER',
                loadHandler: this.onConfirmOrderRouteLoaded
            }
        ];
    }

    onConfirmOrderRouteLoaded(state, action, sharedStoreState) {

        state.showLoadingIndicator = false;
        state.showButtonTextForSignedInUser = sharedStoreState.isSignedIn;
        state.configuration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};

        let cardOrderingEnabled = state.configuration.servicePortal.cardOrdering.enabled;
        state.moreInfoLink = cardOrderingEnabled ? state.configuration.servicePortal.cardOrdering.moreInfoLink : '';

        let returnUrl = prepareUrl.prepareRouteUrl('/open-my-cards-after-authentication', false, false);
        returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl);
        let returnUrlEncoded = encodeURIComponent(returnUrl);

        let strongAuthForAnonymousEnabled = state.configuration.servicePortal.strongAuthenticationForAnonymous.enabled;
        state.signInWithBankLink = strongAuthForAnonymousEnabled ? prepareUrl.getStrongAuthForAnonymousPath(state.configuration, returnUrlEncoded, false) : '';
        state.signInWithDokobitLink = strongAuthForAnonymousEnabled ? prepareUrl.getStrongAuthForAnonymousPath(state.configuration, returnUrlEncoded, true) : '';
        state.signInWithGluu = prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl(returnUrlEncoded));

        let sms2FLoginEnabled = state.configuration.servicePortal.smsAuthentication.enabled;
        state.signInWithSmsLink = sms2FLoginEnabled ? prepareUrl.getSmsAuthPath(state.configuration, returnUrlEncoded) : '';

        let tosType = "person";
        state.personDataPolicyLink = "/binary/" + sharedStoreState.tosPrefix + "_" + tosType + "_termsOfService." + sharedStoreState.localizations.messagesLanguage + ".pdf";

        let isSignedIn = false;
        let isStronlyAuthenticated = sharedStoreState.authInfo && sharedStoreState.authInfo.userProfile ? sharedStoreState.authInfo.userProfile.currentlyAuthenticatedWithStrongAuth : false;
        let sms2faInSession = sharedStoreState.authInfo && sharedStoreState.authInfo.userProfile ? sharedStoreState.authInfo.userProfile.sms2faInSession : false;
        let userName = 'anonymous';

        if (sharedStoreState.authInfo) {
            if (sharedStoreState.authInfo.otherUiInfo) {
                isSignedIn = sharedStoreState.authInfo.otherUiInfo.isSignedIn;
            }
            if (sharedStoreState.authInfo.userProfile) {
                userName = sharedStoreState.authInfo.userProfile.username;
            }
        }

        log.logIndividualAction({
            'message': `User entered 'MY CARDS' page. User ${userName} is signed in - ${isSignedIn}, currently strongly authenticated - ${isStronlyAuthenticated} or authenticated with sms - ${sms2faInSession}`
        });
    }

    [ActionTypes.CONFIRM_ORDER_SIGN_IN_BTN_CLICKED](state, action, sharedStoreState) {
        let userName = 'anonymous';
        let method = action.method;
        let url = null;

        if (sharedStoreState.authInfo) {
            if (sharedStoreState.authInfo.userProfile) {
                userName = sharedStoreState.authInfo.userProfile.username;
            }
        }
        if (constants.personVerificationSources.bankIDProvision == method) {
            url = state.signInWithBankLink;
            log.logIndividualAction({
                'message': `User - '${userName}' clicked 'Sign in with BankID' in 'MY CARDS' page`
            });
        } else if (constants.personVerificationSources.dokobitIdentitygwProvision == method) {
            url = state.signInWithDokobitLink;
            log.logIndividualAction({
                'message': `User - '${userName}' clicked 'Sign in after Dokobit' in 'MY CARDS' page`
            });
        } else if (constants.personVerificationSources.sms2FA == method) {
            url = state.signInWithSmsLink;

            cookie.save(constants.cookieNames.userVisitedManualIdPartnerPage, false)
            cookie.remove(constants.cookieNames.userVisitedManualIdPartnerPage);

            log.logIndividualAction({
                'message': `User - '${userName}' clicked 'Sign in after Manual ID verification' in 'MY CARDS' page`
            });
        } else if (constants.personVerificationSources.gluuProvision == method) {
            url = state.signInWithGluu;
            log.logIndividualAction({
                'message': `User - '${userName}' clicked 'Sign in with Gluu' in 'MY CARDS' page`
            });
        }

        redirectToUrl(url, false, true, false);
    }
}

export default CardReviewLandingPageStore;
