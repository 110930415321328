import ActionTypes from '../actions/ActionTypes.js';

import $ from 'jquery';
import request from 'superagent';

const urlParamToTurnSessionStorageLoggingOn = 'logToSessionStorage';

class InMemoryLogStorage {
    constructor() {
        this.events = [];
    }

    load() {
        return this.events;
    }

    save(events) {
        this.events = events;
    }
}

class SessionLogStorage {
    load() {
        let actionLog = sessionStorage.getItem('actionLog');
        return actionLog ? JSON.parse(actionLog) : null;
    }

    save(events) {
        sessionStorage.setItem('actionLog', JSON.stringify(events));
    }
}

class Log {
    constructor() {

        // Session storage may cause some performance problems, use it only for debugging...
        let commandToTurnOnSessionStorage = window.location.href.indexOf(urlParamToTurnSessionStorageLoggingOn) >= 0;
        if (commandToTurnOnSessionStorage) {
            alert('Actions will be logged to session storage');
            sessionStorage.logging = true;
        }

        this.storage = sessionStorage.logging
            ? new SessionLogStorage()
            : new InMemoryLogStorage();

        this.events = this.storage.load();

        if (!this.events) {
            this.events = [];
        }
    }

    setConfig(extensiveLogging) {
        this.extensiveLogging = extensiveLogging;
        // Mark down in global scope, so that robots could get the log:
        let instance = this;
        window["getFrontendActionLog"] = function () {
            return instance.extensiveLogging ? JSON.stringify(instance.getFullLog()) : "Extensive logging is turned off in server";
        };
    }

    getTimestamp() {
        var currentDateTime = new Date();
        return currentDateTime.getFullYear()
            + '-' + currentDateTime.getMonth()
            + '-' + currentDateTime.getDate()
            + ' ' + currentDateTime.getHours()
            + ':' + currentDateTime.getMinutes()
            + ':' + currentDateTime.getSeconds();
    }

    prepareActionForLogging(action) {

        let actionForLogging = Object.assign({}, action);

        if (action.type == ActionTypes.FORM_FIELD_VALUE_CHANGED
            && action.isSensitiveInformation) {
            actionForLogging.newValue = '***';
        }

        return actionForLogging;
    }

    add(eventType, data) {
        let event = {
            timestamp: this.getTimestamp(),
            type: eventType,
            data: data
        };

        this.events.push(event);
        this.storage.save(this.events);

        console.log(eventType + ':');
        console.log(event);

        let clientActionLogEnabled = false;

        if (this.extensiveLogging && clientActionLogEnabled) {
            this.logClientAction(event);
        }
    }

    addApplicationStarted() {
        this.add('FRONTEND APPLICATION STARTED', {});
    }

    addAction(action) {
        let actionForLogging = this.prepareActionForLogging(action);
        this.add('ACTION [' + action.type + ']', actionForLogging);
    }

    addBackendCallForbiddenInfo(actionType) {
        this.add('BACKEND CALL FORBIDDEN', {
            actionType: actionType
        });
    }

    addRouteUnauthorizedInfo(neededPermission, currentPermissions) {
        this.add('ROUTE UNAUTHORIZED', {
            neededPermission: neededPermission,
            currentPermissions: currentPermissions
        });
    }

    addRedirectingAwayOfApp(url) {
        this.redirectingAwayOfApp = true;
        this.add('REDIRECTING AWAY OF APP', url);
    }

    addLocationChange(newLocation, wayToChange) {
        this.add('LOCATION CHANGE', {
            currentLocation: window.location.href,
            newLocation: newLocation,
            wayToChange: wayToChange
        });
    }

    addStartedRequest(url, method, successActionType) {
        this.add('STARTED REQUEST', {
            url: url,
            method: method,
            successActionType: successActionType
        });
    }

    addUnfinishedRequest(err) {
        this.add('UNFINISHED REQUEST', err);
    }

    getFullLog() {
        var eventsCopy = $.extend(true, {}, this.events);
        return eventsCopy;
    }

    getMinimizedLog() {

        var events = $.extend(true, {}, this.events);

        for (var key in events) {
            if (events.hasOwnProperty(key) && events[key].data && events[key].data.result) {
                events[key].data.result = null;
            }
        }

        return events;
    }

    addServerError(err) {
        this.add('SERVER ERROR', err);
    }

    addError(e) {
        // Do not handle js errors if navigation out of this app has already started:
        if (!this.redirectingAwayOfApp) {
            this.logClientError(e.message);
            this.add('CLIENT ERROR', e.message);
            this.handleClientError('Client error', e.message);
        }
    }

    logClientError(error) {
        let data = {
            error: error,
            log: this.getMinimizedLog()
        };

        let postClientErrorUrl = '../api/general/log-client-error';

        let req = request
            .post(postClientErrorUrl);

        req.send(data).end(function () { });
    }

    logClientAction(actionData) {
        let data = {
            clientAction: actionData
        };

        let postClientActionUrl = '../api/general/log-client-action';

        let req = request
            .post(postClientActionUrl);

        req.send(data).end(function () { });
    }

    logIndividualAction(data) {
        let postClientActionUrl = '../api/general/log-individual-action';

        let req = request.post(postClientActionUrl);

        req.send(data).end(function () { });
    }

    renderJsObject(obj, padding) {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] == "object") {

                    let surroundingitem = $("<div/>")
                        .css("padding-left", padding + 'px');
                    let caption = $("<span/>").html(property).css("font-weight", "bold");
                    surroundingitem.append(caption);
                    $('#error-block').append(surroundingitem);

                    this.renderJsObject(obj[property], padding + 10);
                } else {

                    let surroundingitem = $("<div/>")
                        .css("padding-left", padding + 'px');
                    let caption = $("<span/>").html(property + ": ").css("font-weight", "bold");
                    surroundingitem.append(caption);
                    let value = $("<span/>").html(obj[property]);
                    surroundingitem.append(value);

                    $('#error-block').append(surroundingitem);
                }
            }
        }
    }

    handleClientError(errorType, errorItem) {
        if (this.extensiveLogging) {

            let data = {
                error: errorType,
                errorItem: errorItem
            };

            // Print it to the screen, so that robots would definately fail:

            let errorBlock = $("<div id='error-block' />");
            $('body').append(errorBlock);
            $('body > #container').css("display", "none");

            this.renderJsObject(data, 0);
        }
    }
}

let log = new Log();
log.addApplicationStarted();

export default log;
