import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes.js'
import {requiredValidator, getMaxLengthValidator} from '@vaultit/stv-utils-frontend';
import storeFormManager from '../validation/StoreFormManager.js';
import getPendingCardOrders from '../../actions/actionCreators/cards/GetPendingCardOrders.js';
import confirmCardOrder from '../../actions/actionCreators/cards/ConfirmCardOrder';
import saveVirtualCardStatus from '../../actions/actionCreators/cards/SaveVirtualCardStatus';
import activateCardOrder from '../../actions/actionCreators/cards/ActivateCardOrder';
import reactivateCardOrder from '../../actions/actionCreators/cards/ReactivateCardOrder';
import terminateCardOrder from '../../actions/actionCreators/cards/TerminateCardOrder';
import suspendCardOrder from '../../actions/actionCreators/cards/SuspendCardOrder';
import showPin from '../../actions/actionCreators/cards/ShowPin';
import getCardOrderDetails from '../../actions/actionCreators/cards/GetCardOrderDetails.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import cardOrderRowClickedHandler from '../../actions/actionCreators/cards/CardOrderRowClicked.js';
import orgCardOrderRowClickedHandler from '../../actions/actionCreators/cards/OrgCardOrderRowClicked.js';
import rejectCardOrder from '../../actions/actionCreators/cards/RejectCardOrder.js';
import searchCardsForListDisplay from '../../actions/actionCreators/cards/SearchCardsForListDisplay.js';
import prepareUrl from '../../helpers/PrepareUrl.js';
import checkForPendingCardOrder from '../../actions/actionCreators/cards/CheckForPendingCardOrder.js';
import checkIfUserHasPendingOrReadyToActivateCards from '../../actions/actionCreators/cards/CheckIfUserHasPendingOrReadyToActivateCards.js';
import checkIfUserHasActivatedCardsInOrg from '../../actions/actionCreators/cards/CheckIfUserHasActivatedCardsInOrg.js';
import constants from '../../Constants.js';
import reactivateAllCompanyCards from '../../actions/actionCreators/cards/ReactivateAllCompanyCards.js'
import suspendAllCompanyCards from '../../actions/actionCreators/cards/SuspendAllCompanyCards.js'

const storeKey = "pendingCardOrdersStore";

class PendingCardOrdersStore extends FormStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false,
            cardOrderInfo: null,
            cardOrdersInfo: null,
            cardOrderId: '',
            holderImg: {
                content: ''
            },
            showSuccessfullyConfirmedOrderMsg: false,
            showSuccessfullyRejectedOrderMsg: false,
            showSuccessfullyActivatedOrderMsg: false,
            showSuccessfullyReactivatedOrderMsg: false,
            showSuccessfullyTerminatedOrderMsg: false,
            showSuccessfullySuspendedOrderMsg: false,
            cardManagementShowSuccessfullyReactivatedCardMsg: false,
            cardManagementShowSuccessfullyTerminatedCardMsg: false,
            cardManagementShowSuccessfullySuspendedCardMsg: false,
            cardManagementCardReactivated: false,
            cardManagementCardTerminated: false,
            cardManagementCardSuspended: false,
            showThereAreNoPendingOrReadyToActivateCardsMsg: false,
            signInWithBankId: false,
            signInWithDokobit: false,
            reviewCardOrderInfo: false,
            orderInfoLoaded: false,
            currentOrderInfo: {},
            userCanEditOrgCards: false,
            viewPendingCardOrderPage: false,
            holderName: '',
            customerSupportInformation: {},
            currentLanguage: '',
            actionMsgPageAllOrdersReviewed: false,
            signInWithBankLink: '',
            signInWithDokobitLink: '',
            signOutLink: '',
            reviewPendingOrderUrl: '',
            activateWaitingCardsUrl: '',
            noOrdersToReviewMsg: false,
            pendingCardOrdersList: [],
            readyToActivateCardOrdersList: [],
            showLandingCardReviewPage: false,
            cardOrderStatus: '',
            cardOrderAction: '',
            dismissActivateCardModalAfterClick: false,
            showConfirmActivationForCardModal: false,
            showInvalidCardNumberEnteredMsg: false,
            userCameFromAllCards: false,
            showPin: false,
            pin: '',
            alreadyActivatedCardNumber: '',
            showSearchStatusMessages: false,
            currentCardsListPage: 1,
            holderIsViewingCard: false,
            userCanChangeVirtualCardStatus: false,
            virtualCardEnabledSuccessfully: false,
            virtualCardDisabledSuccessfully: false,
            virtualCardIsEnabled: false,
            showPinButton: false,
            hideCardNumberInputModal: false,
            hideBackButton: null,
            configuration: {},
            cardActivationIsForbiden: false,
            showAllCardsSuspendedMsg: false,
            showAllCardsReactivatedMsg: false,
            showCompanyHasToBeActiveMsg: false,
            showLMAcardNotAllowedMsg: false,
            showNordicCardNotAllowedMsg: false,
            disableAcceptOrderBtn: false
        };

        let rejectCardOrderFieldDefinitiona = {
            rejectionReason: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            }
        }

        let cardOrderFieldDefinitions = {
            phone: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            email: {
                defaultValue: '',
                validators: []
            }
        }

        let cardActivationFormFieldDefinitions = {
            cardNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            }
        }

        let showPinFormFieldDefinitions = {
            cardNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            }
        }

        let filterCardsFormFieldDefinitions = {
            usersFilterValue: {
                defaultValue: '',
            },
            usersFilterStatus: {
                defaultValue: 'all',
            }
        };

        let virtualCardFormFieldDefinitions = {
            virtualCardEnabled: {
                defaultValue: false,
            },
        };

        storeFormManager.defineForm(initialState, storeKey, "filterCardsForm", filterCardsFormFieldDefinitions, this.onFilterCardsFormSubmitted);
        storeFormManager.defineForm(initialState, storeKey, 'cardRejectForm', rejectCardOrderFieldDefinitiona, this.onCardRejectFormSubmitted);
        storeFormManager.defineForm(initialState, storeKey, 'cardOrderForm', cardOrderFieldDefinitions, this.onCardOrderFormSubmitted);
        storeFormManager.defineForm(initialState, storeKey, 'cardActivationForm', cardActivationFormFieldDefinitions, this.onCardActivationFormSubmitted);
        storeFormManager.defineForm(initialState, storeKey, 'showPinForm', showPinFormFieldDefinitions, this.onShowPinFormSubmitted);
        storeFormManager.defineForm(initialState, storeKey, 'virtualCardForm', virtualCardFormFieldDefinitions, this.onVirtualCardFormSubmitted);

        return initialState;
    }

    getFieldValueChangedHandlerCfg() {
        return [
            {
                storeKey: storeKey,
                formKey: 'cardOrderForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'cardRejectForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'cardActivationForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'showPinForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'filterCardsForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'virtualCardForm',
                handler: this.virtualCardFormFieldChanged
            }
        ];
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/organization/cards/view-order/:orderId(/:hideBack)',
                loadHandler: this.onViewOrganizationCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_ORGANIZATIONS_CARDS'
            },
            {
                fullPath: '/organization/cards/suspend-card-order/:orderId',
                loadHandler: this.onSuspendCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_EDIT_ORGANIZATIONS_CARDS'
            },
            {
                fullPath: '/organization/cards/terminate-card-order/:orderId',
                loadHandler: this.onTerminateCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_EDIT_ORGANIZATIONS_CARDS'
            },
            {
                fullPath: '/organization/cards/reactivate-card-order/:orderId',
                loadHandler: this.onReactivateCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_EDIT_ORGANIZATIONS_CARDS'
            },
            {
                fullPath: '/organization/cards/waiting-orders(/:status)',
                loadHandler: this.onShowCardActionMsgRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_ORGANIZATIONS_CARDS'
            },
            {
                fullPath: '/cards/order(/:orderId)',
                loadHandler: this.onPendingCardOrdersRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEE_PENDING_CARD_ORDERS'
            },
            {
                fullPath: '/cards/view-order/:orderId',
                loadHandler: this.onViewCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEE_PENDING_CARD_ORDERS'
            },
            {
                fullPath: '/cards/reject-card-oder/:orderId',
                loadHandler: this.onRejectCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEE_PENDING_CARD_ORDERS'
            },
            {
                fullPath: '/cards/suspend-card-order/:orderId',
                loadHandler: this.onSuspendCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEE_PENDING_CARD_ORDERS'
            },
            {
                fullPath: '/cards/terminate-card-order/:orderId',
                loadHandler: this.onTerminateCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEE_PENDING_CARD_ORDERS'
            },
            {
                fullPath: '/cards/reactivate-card-order/:orderId',
                loadHandler: this.onReactivateCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEE_PENDING_CARD_ORDERS'
            },
            {
                fullPath: '/cards/waiting-orders(/:status)',
                loadHandler: this.onShowCardActionMsgRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEE_PENDING_CARD_ORDERS'
            },
            {
                fullPath: '/cards/all',
                loadHandler: this.onCardsListRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEE_PENDING_CARD_ORDERS'
            },
            {
                fullPath: '/organization/cards(/:pageNumber)',
                loadHandler: this.onCardsListRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_ORGANIZATIONS_CARDS'
            },
            {
                fullPath: '/organization/:organizationId/cards(/:pageNumber)',
                loadHandler: this.onCardsListRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_ORGANIZATIONS_CARDS'
            },
            {
                fullPath: '/organization/:organizationId/cards/view-order/:orderId',
                loadHandler: this.onViewOrganizationCardOrderRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_ORGANIZATIONS_CARDS'
            },
            {
                fullPath: '/cards/visit-manual-id-partner',
                loadHandler: this.onVisitManualIdPartnerRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VISIT_MANUAL_ID_PARTNER'
            },
            {
                fullPath: '/open-my-cards(/:cardSupplier)',
                loadHandler: this.onOpenMyCardsRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CURRENT_USER_CONFIRM_CARD_ORDER'
            },
            {
                fullPath: '/open-my-cards-after-authentication',
                loadHandler: this.onOpenMyCardsAfterAuthenticationRouteLoaded
            }
        ];
    }

    onPendingCardOrdersRouteLoaded(state, action, sharedStoreState) {
        state.holderIsViewingCard = true;
        state.showPinButton = true;
        state.showLoadingIndicator = true;
        state.orderInfoLoaded = false;
        state.cardOrderInfo = null;
        state.showSuccessfullyRejectedOrderMsg = false;
        state.showSuccessfullyConfirmedOrderMsg = false;
        state.showSuccessfullyActivatedOrderMsg = false;
        state.showSuccessfullySuspendedOrderMsg = false;
        state.showSuccessfullyReactivatedOrderMsg = false;
        state.showSuccessfullyTerminatedOrderMsg = false;
        state.showInvalidCardNumberEnteredMsg = false;
        state.showAllCardsSuspendedMsg = false;
        state.showAllCardsReactivatedMsg = false;
        state.showCompanyHasToBeActiveMsg = false;
        state.showPin = false;
        state.viewPendingCardOrderPage = true;
        state.cardActivationIsForbiden = false;
        let routeParams = sharedStoreState.currentRoute.params;
        state.configuration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        getPendingCardOrders(routeParams.orderId);
    }

    onViewCardOrderRouteLoaded(state, action, sharedStoreState) {
        state.holderIsViewingCard = true;
        state.showPinButton = true;
        state.showLoadingIndicator = true;
        state.orderInfoLoaded = false;
        let routeParams = sharedStoreState.currentRoute.params;
        state.viewPendingCardOrderPage = false;
        state.showInvalidCardNumberEnteredMsg = false;
        state.showPin = false;
        state.virtualCardEnabledSuccessfully = false;
        state.virtualCardDisabledSuccessfully = false;
        state.virtualCardIsEnabled = false;
        state.showLMAcardNotAllowedMsg = false;
        state.showNordicCardNotAllowedMsg = false;
        state.disableAcceptOrderBtn = false;
        state.configuration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        getCardOrderDetails(routeParams.orderId);
    }

    onViewOrganizationCardOrderRouteLoaded(state, action, sharedStoreState) {
        state.holderIsViewingCard = false;
        state.showPinButton = false;
        state.showLoadingIndicator = true;
        state.orderInfoLoaded = false;
        let routeParams = sharedStoreState.currentRoute.params;
        state.hideBackButton = routeParams.hideBack;
        state.viewPendingCardOrderPage = false;
        state.showInvalidCardNumberEnteredMsg = false;
        state.showPin = false;
        state.virtualCardEnabledSuccessfully = false;
        state.virtualCardDisabledSuccessfully = false;
        state.virtualCardIsEnabled = false;
        state.showLMAcardNotAllowedMsg = false;
        state.showNordicCardNotAllowedMsg = false;
        state.disableAcceptOrderBtn = false;
        state.configuration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        state.userCanEditOrgCards = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_EDIT_ORGANIZATIONS_CARDS')
        getCardOrderDetails(routeParams.orderId, routeParams.organizationCode, routeParams.organizationId);
    }

    onVisitManualIdPartnerRouteLoaded(state, action, sharedStoreState) {
        state.signInWithBankId = false;
        state.signInWithDokobit = false;
        state.showThereAreNoPendingOrReadyToActivateCardsMsg = false;
        state.showLoadingIndicator = true;
        state.configuration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        let strongAuthForAnonymousEnabled = state.configuration.servicePortal.strongAuthenticationForAnonymous.enabled;
        state.signInWithBankLink = strongAuthForAnonymousEnabled ? prepareUrl.getStrongAuthForAnonymousPath(state.configuration) : '';
        state.signInWithDokobitLink = strongAuthForAnonymousEnabled ? prepareUrl.getStrongAuthForAnonymousPath(state.configuration, null, true) : '';
        checkIfUserHasPendingOrReadyToActivateCards()
    }

    onOpenMyCardsRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        let routeParams = sharedStoreState.currentRoute.params;
        let cardSupplier = routeParams.cardSupplier
        let url = "/cards/my-cards"
        if (cardSupplier) {
            url += `/${cardSupplier}`;
        }
        let cardsLandingPageUrl = prepareUrl.prepareAbsoluteUrl(url);
        redirectToUrl(cardsLandingPageUrl);
    }

    onOpenMyCardsAfterAuthenticationRouteLoaded(state) {
        state.showLoadingIndicator = true;
        redirectToUrl(prepareUrl.getSignInRelativeUrl(), true);
    }

    onRejectCardOrderRouteLoaded(state) {
        state.currentOrderAction = 'reject';
        state.viewPendingCardOrderPage = true;
        state.showLoadingIndicator = false;
        storeFormManager.setFormInitialState(state, "cardRejectForm");
    }

    onSuspendCardOrderRouteLoaded(state) {
        state.currentOrderAction = 'suspend';
        state.viewPendingCardOrderPage = true;
        state.showLoadingIndicator = false;
        storeFormManager.setFormInitialState(state, "cardRejectForm");
    }

    onTerminateCardOrderRouteLoaded(state) {
        state.currentOrderAction = 'terminate';
        state.viewPendingCardOrderPage = true;
        state.showLoadingIndicator = false;
        storeFormManager.setFormInitialState(state, "cardRejectForm");
    }

    onReactivateCardOrderRouteLoaded(state) {
        state.currentOrderAction = 'reactivate';
        state.viewPendingCardOrderPage = true;
        state.showLoadingIndicator = false;
        storeFormManager.setFormInitialState(state, "cardRejectForm");
    }

    onShowCardActionMsgRouteLoaded(state, action, sharedStoreState) {
        let organizationId = sharedStoreState.representedOrganizationCode;
        state.showLoadingIndicator = organizationId ? false : true;
        state.showLandingCardReviewPage = organizationId ? true : false;
        checkForPendingCardOrder();
        state.viewPendingCardOrderPage = false;
        state.actionMsgPageAllOrdersReviewed = false;
        state.noOrdersToReviewMsg = false;
        state.pendingCardOrdersList = [];
        state.readyToActivateCardOrdersList = [];
        state.actionMsgPageAllOrdersReviewed = false;
        let status = sharedStoreState.currentRoute.params.status;
        state.configuration = sharedStoreState.authInfo.topMenuParameters.configuration;
        state.currentLanguage = sharedStoreState.localizations.messagesLanguage;
        state.customerSupportInformation = state.configuration.serviceProviderFooter.providedInformation['col-2'].columnContent;
        let signOutLinkWithRedirectUrl = prepareUrl.getSignOutRelativeUrl(state.configuration.urlAfterSignOut);
        state.signOutLink = prepareUrl.prepareAbsoluteUrl(signOutLinkWithRedirectUrl);
        state.cardOrdersLink = prepareUrl.prepareRouteUrl(`/cards/all`, false, false);

        if (status) {
            if (status === "confirmed") {
                state.showSuccessfullyRejectedOrderMsg = false;
                state.showSuccessfullyConfirmedOrderMsg = true;
            } else if (status === "activated") {
              state.showSuccessfullyRejectedOrderMsg = false;
              state.showSuccessfullyActivatedOrderMsg = true;

            } else {
              if (status === "rejected") {
                state.showSuccessfullyRejectedOrderMsg = true;
              } else if (status === "suspended") {
                state.showSuccessfullySuspendedOrderMsg = true;
              } else if (status === "reactivated") {
                state.showSuccessfullyReactivatedOrderMsg = true;
              } else if (status === "terminated") {
                state.showSuccessfullyTerminatedOrderMsg = true;
              }
                state.showSuccessfullyConfirmedOrderMsg = false;
                state.showSuccessfullyActivatedOrderMsg = false;
            }
        } else {
            state.showSuccessfullyRejectedOrderMsg = false;
            state.showSuccessfullyConfirmedOrderMsg = false;
            state.showSuccessfullyActivatedOrderMsg = false;
            state.showSuccessfullySuspendedOrderMsg = false;
            state.showSuccessfullyReactivatedOrderMsg = false;
            state.showSuccessfullyTerminatedOrderMsg = false;
        }
    }

    onCardsListRouteLoaded(state, action, sharedStoreState) {
        state.orderInfoLoaded = false;
        state.showSuccessfullyRejectedOrderMsg = false;
        state.showSearchStatusMessages = false;
        state.showSuccessfullyConfirmedOrderMsg = false;
        state.showSuccessfullyActivatedOrderMsg = false;
        state.showSuccessfullySuspendedOrderMsg = false;
        state.showSuccessfullyReactivatedOrderMsg = false;
        state.showSuccessfullyTerminatedOrderMsg = false;
        state.cardManagementShowSuccessfullyReactivatedCardMsg = state.cardManagementCardReactivated;
        state.cardManagementShowSuccessfullyTerminatedCardMsg = state.cardManagementCardTerminated;
        state.cardManagementShowSuccessfullySuspendedCardMsg = state.cardManagementCardSuspended;
        state.cardManagementCardReactivated = false;
        state.cardManagementCardTerminated = false;
        state.cardManagementCardSuspended = false;
        state.viewPendingCardOrderPage = false;
        state.userCanActOnAllCards = this.userHasPermission(sharedStoreState, 'SUSPEND_AND_REACTIVATE_ALL_ORG_CARDS')

        this.updateCardListPage(state, action, sharedStoreState)
    }

    getCurrentPage(sharedStoreState) {
        let currentCardsListPage = parseInt(sharedStoreState.currentRoute.params.pageNumber || 1);
        return isNaN(currentCardsListPage) ? 1 : currentCardsListPage;
    }

    [ActionTypes.CARDS_GET_PENDNG_CARD_ORDERS_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        state.cardOrderInfo = action.result;
        state.orderInfoLoaded = true;

        if (state.cardOrderInfo.hasPendingOrders && state.cardOrderInfo.pendingOrder) {
            state.currentOrderStatus = state.cardOrderInfo.pendingOrder.status;
            state.currentOrderAction = state.currentOrderStatus === constants.cardStatuses.readyToBeActivatedByHolder ? 'activate' : 'confirm';
            state.currentOrderActionNotActivate = state.currentOrderAction != 'activate';
            storeFormManager.setFormInitialState(state, "virtualCardForm");
            storeFormManager.setFormInitialState(state, "cardOrderForm");

            state.virtualCardForm.fields.virtualCardEnabled.value = Boolean(action.result.virtualCardStatus);
            state.virtualCardIsEnabled = Boolean(action.result.virtualCardStatus);
            state.userCanChangeVirtualCardStatus = action.result && action.result.canEditVirtualCard;
            this.setCardOrderFormValues(state, action.result.pendingOrder);

            state.showLMAcardNotAllowedMsg = state.cardOrderInfo.pendingOrder.showLMACardNotAllowedMsg;
            state.showNordicCardNotAllowedMsg = state.cardOrderInfo.pendingOrder.showNordicCardNotAllowedMsg;
            state.disableAcceptOrderBtn = state.showLMAcardNotAllowedMsg || state.showNordicCardNotAllowedMsg;

        } else {
            redirectToRoute('/cards/all');
        }
    }

    setCardOrderFormValues(state, values) {
        state.currentOrderInfo = values;
        state.cardOrderForm.fields.phone.value = values.phone;
        state.cardOrderForm.fields.email.value = values.email;
        state.holderImg = values.ownerImage;
        state.cardOrderId = values.orderId;
    }

    [ActionTypes.CARDS_GET_PENDNG_CARD_ORDERS_ERROR](state) {
        state.showLoadingIndicator = false;
        state.orderInfoLoaded = true;
    }

    [ActionTypes.CARD_ORDER_LIST_ROW_CLICKED](state, action) {
        state.reviewCardOrderInfo = false;
        let status = action.status;
        if (status === constants.cardStatuses.waitingForHolderApproval) {
          state.userCameFromAllCards = true;
          redirectToRoute(`/cards/order/${action.orderId}`, false, false, false);
        } else {
          redirectToRoute(`/cards/view-order/${action.orderId}`, false, false, false);
        }
    }

    [ActionTypes.ORGANIZATION_CARD_ORDER_LIST_ROW_CLICKED](state, action, sharedStoreState) {
        state.reviewCardOrderInfo = false;
        sharedStoreState.redirectToCpbList = false;
        if (action.redirectToCpbList) {
            sharedStoreState.redirectToCpbList = action.orgIdViewedByCpb;
        }
        redirectToRoute(`/${action.orgId}/organization/cards/view-order/${action.orderId}`, false, false, false);
    }

    onCardOrderFormSubmitted(state, values) {
        if (values.country && values.country.indexOf(constants.countryCodeSuffixForDuplicatedCodesAtTheTop) > 0) {
            values.country = values.country.replace(constants.countryCodeSuffixForDuplicatedCodesAtTheTop, '');
        }
        state.showLoadingIndicator = true;
        confirmCardOrder(state.cardOrderId, values);
    }

    onCardActivationFormSubmitted(state, values) {
        state.showLoadingIndicator = true;
        state.showInvalidCardNumberEnteredMsg = false;
        checkIfUserHasActivatedCardsInOrg(state.cardOrderId, values);
    }

    onShowPinFormSubmitted(state, values) {
      state.showLoadingIndicator = true;
      state.showInvalidCardNumberEnteredMsg = false;
      state.showPin = false;
      showPin(state.cardOrderId, values);
    }

    onVirtualCardFormSubmitted(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        saveVirtualCardStatus(sharedStoreState.currentRoute.params.organizationCode, state.cardOrderId, values);
    }

    onFilterCardsFormSubmitted(state, values, sharedStoreState, thisReference) {
        state.showLoadingIndicator = true;
        values['page'] = 1;

        searchCardsForListDisplay(values, sharedStoreState.currentRoute.params.organizationCode, sharedStoreState.currentRoute.params.cpbOrganizationId);
        state.searchInProgress = true;
        let redirectPath = thisReference.getCorrectRedirectPathToCardsList(state, sharedStoreState, false);
        redirectToRoute(redirectPath);
    }

    [ActionTypes.REJECT_CARD_ORDER_BTN_CLICKED](state) {
        state.showLoadingIndicator = false;
        let orderId = state.cardOrderId;
        redirectToRoute(`/cards/reject-card-oder/${orderId}`, false, false, false);
    }

    [ActionTypes.CONFIRM_CARD_ORDER_SUCCESS] (state) {
        state.showLoadingIndicator = false;
        redirectToRoute(`/cards/waiting-orders/confirmed`, false, false, false);
    }

    [ActionTypes.CONFIRM_CARD_ORDER_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.REACTIVATE_CARD_ORDER_SUCCESS] (state, action, sharedStoreState) {
        let organizationId = sharedStoreState.representedOrganizationCode;

        if (organizationId) {
          state.cardManagementCardReactivated = true;
          redirectToRoute(`${organizationId}/organization/cards/${state.currentCardsListPage}`, false, false, false);
        } else {
          state.showLoadingIndicator = false;
          redirectToRoute(`/cards/waiting-orders/reactivated`, false, false, false);
        }

    }

    [ActionTypes.REACTIVATE_CARD_ORDER_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.SUSPEND_CARD_ORDER_SUCCESS] (state, action, sharedStoreState) {
        let organizationId = sharedStoreState.representedOrganizationCode;
        if (organizationId) {
          redirectToRoute(`${organizationId}/organization/cards/${state.currentCardsListPage}`, false, false, false);
          state.cardManagementCardSuspended = true;
        } else {
          redirectToRoute(`/cards/waiting-orders/suspended`, false, false, false);
          state.showLoadingIndicator = false;
        }
    }

    [ActionTypes.SUSPEND_CARD_ORDER_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.TERMINATE_CARD_ORDER_SUCCESS] (state, action, sharedStoreState) {
        let organizationId = sharedStoreState.representedOrganizationCode;
        if (organizationId) {
          redirectToRoute(`${organizationId}/organization/cards/${state.currentCardsListPage}`, false, false, false);
          state.cardManagementCardTerminated = true;
        } else {
          redirectToRoute(`/cards/waiting-orders/terminated`, false, false, false);
          state.showLoadingIndicator = false;
        }
    }

    [ActionTypes.TERMINATE_CARD_ORDER_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ACTIVATE_CARD_ORDER_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        state.dismissActivateCardModalAfterClick = false;
        state.cardActivationIsForbiden = false;

        if (action.result && action.result.cardActivationIsForbiden) {
            state.hideCardNumberInputModal = true;
            state.cardActivationIsForbiden = true;
        } else {
            redirectToRoute(`/cards/waiting-orders/activated`, false, false, false);
        }
    }

    [ActionTypes.ACTIVATE_CARD_ORDER_ERROR] (state) {
        state.showLoadingIndicator = false;
        state.dismissActivateCardModalAfterClick = false;
    }

    [ActionTypes.CARDS_GET_CARD_ORDER_DETAILS_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.orderInfoLoaded = true;
        state.reviewCardOrderInfo = action.result;
        state.currentOrderStatus = action.result.status;
        state.currentOrderAction = state.currentOrderStatus === constants.cardStatuses.readyToBeActivatedByHolder ? 'activate' : 'confirm';
        state.currentOrderActionNotActivate = state.currentOrderAction != 'activate';
        storeFormManager.setFormInitialState(state, "virtualCardForm");
        storeFormManager.setFormInitialState(state, "cardOrderForm");

        state.virtualCardForm.fields.virtualCardEnabled.value = Boolean(action.result.virtualCardStatus);
        state.virtualCardIsEnabled = Boolean(action.result.virtualCardStatus);

        state.userCanChangeVirtualCardStatus = action.result && action.result.canEditVirtualCard;

        state.viewPendingCardOrderPage = state.currentOrderStatus === constants.cardStatuses.readyToBeActivatedByHolder;
        this.setCardOrderFormValues(state, action.result);
    }

    [ActionTypes.CARDS_GET_CARD_ORDER_DETAILS_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.BACK_TO_PENDING_CARD_ORDERS_BTN_CLICKED](state, action, sharedStoreState) {
        if ( (sharedStoreState.redirectToCpbList && sharedStoreState.redirectToCpbList.length > 0) ||  sharedStoreState.currentRoute.params.organizationCode) {
            let redirectPath = this.getCorrectRedirectPathToCardsList(state, sharedStoreState);
            redirectToRoute(redirectPath, false, false, false);
        } else {
            let routeParams = sharedStoreState.currentRoute.params;
            let orderId = routeParams.orderId;
            let route = '/cards/order';
            if (orderId)
                route += '/' + orderId;
                redirectToRoute(route, false, false, false);
        }
        sharedStoreState.redirectToCpbList = false;
    }

    [ActionTypes.BACK_TO_WAITING_ORDERS_BTN_CLICKED](state, action, sharedStoreState) {
        if ( (sharedStoreState.redirectToCpbList && sharedStoreState.redirectToCpbList.length > 0) ||  sharedStoreState.currentRoute.params.organizationCode) {
            let redirectPath = this.getCorrectRedirectPathToCardsList(state, sharedStoreState);
            redirectToRoute(redirectPath, false, false, false);
        } else {
            redirectToRoute('/cards/waiting-orders', false, false, false);
        }
        sharedStoreState.redirectToCpbList = false;
    }

    [ActionTypes.BACK_TO_ALL_ORDERS_LIST_BTN_CLICKED] (state, action, sharedStoreState) {
        if ( (sharedStoreState.redirectToCpbList && sharedStoreState.redirectToCpbList.length > 0) ||  sharedStoreState.currentRoute.params.organizationCode ) {
            let redirectPath = this.getCorrectRedirectPathToCardsList(state, sharedStoreState);
            redirectToRoute(redirectPath, false, false, false);
        } else {
            redirectToRoute('/cards/all', false, false, false);
        }
        sharedStoreState.redirectToCpbList = false;
    }

    getCorrectRedirectPathToCardsList(state, sharedStoreState, withCurrentPage = true) {
        let path = null;

        if (sharedStoreState.redirectToCpbList && sharedStoreState.redirectToCpbList.length > 0) {
            path = `/${sharedStoreState.currentRoute.params.organizationCode}/organization/${sharedStoreState.redirectToCpbList}/cards`;
        } else if (sharedStoreState.currentRoute.params.organizationCode) {
            path = `/${sharedStoreState.currentRoute.params.organizationCode}/organization/cards`;
        }

        if (path && withCurrentPage) {
            path = `${path}/${state.currentCardsListPage}`;
        }

        return path;
    }

    onCardRejectFormSubmitted(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        let routeParams = sharedStoreState.currentRoute.params;
        let currentOrderAction = state.currentOrderAction;
        let organizationId = sharedStoreState.representedOrganizationCode;
        values.changeCompanyCardStatus = false;
        if (organizationId) {
            values.changeCompanyCardStatus = true;
            values.org_id = organizationId;
        }
        if (currentOrderAction === 'reactivate') {
          reactivateCardOrder(routeParams.orderId, values);
        } else if (currentOrderAction === 'terminate') {
          terminateCardOrder(routeParams.orderId, values);
        } else if (currentOrderAction === 'suspend') {
          suspendCardOrder(routeParams.orderId, values);
        } else {
          rejectCardOrder(routeParams.orderId, values);
        }

    }

    [ActionTypes.REJECT_CARD_ORDER_SUCCESS](state) {
        state.showLoadingIndicator = false;
        redirectToRoute(`/cards/waiting-orders/rejected`, false, false, false);
    }

    [ActionTypes.REJECT_CARD_ORDER_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ORGANIZATION_CARDS_CLEAR_FILTER_BTN_CLICKED](state, action, sharedStoreState) {
        storeFormManager.setFormInitialState(state, "filterCardsForm");
        action.storeKey = storeKey;
        action.formKey = 'filterCardsForm';
        storeFormManager.onSubmitBtnClicked(state, action, sharedStoreState, this);
    }

    [ActionTypes.GET_FILTERED_CARDS_LIST_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.showSearchStatusMessages = true;
        state.orderInfoLoaded = true;
        state.searchInProgress = false;
        state.cardOrdersInfo = action.result;
        if (action.result.orders && action.result.orders.length) {
            action.result.orders.forEach((order) => {
                if (order.orgId && !sharedStoreState.currentRoute.params.cpbOrganizationId) {
                    order.handleRowClick = orgCardOrderRowClickedHandler(order.orderId, sharedStoreState.currentRoute.params.organizationCode, order.orgId, true);
                    state.backToCpbList = true;
                } else if (sharedStoreState.currentRoute.params.organizationCode) {
                    order.handleRowClick = orgCardOrderRowClickedHandler(order.orderId, sharedStoreState.currentRoute.params.organizationCode, sharedStoreState.currentRoute.params.cpbOrganizationId);
                } else {
                    order.handleRowClick = cardOrderRowClickedHandler(order.orderId, order.status);
                }
            });
        }
    }

    [ActionTypes.GET_FILTERED_CARDS_LIST_ERROR](state) {
        state.showLoadingIndicator = false;
        state.showSearchStatusMessages = true;
        state.searchInProgress = false;
    }

    [ActionTypes.CARDS_CHECK_FOR_PENDING_ORDERS_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;

        state.pendingCardOrdersList = action.result.pendingOrders;
        state.readyToActivateCardOrdersList = action.result.readyToActivateOrders;

        state.holderName = action.result.holderName;

        let hasMorePendingOrders = state.pendingCardOrdersList.length > 0 || state.readyToActivateCardOrdersList.length > 0;
        let status = sharedStoreState.currentRoute.params.status;
        state.actionMsgPageAllOrdersReviewed = !hasMorePendingOrders && status;
        state.noOrdersToReviewMsg = !hasMorePendingOrders && !status;

        state.reviewPendingOrderUrl = prepareUrl.prepareRouteUrl(`/cards/order`, false, false);
        state.activateWaitingCardsUrl = prepareUrl.prepareRouteUrl('/cards/order', false, false);

        //Redirect user to my cards page if there are no pending card orders
        let redirectToMyCardsPage = state.readyToActivateCardOrdersList.length === 0 && state.pendingCardOrdersList.length === 0;
        if (redirectToMyCardsPage) {
            redirectToRoute('cards/all', false, false, false);
        }
        // Redirect user to revieorder page if only one card order is waiting
        let redirectToPendingOrderReview = !status && state.readyToActivateCardOrdersList.length === 0 && state.pendingCardOrdersList.length === 1;
        if (redirectToPendingOrderReview) {
            redirectToRoute(`cards/order/${state.pendingCardOrdersList[0].orderId}`, false, false, false);
        } else {
            state.userCameFromAllCards = false;
            state.showLandingCardReviewPage = true;
        }
    }

    [ActionTypes.CARDS_CHECK_FOR_PENDING_ORDERS_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.CARDS_CHECK_IF_USER_HAS_PENDING_ORDERS_OR_READY_TO_ACTIVATE_CARDS_SUCCESS](state, action) {
        state.showLoadingIndicator = false;

        if (action.result.userHasPendingOrdersOrReadyToActivateCards) {
            state.signInWithBankId = action.result.authenticateWithBankId;
            state.signInWithDokobit = action.result.authenticateWithDokobit;
        } else {
            state.showThereAreNoPendingOrReadyToActivateCardsMsg = true
        }

    }

    [ActionTypes.CARDS_CHECK_IF_USER_HAS_PENDING_ORDERS_OR_READY_TO_ACTIVATE_CARDS_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.CARDS_CHECK_IF_USER_HAS_ACTIVATED_CARDS_IN_ORG_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.showInvalidCardNumberEnteredMsg = false;
        let cardNumberIsValid = action.result.cardNumberIsValid;
        if (cardNumberIsValid) {
            state.dismissActivateCardModalAfterClick = true;
            let userHasActivatedCardsInOrg = action.result.userHasActivatedCardsInOrg;
            if (userHasActivatedCardsInOrg) {
                state.alreadyActivatedCardNumber = action.result.cardNumber;
                state.showConfirmActivationForCardModal = true;
                state.hideCardNumberInputModal = true;
            } else {
                state.showLoadingIndicator = true;
                activateCardOrder(state.cardOrderId);
            }
        } else {
          state.showInvalidCardNumberEnteredMsg = true;
          state.dismissActivateCardModalAfterClick = false;
        }
    }

    [ActionTypes.CARDS_CHECK_IF_USER_HAS_ACTIVATED_CARDS_IN_ORG_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ACTIVATE_CARD_ORDER_MODAL_OPENED] (state) {
        state.dismissActivateCardModalAfterClick = false;
        state.showConfirmActivationForCardModal = false;
        state.showInvalidCardNumberEnteredMsg = false;
        state.hideCardNumberInputModal = false;
        storeFormManager.setFormInitialState(state, "cardActivationForm");
    }

    [ActionTypes.SHOW_PIN_MODAL_OPENED] (state) {
        state.showPin = false;
        state.pin = null;
        state.dismissActivateCardModalAfterClick = false;
        state.showInvalidCardNumberEnteredMsg = false;
        state.hideShowPinButton = false;
        storeFormManager.setFormInitialState(state, "showPinForm");
    }

    [ActionTypes.SUSPEND_CARD_ORDER_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        let organizationId = sharedStoreState.representedOrganizationCode;
        let orderId = state.cardOrderId;
        let url = `/cards/suspend-card-order/${orderId}`
        if (organizationId) {
          url = `${organizationId}/organization` + url;
        }
        redirectToRoute(url, false, false, false);
    }

    [ActionTypes.TERMINATE_CARD_ORDER_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        let organizationId = sharedStoreState.representedOrganizationCode;
        let orderId = state.cardOrderId;
        let url = `/cards/terminate-card-order/${orderId}`;
        if (organizationId) {
          url = `${organizationId}/organization` + url;
        }
        redirectToRoute(url, false, false, false);
    }

    [ActionTypes.REACTIVATE_CARD_ORDER_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        let organizationId = sharedStoreState.representedOrganizationCode;
        let orderId = state.cardOrderId;
        let url = `/cards/reactivate-card-order/${orderId}`;
        if (organizationId) {
          url = `${organizationId}/organization` + url;
        }
        redirectToRoute(url, false, false, false);
    }

    [ActionTypes.SHOW_PIN_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.dismissActivateCardModalAfterClick = false;
        state.showInvalidCardNumberEnteredMsg = false;
        state.pinLoading = false;

        let cardNumberIsValid = action.result.cardNumberIsValid;
        if (cardNumberIsValid) {
          state.pin = action.result.pin;
          state.showPin = true;
          state.hideShowPinButton = true;
          state.pinCardId = action.result.cardId;
        } else {
          state.showInvalidCardNumberEnteredMsg = true;
        }
    }
    [ActionTypes.HIDE_PIN](state) {
        state.showPin = false;
        state.pin = null;
        state.pinCardId = null;
    }

    [ActionTypes.PIN_LOADING] (state, action) {
        state.pinLoading = true;
        state.pinLoadingCardId = action.orderId;

    }
    [ActionTypes.SHOW_PIN_ERROR](state) {
        state.hideShowPinButton = false;
        state.showLoadingIndicator = false;
        state.dismissActivateCardModalAfterClick = false;
        state.pinLoading = false;
    }

    [ActionTypes.ACTIVATE_CARD_ORDER_BTN_CLICKED](state) {
        state.showLoadingIndicator = true;
        state.showConfirmActivationForCardModal = false;
        activateCardOrder(state.cardOrderId);
    }

    [ActionTypes.SAVE_VIRTUAL_CARD_STATUS_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.virtualCardEnabledSuccessfully = action.result.virtualCardEnabled;
        state.virtualCardDisabledSuccessfully = !action.result.virtualCardEnabled;
    }

    [ActionTypes.SAVE_VIRTUAL_CARD_STATUS_ERROR](state) {
        state.showLoadingIndicator = false;
        state.virtualCardEnabledSuccessfully = false;
        state.virtualCardDisabledSuccessfully = false;
    }

    fieldValueChanged(state, action, sharedStoreState) {
        if (action.storeKey === storeKey) {
            if (action.formKey === 'filterCardsForm' && action.fieldKey === 'usersFilterStatus') {
                storeFormManager.onSubmitBtnClicked(state, action, sharedStoreState, this);
            }
        }
    }

    virtualCardFormFieldChanged(state, action, sharedStoreState) {
        storeFormManager.onSubmitBtnClicked(state, action, sharedStoreState);
    }

    [ActionTypes.SUSPEND_ALL_COMPANY_CARDS_BTN_CLICKED](state, action) {
        state.showLoadingIndicator = true;
        state.showAllCardsSuspendedMsg = false;
        state.showAllCardsReactivatedMsg = false;
        state.showCompanyHasToBeActiveMsg = false;
        let organizationId = action.organizationId;
        suspendAllCompanyCards(organizationId)
    }

    [ActionTypes.SUSPEND_ALL_COMPANY_CARDS_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.showAllCardsSuspendedMsg = true;
        state.suspendedCardsCount = action.result.suspendedCardsCount;
        this.updateCardListPage(state, action, sharedStoreState)
    }

    [ActionTypes.SUSPEND_ALL_COMPANY_CARDS_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.REACTIVATE_ALL_COMPANY_CARDS_BTN_CLICKED](state, action) {
        state.showLoadingIndicator = true;
        state.showAllCardsReactivatedMsg = false;
        state.showAllCardsSuspendedMsg = false;
        state.showCompanyHasToBeActiveMsg = false;
        let organizationId = action.organizationId;
        reactivateAllCompanyCards(organizationId);
    }

    updateCardListPage(state, action, sharedStoreState) {
        state.currentCardsListPage = this.getCurrentPage(sharedStoreState);
        if (!state.searchInProgress) {
            state.showLoadingIndicator = true;
            if (!state.cardOrdersInfo) {
                storeFormManager.setFormInitialState(state, "filterCardsForm");
            }
            searchCardsForListDisplay(
                {
                    'page': state.currentCardsListPage,
                    'usersFilterValue': state.filterCardsForm.fields.usersFilterValue.value,
                    'usersFilterStatus': state.filterCardsForm.fields.usersFilterStatus.value,
                },
                sharedStoreState.currentRoute.params.organizationCode,
                sharedStoreState.currentRoute.params.cpbOrganizationId
            );
            state.searchInProgress = true;
        }
    }

    [ActionTypes.REACTIVATE_ALL_COMPANY_CARDS_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        let orgIsNotActive = action.result ? action.result.orgIsNotActive : false
        if (orgIsNotActive) {
            state.showCompanyHasToBeActiveMsg = true;
            state.showLoadingIndicator = false;
        } else {
            state.showAllCardsReactivatedMsg = true;
            state.reactivatedCardsCount = action.result.reactivatedCardsCount;
            this.updateCardListPage(state, action, sharedStoreState)
        }
    }

    [ActionTypes.REACTIVATE_ALL_COMPANY_CARDS_ERROR](state) {
        state.showLoadingIndicator = false;
    }


}

export default PendingCardOrdersStore;
