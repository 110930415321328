import BaseStore from '../base/BaseStore.js';
import ActionTypes from '../../actions/ActionTypes';
import storeFormManager from '../validation/StoreFormManager.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import { requiredValidator } from '@vaultit/stv-utils-frontend';
import getGlobalSearchedUserCardList from '../../actions/actionCreators/cards/GetGlobalSearchedUserCardList.js';
import getCardHistory from '../../actions/actionCreators/cards/GetCardHistory.js';


class GlobalUserSearchStore extends BaseStore {

    getInitialState() {
        let initialState = {
            currentPerson: null,
            cardsInfo: null,
            cardsInfoLoaded: false
        };
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/search-users-globally/user-cards-list/:userId',
                loadOnlyIfHasPermission: 'SEARCH_USERS_GLOBALLY_SEE_USER_CARDS',
                loadHandler: this.onUsersGlobalSearchCardsListRouteLoaded
            }
        ];
    }

    onUsersGlobalSearchCardsListRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.cardsInfo = null;
        state.cardsInfoLoaded = false;
        state.fullName = '';
        let userId = sharedStoreState.currentRoute.params.userId;
        getGlobalSearchedUserCardList(userId);
    }

    onGlobalSearchCardHistoryFormSubmitted(state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.GET_GLOBAL_SEARCH_USER_CARDS_LIST_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.showSearchStatusMessages = false;
        state.cardsInfoLoaded = true;
        state.cardsInfo = action.result.cardsInfo;
        state.fullName = action.result.fullName;
    }

    [ActionTypes.GET_GLOBAL_SEARCH_USER_CARDS_LIST_ERROR](state) {
        state.showLoadingIndicator = false;
        state.showSearchStatusMessages = true;
    }

    [ActionTypes.GLOBAL_SEARCH_USER_CARDS_LIST_CARD_BTN_CLICKED](state, action) {
        redirectToRoute(`/${action.orgId}/organization/cards/view-order/${action.orderId}/true`, false, true, false);
    }

    [ActionTypes.SHOW_CARD_HISTORY_BTN_CLICKED](state, action) {
        if (state.cardsInfo[action.index].cardHistoryData) {
            state.cardsInfo[action.index]['historyVisible'] = true;
        } else {
            state.showLoadingIndicator = true;
            getCardHistory({
                'cardId': action.cardId,
            });
        }
    }

    [ActionTypes.HIDE_CARD_HISTORY_BTN_CLICKED](state, action) {
        for (let index in state.cardsInfo) {
            if (state.cardsInfo[index].orderId == action.cardId) {
                state.cardsInfo[index]['historyVisible'] = false;
            }
        };
    }

    [ActionTypes.GET_CARD_HISTORY_SUCCESS](state, action) {
        state.cardHistory = action.result.cardHistory;
        state.historyCardId = action.result.cardId
        state.showLoadingIndicator = false;
        for (let index in state.cardsInfo) {
            let listedCard = state.cardsInfo[index];
            let matchingCardId = Boolean(listedCard.orderId == state.historyCardId);
            if (matchingCardId) {
                listedCard.cardHistoryData = state.cardHistory;
                listedCard.historyVisible = true;
                let globalSearchCardHistoryFieldDefinitions = {
                    cardStatus: {
                        defaultValue: '',
                        validators: [requiredValidator]
                    },
                    modifiedBy: {
                        defaultValue: '',
                        validators: [requiredValidator]
                    },
                    modifiedDescription: {
                        defaultValue: '',
                        validators: [requiredValidator]
                     },
                    modifiedReason: {
                        defaultValue: '',
                        validators: [requiredValidator]
                    },
                    modifiedTime: {
                        defaultValue: '',
                        validators: [requiredValidator]
                    },
                };
                let formName = `globalSearchCardHistory_${index}`;
                storeFormManager.defineForm(state, 'globalUserSearchStore', formName, globalSearchCardHistoryFieldDefinitions, this.onGlobalSearchCardHistoryFormSubmitted);
                state.formDefinitions[formName].fieldDefinitions.cardStatus.defaultValue = state.cardHistory.cardStatus;
                state.formDefinitions[formName].fieldDefinitions.modifiedBy.defaultValue = state.cardHistory.modifiedBy;
                state.formDefinitions[formName].fieldDefinitions.modifiedDescription.defaultValue = state.cardHistory.modifiedDescription;
                state.formDefinitions[formName].fieldDefinitions.modifiedReason.defaultValue = state.cardHistory.modifiedReason;
                state.formDefinitions[formName].fieldDefinitions.modifiedTime.defaultValue = state.cardHistory.modifiedTime;
                storeFormManager.setFormInitialState(state, formName);
            }
        }
    }

    [ActionTypes.GET_CARD_HISTORY_ERROR](state) {
        state.showLoadingIndicator = false;
    }
}

export default GlobalUserSearchStore;
