import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage } from 'react-intl';
import {PageHeader, Message, formatHelpers, DateLabel } from '@vaultit/stv-utils-frontend';
import showCardHistoryBtnClicked from '../../actions/actionCreators/cards/ShowCardHistoryBtnClicked.js';
import hideCardHistorydBtnClicked from '../../actions/actionCreators/cards/HideCardHistoryBtnClicked.js';
import globalSearchUserCardsListCardBtnClicked from '../../actions/actionCreators/cards/GlobalSearchUserCardsListCardBtnClicked.js';


class CardsGlobalSearchUser extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        let cardsInfo = this.state.globalUserSearchStore.cardsInfo;
        let cardsInfoLoaded = this.state.globalUserSearchStore.cardsInfoLoaded;
        let fullName = this.state.globalUserSearchStore.fullName;
        return (
            <div>
                <PageHeader
                    pageTitleMessageId={fullName}
                    sharedStore={this.state.shared}
                />
                { cardsInfo && cardsInfoLoaded
                ?
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="users.global.cards.cardNumber" defaultMessage="Card number" />
                                </th>
                                <th>
                                    <FormattedMessage id="users.global.cards.companyName" defaultMessage="Company name" />
                                </th>
                                <th>
                                    <FormattedMessage id="users.global.cards.created" defaultMessage="Created" />
                                </th>
                                <th>
                                    <FormattedMessage id="users.global.cards.modified" defaultMessage="Modified" />
                                </th>
                                <th>
                                    <FormattedMessage id="users.global.cards.validUntil" defaultMessage="Valid until" />
                                </th>
                                <th>
                                    <FormattedMessage id="users.global.cards.cardStatus" defaultMessage="Current status" />
                                </th>
                            </tr>
                        </thead>
                        {cardsInfo.length
                            ?
                            cardsInfo.map((card, index) => {
                                let id = 'global_search_user_card_list_' + index;
                                let statusTranslation = card.status;
                                let translationKeyForCardStatus = formatHelpers.formatStatusToTranslationKey('cardapi.cardStatus.', card.status);
                                if (this.context.intl.messages[translationKeyForCardStatus]) {
                                    statusTranslation = this.context.intl.messages[translationKeyForCardStatus];
                                }
                                return (
                                    <tbody key={index}>
                                        <tr id={id}>
                                            <td>{card.cardNumber}</td>
                                            <td>{card.orgName}</td>
                                            <td><DateLabel id="global_search_card_list_status_created_date" format="YYYY-MM-DD HH:mm">{card.dateCreated}</DateLabel></td>
                                            <td><DateLabel id="global_search_card_list_status_modified_date" format="YYYY-MM-DD HH:mm">{card.statusModified}</DateLabel></td>
                                            <td><DateLabel id="global_search_card_list_status_valid_until" format="YYYY-MM-DD">{card.validUntil}</DateLabel></td>
                                            <td>{statusTranslation}</td>
                                            <td className="align-middle text-right w-20">
                                                { card.historyVisible
                                                    ?
                                                        <i
                                                            onClick={ (e) => hideCardHistorydBtnClicked(e, card.orderId, index)}
                                                            role="button"
                                                            className="fa fa-mr fa-folder-open-o align-middle text-primary"
                                                        ></i>
                                                    :
                                                        <i
                                                            onClick={ (e) => showCardHistoryBtnClicked(e, card.orderId, index)}
                                                            role="button"
                                                            className="fa fa-mr fa-history align-middle text-primary"
                                                        ></i>
                                                }
                                                <i
                                                    onClick={ () => globalSearchUserCardsListCardBtnClicked(card.orderId, card.orgId)}
                                                    role="button"
                                                    className="fa fa-list-alt align-middle text-primary"
                                                ></i>
                                            </td>
                                        </tr>
                                        { card.cardHistoryData && card.historyVisible
                                            ?
                                                <tr>
                                                    <td colSpan="7">
                                                        <table className="w-100">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <FormattedMessage id="users.global.cardHistory.cardStatus" defaultMessage="Card status" />
                                                                    </th>
                                                                    <th>
                                                                        <FormattedMessage id="users.global.cardHistory.modifiedTime" defaultMessage="Modified time" />
                                                                    </th>
                                                                    <th>
                                                                        <FormattedMessage id="users.global.cardHistory.modifiedBy" defaultMessage="Modified by" />
                                                                    </th>
                                                                    <th>
                                                                        <FormattedMessage id="users.global.cardHistory.modifiedReason" defaultMessage="Modification reason" />
                                                                    </th>
                                                                    <th>
                                                                        <FormattedMessage id="users.global.cardHistory.modifiedDescription" defaultMessage="Description" />
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            { card.cardHistoryData.map((status, historyIndex) => {
                                                                let h_id = 'global_search_user_card_list_history' + historyIndex;
                                                                let historyStatusTranslation = status.cardStatus;
                                                                let historyTranslationKeyForCardStatus = formatHelpers.formatStatusToTranslationKey('cardapi.cardStatus.', status.cardStatus);
                                                                if (this.context.intl.messages[historyTranslationKeyForCardStatus]) {
                                                                    historyStatusTranslation = this.context.intl.messages[historyTranslationKeyForCardStatus];
                                                                }
                                                                return (
                                                                    <tbody key={h_id}>
                                                                        <td>{historyStatusTranslation}</td>
                                                                        <td><DateLabel id="global_search_card_list_history_modified_time" format="YYYY-MM-DD HH:mm">{status.modifiedTime}</DateLabel></td>
                                                                        <td>{status.modifiedBy}</td>
                                                                        <td>{status.modifiedReason}</td>
                                                                        <td>{status.modifiedDescription}</td>
                                                                    </tbody>
                                                                )
                                                            })}
                                                        </table>
                                                    </td>
                                                </tr>
                                            : null
                                        }
                                    </tbody>
                                )
                                })
                            : null
                        }
                    </table>
                : null
                }
                { cardsInfoLoaded && !cardsInfo.length
                ?
                    <Message id="global_search_user_no_cards" type="info" className="mt-6">
                        <FormattedMessage id="users.global.cards.noCards" defaultMessage="No cards found" />
                    </Message>
                : null
                }
            </div>
        );
    }
}

export default CardsGlobalSearchUser;
