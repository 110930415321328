import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let rejectCardOrder = function (orderId, data) {
    postToBackend(`/cards/reject-card-order/${orderId}`,
        ActionTypes.REJECT_CARD_ORDER_SUCCESS,
        ActionTypes.REJECT_CARD_ORDER_ERROR,
        data
    );
};
export default rejectCardOrder;
