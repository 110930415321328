import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

class ReadyToActivateCard extends React.Component {

    static get propTypes() {
        return {
            order: PropTypes.object,
            statusTranslation: PropTypes.string,
        };
    }

    midnight(date) {
        return date.setHours(0,0,0,0);
    }

    todayOrLater(dateStr) {
        let now = this.midnight(new Date());
        const validFrom = this.midnight(new Date(Date.parse(dateStr)));
        return  validFrom > now;
    }

    render() {
        let message;

        if (this.props.order.status === 'personalized' && this.todayOrLater(this.props.order.validFrom)) {
            message = (
                <FormattedMessage
                    id={'cardapi.cardStatus.personal.personalizedValidFromInFuture'}
                    defaultMessage={'cardapi.cardStatus.personal.personalizedValidFromInFuture'}
                    values={{valid_from_date: this.props.order.validFrom}}
                />)

        } else {
            message = (
                <FormattedMessage
                    id={this.props.statusTranslation}
                    defaultMessage={this.props.statusTranslation}
                />)
        }

        return message
    }
}

export default ReadyToActivateCard;
