import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let globalSearchUserCardsListCardBtnClicked = function(orderId, orgId) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.GLOBAL_SEARCH_USER_CARDS_LIST_CARD_BTN_CLICKED,
        orderId: orderId,
        orgId: orgId
    });
};

export default globalSearchUserCardsListCardBtnClicked;
