import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';


class Pagination extends React.Component {

    static get propTypes() {
        return {
            currentPage: PropTypes.number.isRequired,
            currentFullPath: PropTypes.string.isRequired,
            totalItems: PropTypes.number.isRequired,
            itemsPerPage: PropTypes.number.isRequired,
            numberOfPagesDisplayed: PropTypes.number,
            isVisible:PropTypes.bool
        };
    }

    static get defaultProps () {
        return {
            numberOfPagesDisplayed: 7,
            isVisible: true
        };
    }

    render() {
        if (!this.props.isVisible) {
            return null;
        }

        let totalPages = Math.ceil(parseInt(this.props.totalItems) / parseInt(this.props.itemsPerPage));
        let numberOfPagesDisplayed = parseInt(this.props.numberOfPagesDisplayed);
        let currentPage = parseInt(this.props.currentPage);
        let basePath = this.props.currentFullPath.replace(`/${this.props.currentPage}`, '').replace(/\/$/, '');

        if (totalPages == 1) {
            return null;
        }

        let allPagesLinks = [];

        for (let i=1; i <= totalPages; i++) {
            let itemLink = `${basePath}/${i}`;
            let className = i == currentPage ? 'active page-item mb-3': 'page-item mb-3 d-none d-sm-block';
            
            allPagesLinks.push(
                <li key={i} className={className}>
                    <Link to={itemLink} className="page-link">{i}</Link>
                </li>
            );
        }

        let firstPageLink = null;
        let lastPageLink = null;
        let previousLink = null;
        let nextLink = null;


        let firstItemLink = `${basePath}/1`;
        let lastItemLink = `${basePath}/${totalPages}`;
        let previousItemLink = `${basePath}/${currentPage - 1}`;
        let nextItemLink = `${basePath}/${currentPage + 1}`;

        if (currentPage != 1) {
            firstPageLink = (
                <li className="page-item">
                    <Link to={firstItemLink} className="page-link">&laquo; <FormattedMessage id="pagination.first" defaultMessage="First" /></Link>
                </li>
            );
            previousLink = (
                <li className="page-item">
                    <Link to={previousItemLink} className="page-link">&laquo;</Link>
                </li>
            );
        }

        if (currentPage != totalPages) {
            lastPageLink = (
                <li className="page-item">
                    <Link to={lastItemLink} className="page-link"><FormattedMessage id="pagination.last" defaultMessage="Last" /> &raquo;</Link>
                </li>
            );
            nextLink = (
                <li className="page-item">
                    <Link to={nextItemLink} className="page-link">&raquo;</Link>
                </li>
            );
        }

        let left_side = Math.floor(numberOfPagesDisplayed / 2);
        let right_side = Math.ceil(numberOfPagesDisplayed / 2) - 1;

        let sliceStart = 1;
        let sliceEnd = numberOfPagesDisplayed;

        if (currentPage - left_side > 0) {
            sliceStart = currentPage - left_side;
            sliceEnd = currentPage + right_side;
        } else {
            sliceStart = 1;
            sliceEnd = numberOfPagesDisplayed;
        }

        allPagesLinks = allPagesLinks.slice(sliceStart - 1, sliceEnd);

        return (
            <nav className="pagination-nav">
                <ul className="pagination justify-content-center flex-wrap">
                    { firstPageLink }
                    { previousLink }
                    { allPagesLinks }
                    { nextLink }
                    { lastPageLink }
                </ul>
            </nav>
        );
    }
}

export default Pagination;