import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

export default function (orderId, organizationId=false, orgIdWhichCardViewedByCpb=false) {
    let url = `/cards/card-order-details/${orderId}`;
    if (organizationId) {
        url += `/${organizationId}`;
    }
    if (orgIdWhichCardViewedByCpb) {
        url += `/${orgIdWhichCardViewedByCpb}`;
    }
    getFromBackend(url,
        ActionTypes.CARDS_GET_CARD_ORDER_DETAILS_SUCCESS,
        ActionTypes.CARDS_GET_CARD_ORDER_DETAILS_ERROR);
};
