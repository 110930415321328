import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let hidePin = function() {
    dispatcher.dispatch({
        type: ActionTypes.HIDE_PIN,
    });
};

export default hidePin;
