import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let activateCardOrder = function (orderId) {
    postToBackend(`/cards/activate-card-order/${orderId}`,
        ActionTypes.ACTIVATE_CARD_ORDER_SUCCESS,
        ActionTypes.ACTIVATE_CARD_ORDER_ERROR
    );
};
export default activateCardOrder;
