import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let getGlobalSearchedUserCardList = function(userId) {
    postToBackend(`cards/global-search-user-cards-list/${userId}`,
        ActionTypes.GET_GLOBAL_SEARCH_USER_CARDS_LIST_SUCCESS,
        ActionTypes.GET_GLOBAL_SEARCH_USER_CARDS_LIST_ERROR
    );
};

export default getGlobalSearchedUserCardList;