import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let getHandlerForFormSubmitBtnClicked = function(storeKey, formKey) {
    return function(event) {

        if (event) {
            event.preventDefault();
        }

        dispatcher.dispatch({
            type: ActionTypes.FORM_SUBMIT_BTN_CLICKED,
            storeKey: storeKey,
            formKey: formKey
        });
    };
};

export default getHandlerForFormSubmitBtnClicked;

