import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let checkIfUserHasActivatedCardsInOrg = function (orderId, data) {
    postToBackend(`/cards/check-if-user-has-activated-cards-in-org/${orderId}`,
        ActionTypes.CARDS_CHECK_IF_USER_HAS_ACTIVATED_CARDS_IN_ORG_SUCCESS,
        ActionTypes.CARDS_CHECK_IF_USER_HAS_ACTIVATED_CARDS_IN_ORG_ERROR,
        data
    );
};
export default checkIfUserHasActivatedCardsInOrg;
