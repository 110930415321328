import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let topMenuOrDashboardItemClicked = function(data) {
    dispatcher.dispatch({
        type: ActionTypes.TOP_MENU_OR_DASHBOARD_ITEM_CLICKED,
        data: data
    });
};

export default topMenuOrDashboardItemClicked;