import StoreComponent from './base/StoreComponent.jsx';

class PageLoader extends StoreComponent {

    renderWhenReady() {
        return null;
    }
}

export default PageLoader;
