import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let activateCardOrderModalOpened = function() {
    dispatcher.dispatch({
        type: ActionTypes.ACTIVATE_CARD_ORDER_MODAL_OPENED
    });
};

export default activateCardOrderModalOpened;
