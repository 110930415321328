import request from 'superagent';

import dispatcher from '../../../Dispatcher.js';
import constants from '../../../Constants.js';
import prepareUrl from '../../../helpers/PrepareUrl.js';
import csrfToken from '../../../helpers/CsrfToken.js';
import log from '../../../singletons/Log.js';

const pendingPostRequests = 'postRequests';

let extractAjaxErrorJson = function (err) {
    let result = null;
    if (err && err.response
        && err.response.type === 'application/json') {
        result = JSON.parse(err.response.text);
    }
    return result;
};

let assignAdditionalFieldsIfNeeded = function (action, additionalActionFields) {
    return additionalActionFields
        ? Object.assign(action, additionalActionFields)
        : action;
};

let getFullUrl = function (url) {
    return prepareUrl.prepareAbsoluteUrl(url);
};

let markPendingPostRequests = function (url) {
    let postRequests = sessionStorage.getItem(pendingPostRequests) || '';
    let urls = postRequests.split(',');
    let urlAlreadyAddedToPending = urls.includes(url);
    if (!urlAlreadyAddedToPending) {
        urls.push(url);
        let urlsString = urls.join(',');
        sessionStorage.setItem(pendingPostRequests, urlsString);
    }
}

let removeFromPendingPostRequests = function (url) {
    let postRequests = sessionStorage.getItem(pendingPostRequests) || '';
    let urls = postRequests.split(',');

    let filteredUrls = urls.filter(function(e) { return e != url })

    let urlsString = filteredUrls.join(',');
    sessionStorage.setItem(pendingPostRequests, urlsString);

}

let checkIfPostAlreadyMade = function (url) {

    let postRequests = sessionStorage.getItem(pendingPostRequests) || '';
    let urls = postRequests.split(',');
    return urls.includes(url);
}

let getEndFunction = function (successActionType, errorActionType, additionalActionFields, url=false) {


    return function (err, res) {

        if (url) {
            removeFromPendingPostRequests(url);
        }

        let logicException = res && res.body && res.body.logicExceptionMessageKey;

        if (logicException) {

            // Logic exception:

            let action = {
                type: errorActionType,
                errorMessageId: res.body.logicExceptionMessageKey,
                additionalData: res.body.logicExceptionAdditionalData,
                endOfAjaxRequest: true
            };

            assignAdditionalFieldsIfNeeded(action, additionalActionFields);

            dispatcher.dispatch(action);
        } else if (!err) {

            // Success:

            let action = {
                type: successActionType,
                result: res.body.result,
                authInfoHash: res.body.authInfoHash,
                endOfAjaxRequest: true
            };

            assignAdditionalFieldsIfNeeded(action, additionalActionFields);

            dispatcher.dispatch(action);
        } else {

            if (err.status) {
                // Server error:

                let errorMessageId = constants.generalErrorMessageId;

                if (err.status === 400) {
                    errorMessageId = constants.badRequestErrorMessageId;
                }

                let errorJson = extractAjaxErrorJson(err);

                let additional_data = {};
                let errorData = null;

                if (errorJson && errorJson.details) {
                    errorData = errorJson.details;
                    if (errorJson.details.execution && errorJson.details.execution.exception_additional_data) {
                        additional_data = errorJson.details.execution.exception_additional_data;
                    }
                }

                log.addServerError({
                    "status": err.status,
                    "details": errorData
                });

                let sessionTokenIsMissing  = err.response.header && err.response.header.sessiontokenismissing
                    ? err.response.header.sessiontokenismissing
                    : false;
                let action = {
                    type: errorActionType,
                    errorMessageId: errorMessageId,
                    httpErrorStatus: err.status,
                    errorData: errorData,
                    additionalData: additional_data,
                    endOfAjaxRequest: true,
                    sessionTokenIsMissing: sessionTokenIsMissing
                };

                assignAdditionalFieldsIfNeeded(action, additionalActionFields);

                dispatcher.dispatch(action);
            } else {

                // Unfinished request error:

                log.addUnfinishedRequest(err);
            }
        }
    };
};

export function getFromBackend(url, successActionType, errorActionType, additionalActionFields) {
    log.addStartedRequest(url, 'get', successActionType);
    let req = request
        .get(getFullUrl(url));

    req.end(getEndFunction(successActionType, errorActionType, additionalActionFields));
};

export function postToBackend(url, successActionType, errorActionType, data, additionalActionFields) {
    let postAlreadyMade = checkIfPostAlreadyMade(url);
    if (!postAlreadyMade) {
        log.addStartedRequest(url, 'post', successActionType);
        let req = request
            .post(getFullUrl(url))
            .set(constants.frontEndRequestCsrfHeaderName, csrfToken.get());

        markPendingPostRequests(url);

        req.send(data)
            .end(getEndFunction(successActionType, errorActionType, additionalActionFields, url));
    }
};

export function putToBackend(url, successActionType, errorActionType, data, additionalActionFields) {
    log.addStartedRequest(url, 'put', successActionType);
    let req = request
        .put(getFullUrl(url))
        .set(constants.frontEndRequestCsrfHeaderName, csrfToken.get());

    req.send(data)
        .end(getEndFunction(successActionType, errorActionType, additionalActionFields));
};
