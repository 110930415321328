import React from 'react';

import StoreComponent from './base/StoreComponent.jsx';
import {PageHeader} from '@vaultit/stv-utils-frontend';

class GeneralError extends StoreComponent {

    renderWhenReady() {
        return (
            <PageHeader
                pageTitleMessageId="error.title"
                sharedStore={this.state.shared}
            />
        );
    }
}

export default GeneralError;
