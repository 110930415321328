import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

export default function (orderId=false) {
    let url = '/cards/pending-card-orders';
    if (orderId) {
        url += `/${orderId}`;
    }
    getFromBackend(url,
        ActionTypes.CARDS_GET_PENDNG_CARD_ORDERS_SUCCESS,
        ActionTypes.CARDS_GET_PENDNG_CARD_ORDERS_ERROR);
};
