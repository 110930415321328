import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let suspendCardOrderBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.SUSPEND_CARD_ORDER_BTN_CLICKED
    });
};

export default suspendCardOrderBtnClicked;
