import ActionTypes from '../ActionTypes.js';
import dispatcher from '../../Dispatcher.js';


let routeEntered = function(route) {
    dispatcher.dispatch({
        type: ActionTypes.ROUTE_ENTERED,
        route: route
    });
};

export default routeEntered;