import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let reactivateAllCompanyCardsBtnClicked = function(event, organizationId) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.REACTIVATE_ALL_COMPANY_CARDS_BTN_CLICKED,
        organizationId
    });
};

export default reactivateAllCompanyCardsBtnClicked;
