import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import clearFilterBtnClickedHandler from '../../actions/actionCreators/cards/ClearFilterBtnClickedHandler.js';
import { PageHeader, FormRow, FormColumn, SelectInput, Message, TextInput, FormTable, formatHelpers, DateLabel, ConfirmationModal} from '@vaultit/stv-utils-frontend';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import { Link } from 'react-router';
import Pagination from '../common/Pagination.jsx';
import constants from '../../Constants.js';
import suspendAllCompanyCardsBtnClicked from '../../actions/actionCreators/cards/SuspendAllCompanyCardsBtnClicked.js'
import reactivateAllCompanyCardsBtnClicked from '../../actions/actionCreators/cards/ReactivateAllCompanyCardsBtnClicked.js'


const messages = defineMessages({
    searchPlaceholder: {
        id: 'organization.cards.searchInputPlaceholder',
        description: 'Search input placeholder',
        default: 'Cardholder name or card number',
    },
    listAllFilter: {
        id: "cards.list.allTab",
        description: 'Card management filter options all ',
        default: 'All cards and orders',
    },
    listPendingFilter: {
        id: 'cards.list.pendingTab',
        description: 'Card management filter options pending',
        default: 'Pending card orders',
    },
    listCancelledFilter: {
        id: 'cards.list.cancelledTab',
        description: 'Card management filter options cancelled',
        default: 'Cancelled orders',
    },
    listActiveFilter: {
        id: 'cards.list.activeTab',
        description: 'Card management filter options active',
        default: 'Personalized and active cards',
    },
    listInactiveFilter: {
        id: 'cards.list.inactiveTab',
        description: 'Card management filter options inactive',
        default: 'Inactive cards',
    },
    allCompanyCardsModalOkText: {
        id: 'cards.list.allCompanyCardsModalOkText',
        description: 'Ok modal text',
        defaultMessage: 'Yes'
    },
    allCompanyCardsModalCancelText: {
        id: 'cards.list.allCompanyCardsModalCancelText',
        description: 'Remove modal text',
        defaultMessage: 'Cancel'
    },
    suspendAllCompanyCardsModalTitleText: {
        id: 'cards.list.suspendAllCards',
        description: 'Modal title',
        defaultMessage: 'Suspend all company cards'
    },
    reactivateAllCompanyCardsModalTitleText: {
        id: 'cards.list.reactivateAllCards',
        description: 'Modal title',
        defaultMessage: 'Reactivate all company cards'
    }
});


class OrganizationCardList extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    getColorClass(status, filterStatus, row=false) {
        if (['suspended', 'terminated'].includes(status) && filterStatus == 'all' || status == 'rejected_by_personalizer' && filterStatus == 'pending') {
            return 'text-' + constants.statusColors[status];
        } else {
            return constants.statusColors[status] && !row ? 'text-' + constants.statusColors[status] : '';
        }
    }

    getCardDropdownItems(card, index, idPrefix) {
        let pathToCpbViewOrder = this.state.shared.currentRoute.params.cpbOrganizationId ? `${this.state.shared.currentRoute.params.cpbOrganizationId}/` : '';
        if (pathToCpbViewOrder) {
            this.state.shared.redirectToCpbList = this.state.shared.currentRoute.params.cpbOrganizationId;
        }

        if (!pathToCpbViewOrder && card.orgId) {
            pathToCpbViewOrder = `${card.orgId}/`;
        }
        let pathToViewOrder = '/' + this.state.shared.representedOrganizationCode + '/organization/' + pathToCpbViewOrder + 'cards/view-order/';

        let cardsDropdownMenu = (
            <div className="dropdown-menu dropdown-menu-right">
                <Link
                    id={idPrefix + "cards_list_view_order_link" + index}
                    to={pathToViewOrder + card.orderId}
                    className="dropdown-item"
                >
                    <FormattedMessage id="cards.list.viewOrder" defaultMessage="View order" />
                </Link>
            </div>
        );
        return cardsDropdownMenu;
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;
        let filterCardsForm = this.state.pendingCardOrdersStore.filterCardsForm;
        let orderInfo = this.state.pendingCardOrdersStore.cardOrdersInfo;
        let orderInfoLoaded = this.state.pendingCardOrdersStore.orderInfoLoaded;
        let organizationId = this.props.params.organizationCode;
        let pageHeader = (
            <PageHeader
                pageTitleMessageId="organization.cards.management"
                values={{organizationName: !(!orderInfo && !orderInfoLoaded) && orderInfo.viewedOrgName ? orderInfo.viewedOrgName : this.state.shared.representedOrgName}}
                sharedStore={this.state.shared}
            />
        );
        if (!orderInfo && !orderInfoLoaded) {
            return pageHeader;
        }

        return (
            <div className="cards-management">
                {pageHeader}
                {this.state.pendingCardOrdersStore.cardManagementShowSuccessfullyReactivatedCardMsg
                ?
                    <Message id='card_management_card_successfully_reactivated' type="success" defaultIcon={false} >
                        <FormattedMessage id="organization.cards.cardReactivatedMsg" defaultMessage="Success! The card has been reactivated." />
                    </Message>
                : null
                }
                {this.state.pendingCardOrdersStore.cardManagementShowSuccessfullyTerminatedCardMsg
                ?
                    <Message id='card_management_card_successfully_terminated' type="success" defaultIcon={false} >
                        <FormattedMessage id="organization.cards.cardTerminatedMsg" defaultMessage="Success! The card has been terminated." />
                    </Message>
                : null
                }
                {this.state.pendingCardOrdersStore.cardManagementShowSuccessfullySuspendedCardMsg
                ?
                    <Message id='card_management_card_successfully_suspended' type="success" defaultIcon={false} >
                        <FormattedMessage id="organization.cards.cardSuspendedMsg" defaultMessage="Success! The card has been suspended." />
                    </Message>
                : null
                }
                {this.state.pendingCardOrdersStore.showAllCardsSuspendedMsg
                ?
                    <Message id='card_management_all_org_cards_suspended' type="success" defaultIcon={true} >
                        <FormattedMessage id="organization.cards.allCardsSuspended" defaultMessage="{count} company cards were suspended"  values={{count: this.state.pendingCardOrdersStore.suspendedCardsCount}} />
                    </Message>
                : null
                }
                {this.state.pendingCardOrdersStore.showAllCardsReactivatedMsg
                ?
                    <Message id='card_management_all_org_cards_reactivated' type="success" defaultIcon={true} >
                        <FormattedMessage id="organization.cards.allCardsReactivated" defaultMessage="{count} company cards were reactivated"  values={{count: this.state.pendingCardOrdersStore.reactivatedCardsCount}} />
                    </Message>
                : null
                }
                {this.state.pendingCardOrdersStore.showCompanyHasToBeActiveMsg
                ?
                    <Message id='card_management_company_has_to_be_active' type="warning" defaultIcon={true} >
                        <FormattedMessage id="organization.cards.companyHasToBeActive" defaultMessage="Company has to be active to reactivate all cards" />
                    </Message>
                : null
                }
                <div className="help-link">
                    <Message>
                        <FormattedMessage id="organization.cards.informationLinkText" />
                        <a href={constants.moreInformationCardLink}><FormattedMessage id="organization.cards.informationLinkHere" /> </a>
                    </Message>
                </div>
                <div>
                    { filterCardsForm && orderInfo && orderInfo.pagination
                        ?
                            <div>
                                <p className="lead">
                                    <FormattedMessage id="organization.cards.searchInputPlaceholder" defaultMessage="Find a card by cardholder name, email or card number" />
                                </p>
                                <div id="cards-filter" className="card card-default mb-0">
                                    <div className="card-block">
                                        <form id="search-cards-form" onSubmit={ (e) => { e.preventDefault(); filterCardsForm.handleOnSubmitBtnClicked; } }>
                                            <FormRow>
                                                <FormColumn widthLg="6" widthMd="6">
                                                    <div className="form-group mb-0">
                                                        <TextInput
                                                            id="cards_list_filter_input"
                                                            field={filterCardsForm.fields.usersFilterValue}
                                                            inputGroup={true}
                                                            addonPossition="left"
                                                            autoFocus={true}
                                                            placeholder={ formatMessage(messages.searchPlaceholder) }
                                                        >
                                                            <span role="button" onClick={filterCardsForm.handleOnSubmitBtnClicked} className="input-group-addon text-primary bg-white">
                                                                <i id="filter-icon" className="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </TextInput>
                                                    </div>
                                                </FormColumn>
                                                <FormColumn className="mt-4 mt-md-0" widthLg="3" widthMd="3" lgOffSet="3" mdOffSet="3">
                                                    <SelectInput
                                                        id="company_search_by_status"
                                                        field={filterCardsForm.fields.usersFilterStatus}
                                                        options={[
                                                            {
                                                                value: 'all',
                                                                displayedText: formatMessage(messages.listAllFilter)
                                                            },
                                                            {
                                                                value: 'pending',
                                                                displayedText: formatMessage(messages.listPendingFilter)
                                                            },
                                                            {
                                                                value: 'cancelled',
                                                                displayedText: formatMessage(messages.listCancelledFilter)
                                                            },
                                                            {
                                                                value: 'active',
                                                                displayedText: formatMessage(messages.listActiveFilter)
                                                            },
                                                            {
                                                                value: 'inactive',
                                                                displayedText: formatMessage(messages.listInactiveFilter)
                                                            }
                                                        ]}
                                                    />
                                                </FormColumn>
                                            </FormRow>
                                        </form>
                                    </div>
                                </div>
                                { filterCardsForm.fields.usersFilterValue.value.length
                                    ?
                                        <div role="button" id="clear-filters" className="text-primary pull-right mb-6 mt-2 bg-white" onClick={clearFilterBtnClickedHandler}>
                                            <i className="fa fa-trash-o fa-mr" aria-hidden="true"></i>
                                            <FormattedMessage id="card.list.clearFilters" defaultMessage="Clear filters" />
                                        </div>
                                    : null
                                }
                                <div className="clearfix"></div>
                                <FormRow>
                                    <FormColumn width="12">
                                        <div className="text-primary pull-left mb-6 mt-2">
                                            <FormattedMessage
                                                id="cards.list.cardsFound"
                                                defaultMessage="{number} record(s) found"
                                                values={{number: orderInfo.pagination.totalItems || '0'}}
                                            />
                                        </div>
                                        {this.state.pendingCardOrdersStore.userCanActOnAllCards
                                                ?
                                                    <div className="form-group mt-4 pull-right">
                                                        <button
                                                            role="button"
                                                            id="suspend_all_cards"
                                                            type="button"
                                                            className="btn btn-primary mr-4"
                                                            data-toggle="modal"
                                                            data-target="#suspend-all-cards-modal"
                                                        >
                                                            <FormattedMessage
                                                                id="cards.list.suspendAllCards"
                                                                defaultMessage="Suspend all company cards"
                                                            />
                                                        </button>
                                                        <ConfirmationModal
                                                            id={`suspend-all-cards-modal`}
                                                            okButtonText={formatMessage(messages.allCompanyCardsModalOkText)}
                                                            cancelButtonText={formatMessage(messages.allCompanyCardsModalCancelText)}
                                                            title={formatMessage(messages.suspendAllCompanyCardsModalTitleText)}
                                                            handleActionType={ (e) => suspendAllCompanyCardsBtnClicked(e, organizationId) }
                                                            okBtnClass="btn btn-primary"
                                                            modalClass="text-left"
                                                            sharedStore={this.state.pendingCardOrdersStore.shared}
                                                            dismissModalAfterClick={true}
                                                        >
                                                            <FormattedMessage id="cards.list.suspendAllCardsModalText" defaultMessage="Do you want to suspend all company cards?" />
                                                        </ConfirmationModal>
                                                        <button
                                                            role="button"
                                                            id="reactivate_all_cards"
                                                            type="button"
                                                            className="btn btn-primary mr-1"
                                                            data-toggle="modal"
                                                            data-target="#reactivate-all-cards-modal"
                                                        >
                                                            <FormattedMessage
                                                                id="cards.list.reactivateAllCards"
                                                                defaultMessage="Reactivate all company cards"
                                                            />
                                                        </button>
                                                        <ConfirmationModal
                                                            id={`reactivate-all-cards-modal`}
                                                            okButtonText={formatMessage(messages.allCompanyCardsModalOkText)}
                                                            cancelButtonText={formatMessage(messages.allCompanyCardsModalCancelText)}
                                                            title={formatMessage(messages.reactivateAllCompanyCardsModalTitleText)}
                                                            handleActionType={ (e) => reactivateAllCompanyCardsBtnClicked(e, organizationId) }
                                                            okBtnClass="btn btn-primary"
                                                            modalClass="text-left"
                                                            sharedStore={this.state.pendingCardOrdersStore.shared}
                                                            dismissModalAfterClick={true}
                                                        >
                                                            <FormattedMessage id="cards.list.reactivateAllCardsModalText" defaultMessage="Do you want to reactivate all company cards?" />
                                                        </ConfirmationModal>
                                                    </div>
                                                : null}
                                    </FormColumn>
                                </FormRow>
                                <div className="clearfix"></div>
                            </div>
                        : null
                    }
                    {!orderInfo.orders.length && this.state.pendingCardOrdersStore.showSearchStatusMessages
                        ?
                            <Message id="no-cards-found-list" type="warning" className="mt-4">
                                <FormattedMessage
                                    id="organization.cards.noCardsFoundMessage"
                                    defaultMessage="No cards found"
                                />
                            </Message>
                        : null
                    }
                    {orderInfo.orders.length
                        ? (
                            <div className="mt-6">
                                <FormTable id="cards_list" className="form-table-striped form-table-hover mt-4">
                                    <FormRow header={true}>
                                        <FormColumn width="4">
                                            <a id="user_column">
                                                <FormattedMessage id="organization.cards.cardholder" defaultMessage="Cardholder" />
                                            </a>
                                        </FormColumn>
                                        <FormColumn width="2">
                                            <a id="card_ordered_column">
                                                <FormattedMessage id="organization.cards.statuses.ordered" defaultMessage="Ordered" />
                                            </a>
                                        </FormColumn>
                                        <FormColumn width="2">
                                            <a id="card_valid_until_column">
                                                <FormattedMessage id="pendingCardOrder.cardValidUntil" defaultMessage="Valid till" />
                                            </a>
                                        </FormColumn>
                                        <FormColumn width="3">
                                            <a id="card_status_column">
                                                <FormattedMessage id="organization.cards.status" defaultMessage="Card status" />
                                            </a>
                                        </FormColumn>
                                        <FormColumn width="1" className="text-center">
                                            <a>
                                                <i className="fa fa-gear" aria-hidden="true"></i>
                                            </a>
                                        </FormColumn>
                                    </FormRow>
                                    {orderInfo.orders.map((c, i) => {
                                        let trasnlationKeyForCardStatus = formatHelpers.formatStatusToTranslationKey('cardapi.cardStatus.', c.status);
                                        return (
                                            <FormRow className={this.getColorClass(c.status, filterCardsForm.fields.usersFilterStatus.value, true)} key={i} role="button">
                                                <FormColumn mobileHeader={true} width="9">
                                                    <FormattedMessage id="users.list.title.user" defaultMessage="User" />
                                                </FormColumn>
                                                <FormColumn width="4" onClick={c.handleRowClick}>
                                                    <div className="pull-left">
                                                        <p id={"users_list_name_" + i} className={c.isActive ? 'font-weight-bold':''}>{c.printableName}</p>
                                                    </div>
                                                    <div className="pull-right hidden-md-up" onClick={(e) => e.stopPropagation()}>
                                                        <div id="mobile_users_list_options_dropdown" className="dropdown">
                                                            <button role="button" className="btn btn-link btn-sm py-1 px-3" id={"mobile_users_list_options_dropdown_" + i} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fa fa-cog p-0 fa-lg text-secondary" aria-hidden="true"></i>
                                                            </button>
                                                            {this.getCardDropdownItems(c, i, 'mobile_')}
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </FormColumn>
                                                <FormColumn mobileHeader={true} width="9">
                                                    <FormattedMessage id="users.list.title.ordered" defaultMessage="Ordered" />
                                                </FormColumn>
                                                <FormColumn width="2" onClick={c.handleRowClick}>
                                                    <p id={'users_list_ordered_' + i}>
                                                        {c.created_date
                                                        ?
                                                            <DateLabel id="card_info_created_date">{c.created_date}</DateLabel>
                                                        : null
                                                        }
                                                    </p>
                                                </FormColumn>
                                                <FormColumn mobileHeader={true} width="9">
                                                    <FormattedMessage id="users.list.title.validUntil" defaultMessage="Valid till" />
                                                </FormColumn>
                                                <FormColumn width="2" onClick={c.handleRowClick}>
                                                    <p id={'users_list_valid_until_' + i}>
                                                        <FormattedMessage id="users.list.validUntilText" defaultMessage={c.validUntil} />
                                                    </p>
                                                </FormColumn>
                                                <FormColumn mobileHeader={true} width="9">
                                                    <FormattedMessage id="organization.cards.status" defaultMessage="Card status" />
                                                </FormColumn>
                                                <FormColumn width="3" onClick={c.handleRowClick}>
                                                    <p id={'users_list_card_status_' + i} className={this.getColorClass(c.status, filterCardsForm.fields.usersFilterStatus.value)}>
                                                        <FormattedMessage id={trasnlationKeyForCardStatus} defaultMessage={trasnlationKeyForCardStatus} />
                                                    </p>
                                                </FormColumn>
                                                <FormColumn width="1" className="text-right hidden-sm-down">
                                                    <div id="users_list_options_dropdown" className="dropdown">
                                                        <button role="button" className="btn btn-primary btn-sm" id={"users_list_options_dropdown_" + i} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa-fw fa fa-caret-down pr-0" aria-hidden="true"></i>
                                                        </button>
                                                        {this.getCardDropdownItems(c, i, '')}
                                                    </div>
                                                </FormColumn>
                                            </FormRow>
                                        );
                                    })}
                                </FormTable>
                            </div>
                        )
                            : null
                    }
                    { orderInfo && orderInfo.pagination
                        ?
                            <Pagination
                                totalItems={orderInfo.pagination.totalItems}
                                itemsPerPage={orderInfo.pagination.itemsPerPage}
                                currentFullPath={this.state.shared.currentRoute.fullPathWithOrgCode}
                                currentPage={this.state.pendingCardOrdersStore.currentCardsListPage}
                                isVisible={Boolean(orderInfo.orders.length)}
                            />
                        : null
                    }
                </div>
            </div>
        );

    }
}

export default OrganizationCardList;
