import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let confirmOrderSignInBtnClicked = function(event, method) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CONFIRM_ORDER_SIGN_IN_BTN_CLICKED,
        method: method
    });
};

export default confirmOrderSignInBtnClicked;
