import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let signOutBtnClicked = function() {
    dispatcher.dispatch({
        type: ActionTypes.SIGN_OUT_BTN_CLICKED
    });
};

export default signOutBtnClicked;