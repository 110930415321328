import React from "react";
import PropTypes from "prop-types";
import showPin from '../../actions/actionCreators/cards/ShowPin';
import StoreComponent from '../base/StoreComponent.jsx';
import hidePin from "../../actions/actionCreators/cards/HidePin";
import '../../../img/images';


class ShowPinCode extends StoreComponent {
    static get propTypes() {
        return {
            order: PropTypes.object,
        };
    }

    isLoading() {
        return this.state.pendingCardOrdersStore.pinLoading === true
            && this.state.pendingCardOrdersStore.pinLoadingCardId === this.props.order.orderId
    }

    handleShowPasswordClicked() {
        if (!this.isLoading())
          showPin(this.props.order.orderId,
              {cardNumber: this.props.order.cardNumber});
    }

    handleHidePasswordClicked() {
        hidePin();
    }

    isPinAvailableForCurrentCard() {
        return this.state.pendingCardOrdersStore.showPin
            && !this.isLoading()
            && this.state.pendingCardOrdersStore.pin !== ""
            && this.state.pendingCardOrdersStore.pinCardId === this.props.order.orderId;
    }

    render() {
        if (this.props.order.status !== 'activated') {
            return null
        }

        if (this.isPinAvailableForCurrentCard()) {
            return (<div className="pin-code visible">
                <div onClick={(e) => {this.handleHidePasswordClicked(); e.stopPropagation();}}>
                    {this.state.pendingCardOrdersStore.pin}
                </div>
                <div onClick={(e) => {this.handleHidePasswordClicked(); e.stopPropagation();}}></div>
            </div>
            )
        }
        return (<div className={`pin-code hidden ${this.isLoading()?"loading":""}`}>
            <div onClick={(e) => {this.handleShowPasswordClicked(); e.stopPropagation()}}>&bull;&bull;&bull;&bull;&bull;</div>
            <div onClick={(e) => {this.handleShowPasswordClicked(); e.stopPropagation()}}></div>
        </div>);
    }
}

export default ShowPinCode;
