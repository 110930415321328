import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let searchCardsForListDisplay = function(filter, org_id=false, orgIdViewedByCpb=false) {
    
    let url = 'cards/orders-for-display'

    if (org_id) {
        url += `/${org_id}`;
    }

    if (orgIdViewedByCpb) {
        url += `/${orgIdViewedByCpb}`;
    }

    postToBackend(url,
        ActionTypes.GET_FILTERED_CARDS_LIST_SUCCESS,
        ActionTypes.GET_FILTERED_CARDS_LIST_ERROR,
        filter,
        {
            clearPreviousErrors: true
    });
};

export default searchCardsForListDisplay;
