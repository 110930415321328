import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let cardOrderRowClickedHandler = function(orderId, status) {
    let cardOrderRowClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.CARD_ORDER_LIST_ROW_CLICKED,
            orderId: orderId,
            status: status
        });
    };

    return cardOrderRowClicked;
};

export default cardOrderRowClickedHandler;
