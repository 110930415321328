import dispatcher from '../Dispatcher.js';
import SharedStore from './base/SharedStore.js';
import CounterStore from './base/CounterStore.js';

import MenuItemsStore from './MenuItemsStore.js';
import PageLoaderStore from './PageLoaderStore.js';
import CardReviewLandingPageStore from './cards/CardReviewLandingPageStore.js';
import PendingCardOrdersStore from './cards/PendingCardOrdersStore.js';
import GlobalUserSearchStore from './cards/GlobalUserSearchStore.js';

/* This list must be maintained: */

let allStoreClasses = {
    menuItems: MenuItemsStore,
    home: PageLoaderStore,
    cardReviewLandingPageStore: CardReviewLandingPageStore,
    pendingCardOrdersStore: PendingCardOrdersStore,
    globalUserSearchStore: GlobalUserSearchStore
};

/* Create all FLUX stores: */

let allStores = {};

let sharedStore = new SharedStore(dispatcher);
allStores['shared'] = sharedStore;

Object.keys(allStoreClasses).forEach((storeKey) => {
    let storeClass = allStoreClasses[storeKey];
    allStores[storeKey] = new storeClass(dispatcher, sharedStore);
});

allStores['counter'] = new CounterStore(dispatcher, sharedStore);

export default allStores;
