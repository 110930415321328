import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import { Message, InputLabel, FormRow, FormColumn, FormGroup, FormTable, TextInput,
    PhoneNumberInputComponent, ValidationMessages, CheckboxInput, ConfirmationModal, formatHelpers, PageHeader } from '@vaultit/stv-utils-frontend';
import rejectCardOrderBtnClicked from '../../actions/actionCreators/cards/RejectCardOrderBtnClicked.js';
import suspendCardOrderBtnClicked from '../../actions/actionCreators/cards/SuspendCardOrderBtnClicked.js';
import terminateCardOrderBtnClicked from '../../actions/actionCreators/cards/TerminateCardOrderBtnClicked.js';
import reactivateCardOrderBtnClicked from '../../actions/actionCreators/cards/ReactivateCardOrderBtnClicked.js';
import backToAllOrdersListBtnClicked from '../../actions/actionCreators/cards/BackToAllOrdersListBtnClicked.js';
import backToWaitingOrdersBtnClicked from '../../actions/actionCreators/cards/BackToWaitingOrdersBtnClicked.js';
import activateCardOrderModalOpened from '../../actions/actionCreators/cards/ActivateCardOrderModalOpened.js';
import CardNumberInputModal from '../common/CardNumberInputModal.jsx';
import activateCardOrderBtnClicked from '../../actions/actionCreators/cards/ActivateCardOrderBtnClicked.js';
import constants from '../../Constants.js';
import ReadyToActivateCard from "../common/ReadyToActivateCard.jsx";
import ShowPinCode from "../common/ShowPinCode.jsx";


const messages = defineMessages({
    holderEmailPlaceholder: {
        id: 'pendingCardOrder.holderEmail',
        description: 'Email field placeholder',
        defaultMessage: 'Your email'
    },
    otherPartyEmailPlaceholder: {
        id: 'pendingCardOrder.otherPartyEmail',
        description: 'Email field placeholder',
        defaultMessage: 'Contact email'
    },
    confirmActivateCardModalTitle: {
        id: 'pendingCardOrder.confirmActivateCardModalTitle',
        description: 'Title for card activation confirmation',
        defaultMessage: 'Terminate existing active card?'
    },
    confirmActivateCardModalConfirmBtnText: {
        id: 'pendingCardOrder.confirmActivateCardModalConfirmBtnText',
        description: 'Confirm button for card activation confirmation',
        defaultMessage: 'Yes'
    },
    confirmActivateCardModalDeclineBtnText: {
        id: 'pendingCardOrder.confirmActivateCardModalDeclineBtnText',
        description: 'Decline button for card activation confirmation',
        defaultMessage: 'No'
    }
});

class PendingCardOrders extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    getValidatedFieldClass(conditionValue) {
        let className = "font-weight-bold text-wrap";
        if (!conditionValue)
            className += " text-danger";
        return className;
    }

    getCardTypesToDisplay(cardType) {
        return constants.cardTypesToDisplay[cardType];
    }

    getCardActionOptions(activationEnabled, suspendingEnabled, terminationEnabled) {
        let activeOptionClassName = "dropdown-item";
        let disabledOptionClassName = "dropdown-item disabled";
        let options = (
            <div className="dropdown-menu">

                <button
                    id="review_card_order_reactivate"
                    disabled={!activationEnabled}
                    className={activationEnabled ? activeOptionClassName : disabledOptionClassName}
                    onClick={reactivateCardOrderBtnClicked}
                >
                    <FormattedMessage id="pendingCardOrder.reactivate" defaultMessage="Reactivate" />
                </button>

                <button
                    id="review_card_order_suspend"
                    disabled={!suspendingEnabled}
                    className={suspendingEnabled ? activeOptionClassName : disabledOptionClassName}
                    onClick={suspendCardOrderBtnClicked}
                >
                    <FormattedMessage id="pendingCardOrder.suspend" defaultMessage="Suspend" />
                </button>

                <button
                    id="review_card_order_terminate"
                    href={!terminationEnabled}
                    disabled={!terminationEnabled}
                    className={terminationEnabled ? activeOptionClassName : disabledOptionClassName}
                    onClick={terminateCardOrderBtnClicked}
                >
                    <FormattedMessage id="pendingCardOrder.terminate" defaultMessage="Terminate" />
                </button>

            </div>
        );
        return options;
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let orderInfoLoaded = this.state.pendingCardOrdersStore.orderInfoLoaded;
        let currentOrderInfo = this.state.pendingCardOrdersStore.currentOrderInfo;
        let currentOrderAction = this.state.pendingCardOrdersStore.currentOrderAction;
        let currentOrderStatus = this.state.pendingCardOrdersStore.currentOrderStatus;
        let cardOrderForm = this.state.pendingCardOrdersStore.cardOrderForm;
        let cardOrderFields = cardOrderForm ? cardOrderForm.fields : null;
        let virtualCardForm = this.state.pendingCardOrdersStore.virtualCardForm;
        let virtualCardFormFields = virtualCardForm ? virtualCardForm.fields : null;
        let currentOrderActionNotActivate = this.state.pendingCardOrdersStore.currentOrderActionNotActivate;
        let viewPendingCardOrderPage = currentOrderActionNotActivate ? this.state.pendingCardOrdersStore.viewPendingCardOrderPage : this.state.pendingCardOrdersStore.viewPendingCardOrderPage && currentOrderInfo.canBeActivated;
        let formFieldsEnabled = viewPendingCardOrderPage
        let activateCardForm = this.state.pendingCardOrdersStore.cardActivationForm;
        let dismissActivateCardModalAfterClick = this.state.pendingCardOrdersStore.dismissActivateCardModalAfterClick;
        let showInvalidCardNumberEnteredMsg = this.state.pendingCardOrdersStore.showInvalidCardNumberEnteredMsg;
        let hideCardNumberInputModal = this.state.pendingCardOrdersStore.hideCardNumberInputModal;

        let cardActivationInputModalWindow = (
            <CardNumberInputModal
                form={ activateCardForm }
                dismissModalAfterClick={ dismissActivateCardModalAfterClick }
                action={"activate"}
                showPinErrorIncorrectNumber={showInvalidCardNumberEnteredMsg}
                hideModal={hideCardNumberInputModal}
                sharedStore={this.state.pendingCardOrdersStore.shared}
            />);

        let showConfirmActivationForCardModal = this.state.pendingCardOrdersStore.showConfirmActivationForCardModal;
        let alreadyActivatedCardNumber = this.state.pendingCardOrdersStore.alreadyActivatedCardNumber;
        let userCameFromAllCards = this.state.pendingCardOrdersStore.userCameFromAllCards;
        let showBackButton = !this.state.pendingCardOrdersStore.hideBackButton;
        let statusTranslationKey = '';
        let holderIsViewingCard = this.state.pendingCardOrdersStore.holderIsViewingCard;
        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;
        let virtualCardFunctionalityEnabled = uiConfiguration.servicePortal.cardOrdering.virtualCardEnabled;
        let userCanEditOrgCards = this.state.pendingCardOrdersStore.userCanEditOrgCards;
        let displayCardActionOptions = false;
        if (holderIsViewingCard || userCanEditOrgCards) {
            displayCardActionOptions = currentOrderInfo && (currentOrderInfo.canBeActivated || currentOrderInfo.canBeSuspended || currentOrderInfo.canBeTerminated);
        }
        let mismatchDataSource = currentOrderInfo.verificationSource

        if (orderInfoLoaded && currentOrderInfo) {
                if (holderIsViewingCard) {
                    statusTranslationKey = formatHelpers.formatStatusToTranslationKey('cardapi.cardStatus.personal.', currentOrderInfo.status);
                    statusTranslationKey = this.context.intl.messages[statusTranslationKey] ? statusTranslationKey : '';
                }

                if (!statusTranslationKey) {
                    statusTranslationKey = formatHelpers.formatStatusToTranslationKey('cardapi.cardStatus.', currentOrderInfo.status);
                }
        }

        let countryOptions = [];
        for (let key in this.state.shared.classifiers.countries) {
            let item = this.state.shared.classifiers.countries[key];
            if (item.code) {
                countryOptions.push({
                    value: item.code,
                    displayedText: item.name
                });
            } else {
                countryOptions.push({disbled:true});
            }
        }

        let getNationalityCodeValue = (nationalityCode) => {
            let value = "";
            if (nationalityCode) {
                for (let key in this.state.shared.classifiers.countries) {
                    let item = this.state.shared.classifiers.countries[key];
                    if (item.code == nationalityCode.toLowerCase()) {
                        return item.name;
                    }
                }
            }
            return value;
        }

        let getNationalityCodesValues = (nationalityCodes) => {
            let value = "";

            if (nationalityCodes) {
                nationalityCodes.forEach((nationalityCode) => {
                    if (nationalityCode) {
                        let returnedValue = getNationalityCodeValue(nationalityCode);
                        value += (value.length ? ', ': '') + returnedValue;
                    }
                })
            }
            return value;
        }

        return (
            <div>
                { cardOrderForm && orderInfoLoaded && currentOrderInfo
                ?

                    <div>
                        { viewPendingCardOrderPage
                            ?
                                <PageHeader
                                    pageTitleMessageId={orderInfoLoaded ? "pendingCardOrder." + currentOrderAction + ".title" : ''}
                                    sharedStore={this.state.shared}
                                    hideSeparator={true}
                                />
                            :
                                <PageHeader
                                    pageTitleMessageId={holderIsViewingCard ? "viewCardOrder.personal.title" : "viewCardOrder.title"}
                                    sharedStore={this.state.shared}
                                    hideSeparator={true}
                                />
                        }
                        { !currentOrderInfo.namesIsValid
                        ?
                            <Message id='pending_card_order_name_incorrect' type="danger" defaultIcon={true}>
                                <FormattedMessage
                                    id="pendingCardOrder.informationMismatchName"
                                    defaultMessage="Card order name to be printed, does not match personal data."
                                />
                                <FormattedMessage
                                    id={"pendingCardOrder.nameMismatch"}
                                    values={{
                                        orderName: currentOrderInfo.printableNamesForDisplay,
                                        personName: currentOrderInfo.fullName,
                                    }}
                                />
                                { mismatchDataSource === constants.personVerificationSources.manualIDVerification
                                ?
                                    <FormattedMessage id={"pendingCardOrder.sourceScannedID"} />
                                : null }
                                { mismatchDataSource === constants.personVerificationSources.bankID
                                ?
                                    <FormattedMessage id={"pendingCardOrder.name.sourceBankID"} />
                                : null }
                            </Message>
                        : null
                        }
                        { !currentOrderInfo.nationalityIsValid
                        ?
                            <Message id='pending_card_order_nationality_incorrect' type="danger" defaultIcon={true}>
                                <FormattedMessage
                                    id="pendingCardOrder.informationMismatchNationality"
                                    defaultMessage="Card order nationality to be printed, does not match personal data."
                                />
                                <FormattedMessage
                                    id={"pendingCardOrder.nationalityMismatch"}
                                    values={{
                                        personNationality: getNationalityCodesValues(currentOrderInfo.verifiedNationalities),
                                        orderNationality: getNationalityCodesValues(currentOrderInfo.nationality)
                                    }}
                                />
                                { mismatchDataSource === constants.personVerificationSources.manualIDVerification
                                ?
                                    <FormattedMessage id={"pendingCardOrder.sourceScannedID"} />
                                : null }
                                { mismatchDataSource === constants.personVerificationSources.bankID
                                ?
                                    <FormattedMessage id={"pendingCardOrder.nationality.sourceBankID"} />
                                : null }
                            </Message>
                        : null }

                        {this.state.pendingCardOrdersStore.showLMAcardNotAllowedMsg
                        ?
                            <Message id="lma_card_not_allowed_for_user" type="danger">
                                <FormattedMessage
                                    id="pendingCardOrder.lmaCardNotAllowed"
                                    defaultMessage="Your identity has to be verified using your LMA card"
                                />.
                            </Message>
                        : null
                        }

                        {this.state.pendingCardOrdersStore.showNordicCardNotAllowedMsg
                        ?
                            <Message id="nordic_card_not_allowed_for_user" type="danger">
                                <FormattedMessage
                                    id="pendingCardOrder.nordicCardNotAllowed"
                                    defaultMessage="Your identity has to be verified using your passport or eID"
                                />.
                            </Message>
                        : null
                        }

                        { viewPendingCardOrderPage
                            ?
                                <Message id="warningMessage" type="warning">
                                    <FormattedMessage
                                        id={"pendingCardOrder." + currentOrderAction + ".explanation"}
                                        values={{
                                            cardType:this.getCardTypesToDisplay(currentOrderInfo.cardType),
                                            companyName: currentOrderInfo.orgName
                                        }}
                                    />
                                </Message>
                            : null
                        }
                        { this.state.pendingCardOrdersStore.virtualCardEnabledSuccessfully
                            ?
                                <Message id='virtual-card-enabled-successfully' type="success" >
                                    <FormattedMessage id="pendingCardOrder.virtualCardEnabledSuccessFully" defaultMessage="Virtual card enabled successfully." />
                                </Message>
                            : null
                        }
                        { this.state.pendingCardOrdersStore.virtualCardDisabledSuccessfully
                            ?
                                <Message id='virtual-card-disabled-successfully' type="success" >
                                    <FormattedMessage id="pendingCardOrder.virtualCardDisabledSuccessFully" defaultMessage="Virtual card disabled successfully." />
                                </Message>
                            : null
                        }
                        { this.state.pendingCardOrdersStore.cardActivationIsForbiden
                            ?
                                <Message id="card-activation-is-forbiden" type="danger" >
                                    <FormattedMessage id="pendingCardOrder.cardActivationIsForbiden" defaultMessage="The card cannot be activated because your company is not active" />
                                </Message>
                            : null
                        }
                        <FormRow>
                            <FormColumn width="8">
                                <FormTable id="card_order_details" className="form-table-striped">
                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.orgName" defaultMessage="Company name" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                {currentOrderInfo.orgName}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.orgNumber" defaultMessage="Company number" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                {currentOrderInfo.orgCode}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.cardSupplier" defaultMessage="Card supplier" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                {currentOrderInfo.cpb.name}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.fullName" defaultMessage="Full name" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                {currentOrderInfo.fullName}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.printableName" defaultMessage="Name to print" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className={this.getValidatedFieldClass(currentOrderInfo.namesIsValid)}>
                                                {currentOrderInfo.printableNamesForDisplay}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.dateOfBirth" defaultMessage="Date of brith" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                {currentOrderInfo.dateOfBirth}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.nationality" defaultMessage="Nationality" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className={this.getValidatedFieldClass(currentOrderInfo.nationalityIsValid)}>
                                                {getNationalityCodesValues(currentOrderInfo.nationality)}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.verifiedNationality" defaultMessage="Verified nationality" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                {getNationalityCodesValues(currentOrderInfo.verifiedNationalities)}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.validFrom" defaultMessage="Card valid from" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                {currentOrderInfo.validFrom}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.validUntil" defaultMessage="Card valid until" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                {currentOrderInfo.validUntil}
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormattedMessage id="pendingCardOrder.status" defaultMessage="Status" />
                                        </FormColumn>
                                        <FormColumn width="8">
                                            <p className="font-weight-bold">
                                                <ReadyToActivateCard order={currentOrderInfo} statusTranslation={statusTranslationKey} />
                                            </p>
                                        </FormColumn>
                                    </FormRow>

                                    {currentOrderInfo && currentOrderInfo.cardBelongsToCurrentUser && (<React.Fragment>
                                        <FormRow>
                                            <FormColumn width="4">
                                                <FormattedMessage id="pendingCardOrder.pinCode" defaultMessage="Pin code" />
                                            </FormColumn>
                                            <FormColumn width="8">
                                                <ShowPinCode order={currentOrderInfo} />
                                            </FormColumn>
                                        </FormRow>
                                    </React.Fragment>)}
                                    {virtualCardFunctionalityEnabled
                                    ?
                                        <FormRow>
                                            <FormColumn width="4">
                                                <FormattedMessage id="pendingCardOrder.virtualCard" defaultMessage="Virtual card" />
                                            </FormColumn>
                                            <FormColumn width="8">
                                                { this.state.pendingCardOrdersStore.userCanChangeVirtualCardStatus
                                                ?
                                                    <CheckboxInput
                                                        id="enable-virtual-card"
                                                        checked={virtualCardFormFields.virtualCardEnabled.value}
                                                        field={virtualCardFormFields.virtualCardEnabled}
                                                        disabled={!this.state.pendingCardOrdersStore.userCanChangeVirtualCardStatus}
                                                    />
                                                :
                                                    <p className="font-weight-bold" id="virtual_card_status">
                                                        <FormattedMessage
                                                            id={this.state.pendingCardOrdersStore.virtualCardIsEnabled ? "pendingCardOrder.virtualCardEnabled": "pendingCardOrder.virtualCardDisabled"}
                                                            defaultMessage="Disabled"
                                                        />
                                                    </p>
                                                }
                                            </FormColumn>
                                        </FormRow>
                                    : null}


                                    {currentOrderStatus == 'cancelled_by_holder' && currentOrderInfo.showRejectionReason
                                    ?
                                        <FormRow>
                                            <FormColumn width="4">
                                                <p className="font-weight-bold text-danger">
                                                    <FormattedMessage id="pendingCardOrder.rejectionReason" defaultMessage="Rejection reason" />
                                                </p>
                                            </FormColumn>
                                            <FormColumn width="8">
                                                <p className="font-weight-bold text-danger">
                                                    {currentOrderInfo.rejectionReason}
                                                </p>
                                            </FormColumn>
                                        </FormRow>
                                    : null}

                                </FormTable>
                            </FormColumn>
                            <FormColumn width="4">
                                <div className="card">
                                    <div className="card-body holder-image-wrapper text-center">
                                        <img className="p-4" src={this.state.pendingCardOrdersStore.holderImg} alt="holder_img" />
                                    </div>
                                </div>
                            </FormColumn>
                        </FormRow>
                        {currentOrderActionNotActivate || !viewPendingCardOrderPage
                        ?
                            <form name="respondToPendingCardOrders" noValidate="">
                                <PageHeader
                                    pageTitleMessageId="pendingCardOrder.contactsTitle"
                                    sharedStore={this.state.shared}
                                    smallerPageTitle={true}
                                    className="font-weight-bold"
                                    // Hiding error message because there is another pageHeader a the top of the file
                                    // Top header will display error message
                                    hideErrorMessge={true}
                                />
                                <FormRow>
                                    <FormColumn width="6">
                                        <FormGroup>
                                            <ValidationMessages field={cardOrderFields.phone} componentCustomValidation="errors.validation.field.componentCustomValidation.phoneNumber">
                                                <InputLabel
                                                    required={formFieldsEnabled}
                                                    htmlFor="pending_card_order_phone"
                                                    messageId={holderIsViewingCard ? "pendingCardOrder.holderPhone" : "pendingCardOrder.otherPartyPhone"}
                                                    defaultMessage="Contact phone"
                                                />
                                                <PhoneNumberInputComponent
                                                    id="pending_card_order_phone"
                                                    className="form-control"
                                                    value={cardOrderFields.phone.value || ""}
                                                    onChange={cardOrderFields.phone.handleOnChange}
                                                    preferredCountries={['se', 'ee', 'fi']}
                                                    initialCountry={currentOrderInfo.country || null}
                                                    disabled={!formFieldsEnabled}
                                                />
                                            </ValidationMessages>
                                        </FormGroup>
                                    </FormColumn>
                                    <FormColumn width="6">
                                        <FormGroup>
                                            <ValidationMessages field={cardOrderFields.email}>
                                                <TextInput
                                                    id="pending_card_order_email"
                                                    field={cardOrderFields.email}
                                                    messageId={holderIsViewingCard ? "pendingCardOrder.holderEmail" : "pendingCardOrder.otherPartyEmail"}
                                                    defaultMessage="Your email"
                                                    placeholder={formatMessage(holderIsViewingCard ? messages.holderEmailPlaceholder : messages.otherPartyEmailPlaceholder)}
                                                    required={formFieldsEnabled}
                                                    disabled={true}
                                                />
                                            </ValidationMessages>
                                        </FormGroup>
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn width="12">
                                        <FormGroup className="mt-6">
                                            {viewPendingCardOrderPage
                                                ?
                                                    <button
                                                        id="pending_card_order_accept"
                                                        className="btn btn-primary btn-lg mr-3"
                                                        role="button"
                                                        onClick={cardOrderForm.handleOnSubmitBtnClicked}
                                                        disabled={(currentOrderInfo && !currentOrderInfo.cardInfoIsValid) || this.state.pendingCardOrdersStore.disableAcceptOrderBtn}
                                                    >
                                                        <FormattedMessage id="pendingCardOrder.accept" defaultMessage="Accept" />
                                                    </button>
                                                : null
                                            }
                                            {viewPendingCardOrderPage
                                            ?
                                                <button
                                                    id="pending_card_order_reject"
                                                    className="btn btn-default btn-lg mr-3"
                                                    role="button"
                                                    onClick={rejectCardOrderBtnClicked}
                                                >
                                                    <FormattedMessage id="pendingCardOrder.reject" defaultMessage="Reject" />
                                                </button>
                                            : null}
                                            {viewPendingCardOrderPage && userCameFromAllCards && showBackButton
                                            ?
                                                <button
                                                    id="review_card_order_back"
                                                    className="btn btn-lg mr-3 btn-primary"
                                                    role="button"
                                                    onClick={backToAllOrdersListBtnClicked}
                                                >
                                                    <FormattedMessage id="viewCardOrder.back" defaultMessage="Back" />
                                                </button>
                                            : null}
                                            {!viewPendingCardOrderPage
                                                ?
                                                    <div>
                                                        {displayCardActionOptions
                                                            ?
                                                                <div className="dropdown d-inline-block">
                                                                    <button role="button" className="btn btn-lg mr-3 btn-primary" id={"card_actions_options_dropdown_button"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <FormattedMessage id="viewCardOrder.actions" defaultMessage="Actions" />
                                                                        <i className="fa-fw fa fa-caret-down pr-0" aria-hidden="true"></i>
                                                                    </button>
                                                                    {this.getCardActionOptions(currentOrderInfo.canBeActivated, currentOrderInfo.canBeSuspended, currentOrderInfo.canBeTerminated)}
                                                                </div>
                                                            : null
                                                        }
                                                        {showBackButton
                                                        ?
                                                            <button
                                                                id="review_card_order_back"
                                                                className="btn btn-lg mr-3 btn-primary"
                                                                role="button"
                                                                onClick={backToAllOrdersListBtnClicked}
                                                            >
                                                                <FormattedMessage id="viewCardOrder.back" defaultMessage="Back" />
                                                            </button>
                                                        : null
                                                        }
                                                        {!viewPendingCardOrderPage && currentOrderInfo.canBeReConfirmed}
                                                    </div>
                                                : null
                                            }
                                            {!viewPendingCardOrderPage && currentOrderInfo.canBeReConfirmed}
                                            <div className="clearfix"></div>
                                        </FormGroup>
                                    </FormColumn>
                                </FormRow>
                            </form>
                    :
                            <div>
                                {cardActivationInputModalWindow}
                                <ConfirmationModal
                                    id='pending_card_order_open_confirmation_modal'
                                    okButtonText={formatMessage(messages.confirmActivateCardModalConfirmBtnText)}
                                    cancelButtonText={formatMessage(messages.confirmActivateCardModalDeclineBtnText)}
                                    title={formatMessage(messages.confirmActivateCardModalTitle)}
                                    handleActionType={ (e) => activateCardOrderBtnClicked(e) }
                                    okBtnClass="btn btn-primary"
                                    modalClass="text-left"
                                    dismissModalAfterClick={true}
                                    showModal={showConfirmActivationForCardModal}
                                    sharedStore={this.state.pendingCardOrdersStore.shared}
                                >
                                    <FormattedMessage
                                        id="pendingCardOrder.confirmActivateCardModalDescription"
                                        defaultMessage="Your current card {cardNumber} will be terminated as only one active card for the company is allowed."
                                        values={{cardNumber: <strong>{alreadyActivatedCardNumber}</strong>}}
                                    />
                                </ConfirmationModal>
                                <button
                                    id="pending_card_order_open_modal"
                                    className="btn btn-primary btn-lg mr-3"
                                    role="button"
                                    data-toggle="modal"
                                    data-target="#card_activation_modal"
                                    onClick={activateCardOrderModalOpened}
                                    disabled={currentOrderInfo && !currentOrderInfo.cardInfoIsValid || !currentOrderInfo.readyToBeActivated}
                                >
                                    <FormattedMessage id="pendingCardOrder.activate" defaultMessage="Activate" />
                                </button>
                                <button
                                    id="back"
                                    className="btn btn-primary btn-lg mr-3"
                                    role="button"
                                    onClick={backToWaitingOrdersBtnClicked}
                                >
                                    <FormattedMessage id="viewCardOrder.back" defaultMessage="Back" />
                                </button>
                            </div>
                    }
                    </div>
                : null
                }
            </div>
        );
    }


}
export default PendingCardOrders;
