import "intl";
import React from 'react';
import ReactDOM from 'react-dom';
import log from './singletons/Log.js';
import '../styles/styles.scss';
import 'jquery';
import 'font-awesome/css/font-awesome.css';

import Routes from './Routes.js';
import { AppContainer } from 'react-hot-loader';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createHashHistory } from "history";
import request from 'superagent';

window.addEventListener('error', function(e) {
    log.addError(e);
});

/*
HACK: Does nothing but breaks the bfcache, meaning, that back button will always reload page.
This was needed to solve SP-491 bug (FF back button after logout opens up page without reloading anything).
Idea from: http://madhatted.com/2013/6/16/you-do-not-understand-browser-history
*/
window.addEventListener('unload', function() {});

/* Render router: */
Reflect.deleteProperty(AppContainer.prototype, 'unstable_handleError');

const browserHistory = createHashHistory({ basename: '' });
var reactPlugin = new ReactPlugin();

request.get("/api/general/aics").then((res) => {
    var connectionString = res.body['APPLICATIONINSIGHTS_CONNECTION_STRING'];
    var appInsights = new ApplicationInsights({
        config: {
            connectionString,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            }
        }
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((env) => {
        env.tags = env.tags || [];
        env.tags["ai.cloud.role"] = "Cards web app";
    });
});

const render = () => {
    ReactDOM.render(
        <AppContainer>
            <Routes />
        </AppContainer>,
        document.getElementById('container')
    );
};

render(Routes);

if (module.hot) {
    module.hot.accept('./Routes.js', () => { render(Routes); });
}
