import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let getCardHistory = function (data) {
    postToBackend('cards/get-card-history',
        ActionTypes.GET_CARD_HISTORY_SUCCESS,
        ActionTypes.GET_CARD_HISTORY_ERROR,
        data
    );
};

export default getCardHistory;
