import prepareUrl from '../../../helpers/PrepareUrl.js';
import redirectManager from '../../../singletons/RedirectManager.js';
import log from '../../../singletons/Log.js';

let redirectToUrl = function (url, withoutAbilityToGoBack, redirectStraightToUrl, inNewWindow) {
    let fullUrl = redirectStraightToUrl ? url : prepareUrl.prepareAbsoluteUrl(url);
    log.addRedirectingAwayOfApp(fullUrl);
    // No need to dispatch any action, just navigate out of this application:
    redirectManager.registerNeededRedirect(fullUrl, withoutAbilityToGoBack, inNewWindow);
};

export default redirectToUrl;
