import keymirror from 'keymirror';

const ActionTypes = keymirror({
    ACTIVATE_CARD_ORDER_BTN_CLICKED: null,
    ACTIVATE_CARD_ORDER_ERROR: null,
    ACTIVATE_CARD_ORDER_MODAL_OPENED: null,
    ACTIVATE_CARD_ORDER_SUCCESS: null,
    AUTH_INFO_GET_ERROR: null,
    AUTH_INFO_GET_SUCCESS: null,
    BACK_TO_ALL_ORDERS_LIST_BTN_CLICKED: null,
    BACK_TO_PENDING_CARD_ORDERS_BTN_CLICKED: null,
    BACK_TO_WAITING_ORDERS_BTN_CLICKED: null,
    CARDS_CHECK_FOR_PENDING_ORDERS_ERROR: null,
    CARDS_CHECK_FOR_PENDING_ORDERS_SUCCESS: null,
    CARDS_CHECK_IF_USER_HAS_ACTIVATED_CARDS_IN_ORG_ERROR: null,
    CARDS_CHECK_IF_USER_HAS_ACTIVATED_CARDS_IN_ORG_SUCCESS: null,
    CARDS_CHECK_IF_USER_HAS_PENDING_ORDERS_OR_READY_TO_ACTIVATE_CARDS_ERROR: null,
    CARDS_CHECK_IF_USER_HAS_PENDING_ORDERS_OR_READY_TO_ACTIVATE_CARDS_SUCCESS: null,
    CARDS_GET_CARD_ORDER_DETAILS_ERROR: null,
    CARDS_GET_CARD_ORDER_DETAILS_SUCCESS: null,
    CARDS_GET_PENDNG_CARD_ORDERS_ERROR: null,
    CARDS_GET_PENDNG_CARD_ORDERS_SUCCESS: null,
    CARD_ORDER_LIST_ROW_CLICKED: null,
    CLASSIFIERS_GET_ALL_ERROR: null,
    CLASSIFIERS_GET_ALL_SUCCESS: null,
    CLOSE_REDIRECT_NOTICE_BTN_CLICKED: null,
    CONFIG_GET_ERROR: null,
    CONFIG_GET_SUCCESS: null,
    CONFIRMATION_MODAL_OK_BTN_CLICKED: null,
    CONFIRM_CARD_ORDER_ERROR: null,
    CONFIRM_CARD_ORDER_SUCCESS: null,
    CONFIRM_ORDER_SIGN_IN_BTN_CLICKED: null,
    DISPLAY_LOADING_INDICATOR: null,
    FORM_BACK_BTN_CLICKED: null,
    FORM_FIELD_REMOVED: null,
    FORM_FIELD_VALUE_CHANGED: null,
    FORM_REPEATED_FIELD_ADDED: null,
    FORM_REPEATED_FIELD_REMOVED: null,
    FORM_SUBMIT_BTN_CLICKED: null,
    GET_CARD_HISTORY_ERROR: null,
    GET_CARD_HISTORY_SUCCESS: null,
    GET_FILTERED_CARDS_LIST_ERROR: null,
    GET_FILTERED_CARDS_LIST_SUCCESS: null,
    GET_GLOBAL_SEARCH_USER_CARDS_LIST_ERROR: null,
    GET_GLOBAL_SEARCH_USER_CARDS_LIST_SUCCESS: null,
    GLOBAL_SEARCH_USER_CARDS_LIST_CARD_BTN_CLICKED: null,
    HIDE_CARD_HISTORY_BTN_CLICKED: null,
    HIDE_PIN: null,
    LANGUAGE_CHANGE_ERROR: null,
    LANGUAGE_CHANGE_SUCCESS: null,
    LANGUAGE_SELECT_BTN_CLICKED: null,
    ORGANIZATION_CARDS_CLEAR_FILTER_BTN_CLICKED: null,
    ORGANIZATION_CARD_ORDER_LIST_ROW_CLICKED: null,
    PERFORM_PLUGIN_ACTION_ERROR: null,
    PERFORM_PLUGIN_ACTION_SUCCESS: null,
    PIN_LOADING: null,
    POST_TO_HUBSPOT_ERROR: null,
    POST_TO_HUBSPOT_SUCCESS: null,
    PROCEED_TO_COMPANY_BTN_CLICKED: null,
    REACTIVATE_ALL_COMPANY_CARDS_BTN_CLICKED: null,
    REACTIVATE_ALL_COMPANY_CARDS_ERROR: null,
    REACTIVATE_ALL_COMPANY_CARDS_SUCCESS: null,
    REACTIVATE_CARD_ORDER_BTN_CLICKED: null,
    REACTIVATE_CARD_ORDER_ERROR: null,
    REACTIVATE_CARD_ORDER_SUCCESS: null,
    REDIRECT_TO_SERVICE_BTN_CLICKED: null,
    REGISTRATION_COMPLETE_REGISTRATION_SUCCESS_MSG_TIME_PASSED: null,
    REJECT_CARD_ORDER_BTN_CLICKED: null,
    REJECT_CARD_ORDER_ERROR: null,
    REJECT_CARD_ORDER_SUCCESS: null,
    REPRESENTED_ORG_BTN_CLICKED: null,
    ROUTE_ENTERED: null,
    SAVE_VIRTUAL_CARD_STATUS_ERROR: null,
    SAVE_VIRTUAL_CARD_STATUS_SUCCESS: null,
    SHOW_CARD_HISTORY_BTN_CLICKED: null,
    SHOW_PIN_ERROR: null,
    SHOW_PIN_MODAL_OPENED: null,
    SHOW_PIN_SUCCESS: null,
    SIGN_IN_BTN_CLICKED: null,
    SIGN_OUT_BTN_CLICKED: null,
    SUSPEND_ALL_COMPANY_CARDS_BTN_CLICKED: null,
    SUSPEND_ALL_COMPANY_CARDS_ERROR: null,
    SUSPEND_ALL_COMPANY_CARDS_SUCCESS: null,
    SUSPEND_CARD_ORDER_BTN_CLICKED: null,
    SUSPEND_CARD_ORDER_ERROR: null,
    SUSPEND_CARD_ORDER_SUCCESS: null,
    TERMINATE_CARD_ORDER_BTN_CLICKED: null,
    TERMINATE_CARD_ORDER_ERROR: null,
    TERMINATE_CARD_ORDER_SUCCESS: null,
    TOP_MENU_OR_DASHBOARD_ITEM_CLICKED: null
});

export default ActionTypes;
