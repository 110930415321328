import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getClassifiers = function(languageCode) {

    getFromBackend('/general/classifiers/' + languageCode,
        ActionTypes.CLASSIFIERS_GET_ALL_SUCCESS,
        ActionTypes.CLASSIFIERS_GET_ALL_ERROR,
        { languageCode: languageCode });
};

export default getClassifiers;
