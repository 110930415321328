import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage} from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';

class VisitManualIDVerifier extends StoreComponent {

    renderWhenReady() {

        let userNeedsToSignIn = this.state.pendingCardOrdersStore.signInWithBankId || this.state.pendingCardOrdersStore.signInWithDokobit;

        let strongAuthForAnonymousLink = null;

        if (this.state.pendingCardOrdersStore.signInWithBankId) {
            strongAuthForAnonymousLink = <a href={this.state.pendingCardOrdersStore.signInWithBankLink}><FormattedMessage id="profile.confirmCardOder.bankIdHere" defaultMessage="BankID here" /></a>
        }

        if (this.state.pendingCardOrdersStore.signInWithDokobit) {
            strongAuthForAnonymousLink = <a href={this.state.pendingCardOrdersStore.signInWithDokobitLink}><FormattedMessage id="profile.confirmCardOder.dokobitHere" defaultMessage="BankID here" /></a>
        }

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="profile.confirmCardOder.visitManualId"
                    sharedStore={this.state.shared}
                    hideSeparator={false}
                />
                { userNeedsToSignIn
                ?
                    <Message type="warning" className="mt-6" id="sign_in_or_visit_manual_id_verification_partner_first">
                        <FormattedMessage
                            id="profile.confirmCardOder.signInWithBankIdOrGoToManualIdVerificationPartnerFirstMsg"
                            defaultMessage="Please sign in with {strongAuthLink} or go to Manual ID verification partner first."
                            values={{strongAuthLink: strongAuthForAnonymousLink}}
                        />
                    </Message>
                : null}
                { this.state.pendingCardOrdersStore.showThereAreNoPendingOrReadyToActivateCardsMsg
                ?
                    <Message type="warning" className="mt-6" id="no_pending_or_ready_to_activate_cards">
                        <FormattedMessage
                            id="profile.confirmCardOder.thereAreNoPendingOrReadyToActivateCardsMsg"
                            defaultMessage="It seems there are no pending card orders or cards to activate for you."
                        />
                    </Message>
                : null}
            </div>
        );
    }

}

export default VisitManualIDVerifier;
