import { ReduceStore } from 'flux/utils';

import redirectManager from '../../singletons/RedirectManager.js';

// A store, which counts up, so that react views could update when needed (and not too often)

class CounterStore extends ReduceStore {

    constructor(dispatcher, sharedStore) {
        super(dispatcher);
        this.sharedStore = sharedStore;
    }

    getInitialState() {
        return 0;
    }

    reduce(state) {

        // This is the last store to do reducing, so taking care of redirecting here, after all actions have been handled:
        let sharedStoreState = this.sharedStore.getState();
        redirectManager.redirectIfPossible(sharedStoreState);

        // Increment counter:
        return state + 1;
    }
}

export default CounterStore;
