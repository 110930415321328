import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let activateCardOrderBtnClicked = function(event) {
    event.preventDefault()
    dispatcher.dispatch({
        type: ActionTypes.ACTIVATE_CARD_ORDER_BTN_CLICKED
    });
};

export default activateCardOrderBtnClicked;
