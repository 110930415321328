import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage } from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';
import Logo from '../../components/common/Logo.jsx';
import constants from '../../Constants.js';

class CardsWaitingActionsPage extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    getCardTypesToDisplay(cardType) {
        return constants.cardTypesToDisplay[cardType];
    }

    renderWhenReady() {

        let customerSupportInformation = this.state.pendingCardOrdersStore.customerSupportInformation;
        let currentLanguage = this.state.pendingCardOrdersStore.currentLanguage;

        let customerSupportInformationText = '';

        if (customerSupportInformation && customerSupportInformation.email) {

            customerSupportInformationText = customerSupportInformation.email[currentLanguage];
            if (customerSupportInformation.phone) {
                customerSupportInformationText += ', ' + customerSupportInformation.phone[currentLanguage];
            }
            if (customerSupportInformation.timePart2 && customerSupportInformation.timePart2[currentLanguage]) {
                customerSupportInformationText +=  ', ' + customerSupportInformation.time[currentLanguage] + ', ' + customerSupportInformation.timePart2[currentLanguage] + '.';
            } else {
                customerSupportInformationText +=  ', (' + customerSupportInformation.time[currentLanguage] + ')';
            }
        }

        return (
            <div>

                <PageHeader
                    sharedStore={this.state.shared}
                />

                {this.state.pendingCardOrdersStore.showLandingCardReviewPage
                ?
                    <div className="mt-6 p-6 bg-light">
                        <div className="p-5 bg-white">
                            <div className="logo-for-message mb-5 mt-3">
                                <Logo configuration={this.state.pendingCardOrdersStore.configuration} />
                            </div>

                            <div>
                                {this.state.pendingCardOrdersStore.showSuccessfullyConfirmedOrderMsg || this.state.pendingCardOrdersStore.showSuccessfullyRejectedOrderMsg || this.state.pendingCardOrdersStore.showSuccessfullyActivatedOrderMsg || this.state.pendingCardOrdersStore.showSuccessfullySuspendedOrderMsg || this.state.pendingCardOrdersStore.showSuccessfullyTerminatedOrderMsg || this.state.pendingCardOrdersStore.showSuccessfullyReactivatedOrderMsg
                                ?
                                    <div>
                                        {this.state.pendingCardOrdersStore.showSuccessfullyConfirmedOrderMsg
                                        ?
                                            <Message id='pending_card_order_successfully_confirmed' type="success" defaultIcon={false} >
                                                <FormattedMessage id="pendingCardOrder.successfullyConfirmedMsg" defaultMessage="Thank you! Your card data was confirmed and we are now ready to print card" />.
                                            </Message>
                                        : null
                                        }
                                        {this.state.pendingCardOrdersStore.showSuccessfullyActivatedOrderMsg
                                        ?
                                            <Message id='pending_card_order_successfully_activated' type="success" defaultIcon={false} >
                                                <FormattedMessage id="pendingCardOrder.successfullyActivatedMsg" defaultMessage="Thank you! Your card has been activated" />.
                                            </Message>
                                        : null
                                        }
                                        {this.state.pendingCardOrdersStore.showSuccessfullyRejectedOrderMsg
                                        ?
                                            <Message id='pending_card_order_successfully_rejected' type="success" defaultIcon={false} >
                                                <FormattedMessage id="pendingCardOrder.successfullyRejectedMsg" defaultMessage="Thank you! Your card data was rejected and ordering organisation is informed" />.
                                            </Message>
                                        : null
                                        }
                                        {this.state.pendingCardOrdersStore.showSuccessfullyReactivatedOrderMsg
                                        ?
                                            <Message id='pending_card_order_successfully_reactivated' type="success" defaultIcon={false} >
                                                <FormattedMessage id="cardOrder.successfullyReactivatedMsg" defaultMessage="Thank you! Your card was reactivated" />.
                                            </Message>
                                        : null
                                        }
                                        {this.state.pendingCardOrdersStore.showSuccessfullyTerminatedOrderMsg
                                        ?
                                            <Message id='pending_card_order_successfully_terminated' type="success" defaultIcon={false} >
                                                <FormattedMessage id="cardOrder.successfullyTerminatedMsg" defaultMessage="Thank you! Your card was terminated" />.
                                            </Message>
                                        : null
                                        }
                                        {this.state.pendingCardOrdersStore.showSuccessfullySuspendedOrderMsg
                                        ?
                                            <Message id='pending_card_order_successfully_suspended' type="success" defaultIcon={false} >
                                                <FormattedMessage id="cardOrder.successfullySuspendedMsg" defaultMessage="Thank you! Your card was suspended" />.
                                            </Message>
                                        : null
                                        }
                                    </div>
                                : null
                                }
                                <h2>
                                    <FormattedMessage id="pendingCardOrder.msgAfterAction.hello" defaultMessage="Hello" />&nbsp;{this.state.pendingCardOrdersStore.holderName},
                                </h2>
                                {this.state.pendingCardOrdersStore.pendingCardOrdersList.length || this.state.pendingCardOrdersStore.readyToActivateCardOrdersList.length
                                ?
                                    <p>
                                        <FormattedMessage id="pendingCardOrder.msgAfterAction.weNeedInput" defaultMessage="it seems we need input from you" />.
                                    </p>
                                : null
                                }

                                {this.state.pendingCardOrdersStore.noOrdersToReviewMsg && !this.state.pendingCardOrdersStore.actionMsgPageAllOrdersReviewed
                                ?
                                    <p>
                                        <FormattedMessage
                                            id="pendingCardOrder.msgAfterAction.noMoreOrder"
                                            defaultMessage="it seems there are no pending card orders or cards to activate for you"
                                        />.
                                    </p>
                                : null
                                }

                                {this.state.pendingCardOrdersStore.actionMsgPageAllOrdersReviewed && !this.state.pendingCardOrdersStore.noOrdersToReviewMsg
                                ?
                                    <div className="mb-6">

                                        <p>
                                            <FormattedMessage
                                                id="pendingCardOrder.msgAfterAction.allIsSet"
                                                defaultMessage="all is set. You can"
                                            />
                                            &nbsp;
                                            <a href={this.state.pendingCardOrdersStore.signOutLink} id="pending_card_orders_sign_out_link">
                                                <FormattedMessage
                                                    id="pendingCardOrder.msgAfterAction.signOut"
                                                    defaultMessage="sign out here"
                                                />
                                            </a>.
                                        </p>
                                        <p>
                                            <FormattedMessage
                                                id="pendingCardOrder.msgAfterAction.ifYouWantTo"
                                                defaultMessage="If you want to, you can also go to"
                                            />
                                            &nbsp;
                                            <a href={this.state.pendingCardOrdersStore.cardOrdersLink} id="all_user_cards_link">
                                                <FormattedMessage
                                                    id="pendingCardOrder.msgAfterAction.goToCardOrder"
                                                    defaultMessage="see all your current cards and card orders"
                                                />
                                            </a>.
                                        </p>
                                        <p>
                                            <FormattedMessage
                                                id="pendingCardOrder.msgAfterAction.ifHaveQuestions"
                                                defaultMessage="If questions, contact your employer or the person responsible for your ID06 card orders"
                                            />.
                                        </p>
                                    </div>
                                : null}


                                {this.state.pendingCardOrdersStore.pendingCardOrdersList.length
                                ?
                                    <div className="mb-6">
                                        <p>
                                            <FormattedMessage id="pendingCardOrder.msgAfterAction.orderToConfirm" defaultMessage="You have a card order(s) to confirm" />:
                                        </p>
                                        <table className="table table-hover table-striped" id="pending_card_orders">
                                            <tbody>
                                                {this.state.pendingCardOrdersStore.pendingCardOrdersList.map((card, index) => {
                                                    let id =  `pending_cards_${index}`;
                                                    let buttonId = `pending_cards_btn_${index}`;
                                                    let reviewOrderUrl = this.state.pendingCardOrdersStore.reviewPendingOrderUrl + `/${card.orderId}`;

                                                    return (
                                                        <tr id={id} key={index}>
                                                            <td>
                                                                <p>
                                                                    <FormattedMessage
                                                                        id="pendingCardOrder.msgAfterAction.cardFor"
                                                                        values={{ cardType: this.getCardTypesToDisplay(card.cardType) }}
                                                                        defaultMessage="ID06 card for"
                                                                    />
                                                                    &nbsp;
                                                                    <b>{card.orgName}</b>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    id={buttonId}
                                                                    className="btn btn-primary mr-4 btn-lg pull-right"
                                                                    role="button"
                                                                    href={reviewOrderUrl}
                                                                >
                                                                    <FormattedMessage
                                                                        id="pendingCardOrder.msgAfterAction.reviewMyCard"
                                                                        values={{ cardType: this.getCardTypesToDisplay(card.cardType) }}
                                                                        defaultMessage="Review my ID06 card order" 
                                                                    />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                : null}

                                {this.state.pendingCardOrdersStore.readyToActivateCardOrdersList.length
                                ?
                                    <div className="mb-6">
                                        <p>
                                            <FormattedMessage id="pendingCardOrder.msgAfterAction.orderToActivate" defaultMessage="You have card(s) to activate" />:
                                        </p>
                                        <table className="table table-hover table-striped" id="orders_ready_to_be_activated">
                                            <tbody>
                                                {this.state.pendingCardOrdersStore.readyToActivateCardOrdersList.map((card, index) => {
                                                    let id =  `card_ready_to_be_activated_${index}`;
                                                    let buttonId = `card_ready_to_be_activated_${index}`;
                                                    let activateCardUrl = this.state.pendingCardOrdersStore.activateWaitingCardsUrl + `/${card.orderId}`;

                                                    return (
                                                        <tr id={id} key={index}>
                                                            <td>
                                                                <p>
                                                                    <FormattedMessage
                                                                        id="pendingCardOrder.msgAfterAction.cardFor"
                                                                        values={{ cardType: this.getCardTypesToDisplay(card.cardType) }}
                                                                        defaultMessage="ID06 card for"
                                                                    />
                                                                    &nbsp;
                                                                    <b>{card.orgName}</b>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    id={buttonId}
                                                                    className="btn btn-primary mr-4 btn-lg pull-right"
                                                                    role="button"
                                                                    href={activateCardUrl}
                                                                >
                                                                    <FormattedMessage
                                                                        id="pendingCardOrder.msgAfterAction.activateBtn"
                                                                        values={{ cardType: this.getCardTypesToDisplay(card.cardType) }}
                                                                        defaultMessage="Activate my ID06 card"
                                                                    />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                : null
                                }

                                {!this.state.pendingCardOrdersStore.actionMsgPageAllOrdersReviewed
                                ?
                                    <div>
                                        <p>
                                            <FormattedMessage
                                                id="pendingCardOrder.msgAfterAction.ifYouWantTo"
                                                defaultMessage="If you want to, you can also go to"
                                            />
                                            &nbsp;
                                            <a href={this.state.pendingCardOrdersStore.cardOrdersLink} id="all_user_cards_link">
                                                <FormattedMessage
                                                    id="pendingCardOrder.msgAfterAction.goToCardOrder"
                                                    defaultMessage="see all your current cards and card orders"
                                                />
                                            </a>.
                                        </p>
                                        <p>
                                            <FormattedMessage id="pendingCardOrder.msgAfterAction.whenReady" defaultMessage="When ready, you can" />
                                            &nbsp;
                                            <a href={this.state.pendingCardOrdersStore.signOutLink} id="pending_card_orders_sign_out_link">
                                                <FormattedMessage
                                                    id="pendingCardOrder.msgAfterAction.signOut"
                                                    defaultMessage="sign out here"
                                                />
                                            </a>.
                                        </p>
                                    </div>
                                : null
                                }

                                <hr />

                                <div>
                                    <p>
                                        <FormattedMessage
                                            id="pendingCardOrder.msgAfterAction.customerService"
                                            defaultMessage="Our customer service"
                                        />:&nbsp;
                                        {customerSupportInformationText}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                : null
                }

            </div>
        );
    }


}
export default CardsWaitingActionsPage;
