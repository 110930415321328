import ActionTypes from '../ActionTypes.js';
import {putToBackend} from './generic/CallBackend.js';

let saveUsedLanguage = function(language, reloadAppAfter) {
    putToBackend('/general/gui_language',
        ActionTypes.LANGUAGE_CHANGE_SUCCESS,
        ActionTypes.LANGUAGE_CHANGE_ERROR,
        {
            language: language
        },
        { reloadAppAfter: reloadAppAfter });
};

export default saveUsedLanguage;
