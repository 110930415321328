import dispatcher from '../../../Dispatcher.js';

let wait = function(miliseconds, successActionType) {
    setTimeout(function() {
        dispatcher.dispatch({
            type: successActionType
        });
    }, miliseconds);
};

export default wait;
