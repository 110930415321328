import routeEntered from '../actions/actionCreators/RouteEntered.js';


let collectQuery = function(nextState) {

    // Initially, collect what is in react router query (after hashtag):

    let query = nextState &&  nextState.location && nextState.location.query
        ? Object.assign({}, nextState.location.query)
        : {};

    return query;
};

let routeOnEnterHook = function(nextState) {

    let routePaths = nextState.routes.map((r) => r.path);

    let numberOfRoutesToClear = nextState.params.organizationCode ? 2 : 1;

    if (routePaths.length > numberOfRoutesToClear) {
        const indexToRemove = 0;
        const numberToRemove = numberOfRoutesToClear;

        routePaths.splice(indexToRemove, numberToRemove);
    }

    let fullPath = '/' + routePaths.join('/');
    let paramsCopied = Object.assign({}, nextState.params);

    let pathNameParts = nextState &&  nextState.location && nextState.location.pathname
        ? nextState.location.pathname.split('/')
        : [];

    if (pathNameParts.length > numberOfRoutesToClear) {
        const indexToRemove = 0;
        const numberToRemove = numberOfRoutesToClear;

        pathNameParts.splice(indexToRemove, numberToRemove);
    }

    let pathFilledWithParams = '/' + pathNameParts.join('/');

    routeEntered({
        fullPath: fullPath,
        paths: routePaths,
        params: paramsCopied,
        pathFilledWithParams: pathFilledWithParams,
        queryParams: collectQuery(nextState),
        fullUrl: pathFilledWithParams + nextState.location.search,
        fullPathWithOrgCode: nextState.location.pathname
    });
};

export default routeOnEnterHook;
