import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let representedOrganizationBtnClicked = function(index) {
    dispatcher.dispatch({
        type: ActionTypes.REPRESENTED_ORG_BTN_CLICKED,
        index: index
    });
};

export default representedOrganizationBtnClicked;