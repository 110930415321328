import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let clearFilterBtnClickedHandler = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.ORGANIZATION_CARDS_CLEAR_FILTER_BTN_CLICKED
    });
};

export default clearFilterBtnClickedHandler;