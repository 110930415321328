import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let bactToPendingOrdersBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.BACK_TO_PENDING_CARD_ORDERS_BTN_CLICKED
    });
};

export default bactToPendingOrdersBtnClicked;
