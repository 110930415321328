import ActionTypes from '../ActionTypes.js';
import {getFromBackend} from './generic/CallBackend.js';

let getAuthInfo = function(orgId) {

    getFromBackend('/general/' + orgId + '/auth-info',
        ActionTypes.AUTH_INFO_GET_SUCCESS,
        ActionTypes.AUTH_INFO_GET_ERROR,
        null,
        true);
};

export default getAuthInfo;
