import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let getControlValue = function(control) {
    if (control.type === "checkbox") {
        return control.checked;
    } else {
        return control.value;
    }
};


let getHandlerForFormFieldValueChanged = function(storeKey, formKey, fieldKey, isSensitiveInformation, valueGetter) {
    return function(event) {

        let actionToDispatch = {
            type: ActionTypes.FORM_FIELD_VALUE_CHANGED,
            storeKey: storeKey,
            formKey: formKey,
            fieldKey: fieldKey,
            isSensitiveInformation: isSensitiveInformation
        };

        if (valueGetter) {
            actionToDispatch.newValue = valueGetter(event);
        } else {
            actionToDispatch.newValue = getControlValue(event.target);
            actionToDispatch.additionalInfo = event.target.additionalInfo;
        }

        dispatcher.dispatch(actionToDispatch);
    };
};

export default getHandlerForFormFieldValueChanged;
