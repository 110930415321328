import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';

import { TopMenuComponent, FooterComponent, Message, SetPageTitle, Loading, SubMenu } from '@vaultit/stv-utils-frontend';

import languageSelectBtnClicked from './../actions/actionCreators/menu/LanguageSelectBtnClicked.js';
import representedOrganizationBtnClicked from '../actions/actionCreators/menu/RepresentedOrganizationBtnClicked.js';
import signInBtnClicked from './../actions/actionCreators/menu/SignInBtnClicked.js';
import signOutBtnClicked from './../actions/actionCreators/menu/SignOutBtnClicked.js';
import topMenuOrDashboardItemClicked from './../actions/actionCreators/menu/TopMenuOrDashboardItemClicked.js';

import StoreComponent from './base/StoreComponent.jsx';

import ActionTypes from './../actions/ActionTypes';
import wait from './../actions/actionCreators/generic/Wait.js';


class MasterPage extends StoreComponent {

    essentialDataStillLoading() {

        let classifiersLoading = this.state.shared.classifiers === null;
        let loadingAuthInfoInitially = !this.state.shared.authInfo;

        return loadingAuthInfoInitially || classifiersLoading || this.state.shared.showOnlyLoadingIndicator;
    }

    appendChatComponent() {
        let chatComponentTurnedInConfiguration = this.state.shared.authInfo
         && this.state.shared.authInfo.topMenuParameters
         && this.state.shared.authInfo.topMenuParameters.configuration.loadChatComponent;

        if (chatComponentTurnedInConfiguration && process.env.NODE_ENV !== 'development') {
            let id  = this.state.shared.constants.chatScriptId;
            if (document.getElementById(id) === null) {
                const chatScriptElement = document.createElement("script");
                const chatScript = document.createTextNode("(function(w,t,f){var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live/';w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n)})(window,document,6704);");
                chatScriptElement.appendChild(chatScript);
                chatScriptElement.type = 'text/javascript';
                chatScriptElement.id = id;

                // Set chat language (uiLang is always either 'sv' or 'en'. Use 'en' as fallback.)
                let uiLang = this.state.shared.localizations.messagesLanguage;
                if (chatScriptElement.dataset) {
                    chatScriptElement.dataset.lang = uiLang || 'en';
                }

                document.body.appendChild(chatScriptElement);
            }
        }
    }

    appendHubSpotScript() {
        let hubSpotTurnedInConfiguration = this.state.shared.authInfo
         && this.state.shared.authInfo.topMenuParameters
         && this.state.shared.authInfo.topMenuParameters.configuration.enableHubSpot;

        if (hubSpotTurnedInConfiguration && process.env.NODE_ENV !== 'development') {

            let id  = this.state.shared.constants.hubSpotScriptId;

            if (document.getElementById(id) === null) {
                const hubSpotScript = document.createElement("script");
                hubSpotScript.src = this.state.shared.constants.hubSpotScriptUrl;
                hubSpotScript.id = id;
                hubSpotScript.async = true;
                hubSpotScript.defer = true;
                hubSpotScript.type = 'text/javascript';
                document.body.appendChild(hubSpotScript);
            }

        }
    }

    appendCookiebot() {
        if (process.env.NODE_ENV !== 'development') {
            let id = 'Cookiebot';

            if (document.getElementById(id) === null) {
                const scriptElements = document.head.getElementsByTagName('script');

                const cookiebotScript = document.createElement('script');
                cookiebotScript.id = id;
                cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
                cookiebotScript.type = 'text/javascript';
                cookiebotScript.setAttribute('data-cbid', 'f799c4e5-c7e7-4b1d-b595-e2155147f4fe');
                cookiebotScript.setAttribute('data-blockingmode', 'auto');
                if (scriptElements.length > 0) {
                    document.head.insertBefore(cookiebotScript, scriptElements[0]);
                } else {
                    document.head.appendChild(cookiebotScript);
                }
            }
        }
    }

    render() {
        this.appendCookiebot();
        this.appendChatComponent();
        this.appendHubSpotScript();

        let loadingAuthInfoRepeatedly = this.state.shared.authInfo && this.state.shared.loadingAuthInfo;
        let showLoadingIndicatorWithContents = loadingAuthInfoRepeatedly
            || this.state.shared.savingUsedLanguage
            || Object.keys(this.state).find((stateKey) =>
                this.state[stateKey].showLoadingIndicator && stateKey !== 'shared' && stateKey !== 'counter');

        let showOnlyLoadingIndicator = this.essentialDataStillLoading();

        let pageIsLoading = (showLoadingIndicatorWithContents && showLoadingIndicatorWithContents !== null && showLoadingIndicatorWithContents !== false) || showOnlyLoadingIndicator;

        if (pageIsLoading) {
            if (this.state.shared.loadingIndicatorIsVisible === false) {
                wait(this.state.shared.constants.milisecondsToWaitForLoadingIndicatorDisplay, ActionTypes.DISPLAY_LOADING_INDICATOR);
            }
        } else {
            this.state.shared.loadingIndicatorIsVisible =  false;
        }

        let redirectNotice = null;
        let sessionExpiredMsg = null;

        let thirdLevelMenuEnabled = this.state.shared.authInfo
            && this.state.shared.authInfo.topMenuParameters
            && this.state.shared.authInfo.topMenuParameters.configuration.thirdLevelMenu;

        let thirdLevelMenuVisible = thirdLevelMenuEnabled
            && this.state.shared.authInfo.otherUiInfo.isSignedIn
            && !this.state.shared.showMinimalRegistrationFlow;


        if (this.state.shared.showSessionExpiredInfoMsg) {
            sessionExpiredMsg = (
                <Message id="warning_session_expired" type="warning" className="my-6">
                    <FormattedMessage
                        id="masterPage.sessionExpired"
                        defaultMessage="Your current session expired. Please review your data and resubmit it."
                    />
                </Message>
            );
        } else if (this.state.shared.showSessionExpiredInfoMsgForLoggedInUser) {
            sessionExpiredMsg = (
                <Message id="warning_session_expired" type="warning" className="my-6">
                    <FormattedMessage
                        id="masterPage.sessionExpiredForLoggedInUser"
                        defaultMessage="Your current session was refreshed. Please fill form and repeat action again."
                    />
                </Message>
            );
        }

        let inactiveAccountMessage = null;

        if (this.state.menuItems.showUserInactiveAccountMessage) {
            inactiveAccountMessage = (
                <Message id="warningInactiveAccount" type="warning" className="mb-6">
                    <FormattedMessage
                        id="general.inactiveAccountInfo"
                        defaultMessage="It seems your ID06 account is deactivated. If you need to activate it, please contact our customer service - contact info can be found at the bottom of the page."
                    />
                </Message>
            );
        }

        let loadingIndicator = <Loading loading={pageIsLoading} showLoadingIndicator={this.state.shared.loadingIndicatorIsVisible} />;

        let baseContent = !thirdLevelMenuEnabled
            ?
                (<div className="main container">
                    {showLoadingIndicatorWithContents
                        ? loadingIndicator
                        : null
                    }
                    { inactiveAccountMessage }
                    { sessionExpiredMsg }
                    { redirectNotice }
                    { this.props.children }
                </div>)
            :
                (<div className="container-fluid">
                    {showLoadingIndicatorWithContents
                    ? loadingIndicator
                    : null
                }
                    <div className="row">
                        <div className={thirdLevelMenuVisible ? "col-xl-6 col-md-8" : "col-xl-6 col-md-8 offset-xl-3 offset-md-2"}>
                            <div className="main">
                                { inactiveAccountMessage }
                                { sessionExpiredMsg }
                                { redirectNotice }
                                { this.props.children }
                            </div>
                        </div>
                    </div>
                </div>);

        let canShowMainComponents = !showOnlyLoadingIndicator;


        let subMenuVisible = canShowMainComponents
            && this.state.shared.authInfo.topMenuParameters.menuItemsVisibility.secondLevelNavIsVIsible;

        let subMenu = canShowMainComponents
            ?
                (<SubMenu
                    visible={subMenuVisible}
                    organizationCode={this.state.shared.representedOrganizationCode}
                    activeRoute={this.state.shared.currentRoute.pathFilledWithParams}
                    configuration={this.state.shared.authInfo.topMenuParameters.configuration}
                 />)
            : null;

        let topMenu = canShowMainComponents
            ? (<TopMenuComponent
                /* Props for logging in, logging out & logged in user info */
                    onSignInClicked={signInBtnClicked}
                    onSignOutClicked={signOutBtnClicked}
                /* Props for represented org */
                    onSelectedRepresentationChanged={representedOrganizationBtnClicked}
                /* Props for language */
                    selectedLanguageCode={this.state.shared.authInfo.topMenuParameters.selectedLanguageCode}
                    onLanguageChanged={languageSelectBtnClicked}
                /* Top menu item clicks: */
                    onTopMenuItemClicked={topMenuOrDashboardItemClicked}
                /* Configuration for the top menu rendering */
                    topMenuParameters={this.state.shared.authInfo.topMenuParameters}
               >
                {subMenu}
            </TopMenuComponent>)
            : null;

        let footer = canShowMainComponents
            ? (<FooterComponent
                    selectedLanguageCode={this.state.shared.localizations.messagesLanguage}
                    pushToBottom={true}
                    configuration={this.state.shared.authInfo.topMenuParameters.configuration}
               />)
            : null;

        let mainPageTitle = canShowMainComponents ?  <SetPageTitle uiConfiguration={this.state.shared.authInfo.topMenuParameters.configuration} currentLanguage={this.state.shared.localizations.messagesLanguage} /> : null;

        return (
            <IntlProvider {...this.state.shared.localizations} >
                <div>
                    {mainPageTitle}

                    {topMenu}

                    {showOnlyLoadingIndicator
                        ? loadingIndicator
                        : baseContent
                    }

                    {footer}
                </div>
            </IntlProvider>
        );
    }
}

export default MasterPage;
