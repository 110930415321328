import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import { TextInput, Message, ValidationMessages, ConfirmationModal } from '@vaultit/stv-utils-frontend';
import $ from 'jquery';

class CardNumberInputModal extends React.Component {

    static get propTypes() {
        return {
            form: PropTypes.object,
            dismissModalAfterClick: PropTypes.bool,
            action: PropTypes.oneOf(['showPin', 'activate']),
            showPin: PropTypes.bool,
            pin: PropTypes.string,
            showPinErrorIncorrectNumber: PropTypes.bool,
            hideModal: PropTypes.bool,
            sharedStore: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;

        let cardNumberInputForm = this.props.form;
        let modalMessages = defineMessages({
            cardNumberInputModalTitle: {
                id: 'cardNumberInputModal.' + this.props.action + '.title'
            },
            cardNumberInputModalConfirmBtn: {
                id: 'cardNumberInputModal.' + this.props.action + '.confirm'
            },
            cardNumberInputModalCancelBtn: {
                id: 'cardNumberInputModal.' + this.props.action + '.cancel'
            },
            cardNumberInputModalExplanationMessage: {
                id: 'cardNumberInputModal.explanation',
                description: 'Card activation window explanation message',
                defaultMessage: 'Please input your card number'
            },
            cardActivationModalInputPlaceholder: {
                id: 'cardActivationModal.input',
                description: 'Card activation modal input placeholder',
                defaultMessage: 'XX-XXXX-XXXXXX'
            },
            cardNumberInputModalPlaceholder: {
                id: 'cardNumberInputModal.input',
                description: 'Card activation modal input placeholder',
                defaultMessage: 'XX-XXXX-XXXXXX'
            },
            cardNumberIsIncorrect: {
                id: 'pendingCardOrder.invalidCardNumberEntered',
                description: 'Entered pin number is incorrect',
                defaultMessage: 'The entered card number is invalid'
            }
        })

        let handleEnter = (event) => {
            if (event.keyCode == 13 && this.props.dismissModalAfterClick) {
                event.preventDefault()
                $(".closeModal").click();
            }
        };

        document.addEventListener("keydown", handleEnter, false);

        return (
            <ConfirmationModal
                id="card_activation_modal"
                idPrefix="card_activation"
                title={formatMessage(modalMessages.cardNumberInputModalTitle)}
                okButtonText={formatMessage(modalMessages.cardNumberInputModalConfirmBtn)}
                cancelButtonText={formatMessage(modalMessages.cardNumberInputModalCancelBtn)}
                handleActionType={() => cardNumberInputForm.handleOnSubmitBtnClicked()}
                okBtnClass="btn btn-primary"
                dismissModalAfterClick={this.props.action == 'activate' && this.props.showPinErrorIncorrectNumber ?
                    false : this.props.dismissModalAfterClick}
                hideModal={this.props.hideModal ? this.props.hideModal : null}
                sharedStore={this.props.sharedStore}
                hideConfirmButton={this.props.hideConfirmButton}
            >
                {cardNumberInputForm
                    ?
                        <div>
                            {this.props.action == 'showPin' && this.props.showPinErrorIncorrectNumber
                                ?
                                    <Message id='pending_card_order_invalid_card_number_entered' type="danger" defaultIcon={true} >
                                        {formatMessage(modalMessages.cardNumberIsIncorrect)}.
                                    </Message>
                                : null
                            }

                            {this.props.action == 'activate' && this.props.showPinErrorIncorrectNumber
                                ?
                                    <Message id='pending_card_order_invalid_card_number_entered' type="danger" defaultIcon={true} >
                                        {formatMessage(modalMessages.cardNumberIsIncorrect)}.
                                    </Message>
                                : null
                            }

                            <p className="mt-3 mb-5 font-weight-bold">
                                {formatMessage(modalMessages.cardNumberInputModalExplanationMessage)}
                            </p>

                            <ValidationMessages field={cardNumberInputForm.fields.cardNumber}>
                                <TextInput
                                    field={cardNumberInputForm.fields.cardNumber}
                                    id="card_activation_modal_input"
                                    placeholder={formatMessage(modalMessages.cardNumberInputModalPlaceholder)}
                                />
                            </ValidationMessages>
                        </div>
                    : null
                }

                { this.props.action == 'showPin' && this.props.showPin
                    ? <div className="mt-5 text-center"><FormattedMessage id="pendingCardOrder.showPin" defaultMessage="Your card pin is: {pin}"  values={{pin: <h2>{ this.props.pin }</h2>}} /></div>
                    : null
                }

            </ConfirmationModal>
        );
    }
}

export default CardNumberInputModal
