import React from 'react';
import { FormattedMessage } from 'react-intl';
import {saveAs} from 'filesaver.js';
import log from '../singletons/Log.js';
import StoreComponent from './base/StoreComponent.jsx';


const reportFileName = 'report.json';

class ProblemReport extends StoreComponent {

    handleDownloadLinkClick(event) {
        event.preventDefault();

        let actionLog = log.getFullLog();

        let reportFileContents = {
            actionLog: actionLog
        };

        if (navigator && navigator.userAgent) {
            reportFileContents.userAgent = navigator.userAgent;
        }

        let text = JSON.stringify(reportFileContents);

        var blob = new Blob([text], {type: "text/plain;charset=utf-8"});
        saveAs(blob, reportFileName);
    }

    renderWhenReady() {
        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;

        return (
            <div>
                <h4>
                    <FormattedMessage id="problemreport.title" defaultMessage="Report a problem" />
                </h4>

                <p>
                    <FormattedMessage id="problemreport.infotxt.part1" defaultMessage="To report a problem, please send an email to our customer support" />
                    <span>&nbsp;</span>
                    <a href={'mailto:' + uiConfiguration.customerSupportEmail}>{uiConfiguration.customerSupportEmail}</a>
                    <FormattedMessage id="problemreport.infotxt.part2" defaultMessage=". Problem report file" />
                    <span>&nbsp;</span>
                    <a href="#" onClick={this.handleDownloadLinkClick}>{reportFileName}</a>
                    <span>&nbsp;</span>
                    <FormattedMessage id="problemreport.infotxt.part3" defaultMessage="can be added as an attachment to help us identify the problem." />
                </p>
            </div>
        );
    }
}

export default ProblemReport;
