import getHandlerForFormFieldValueChanged from '../../actions/actionCreators/generic/GetHandlerForFormFieldValueChanged.js';
import getHandlerForFormBackBtnClicked from '../../actions/actionCreators/generic/GetHandlerForFormBackBtnClicked.js';
import getHandlerForFormSubmitBtnClicked from '../../actions/actionCreators/generic/GetHandlerForFormSubmitBtnClicked.js';
import getHandlerForFormRepeatedFieldAdded from '../../actions/actionCreators/generic/GetHandlerForFormRepeatedFieldAdded.js';
import getHandlerForFormFieldRemoved from '../../actions/actionCreators/generic/GetHandlerForFormFieldRemoved.js';
import getHandlerForFormRepeatedFieldRemoved from '../../actions/actionCreators/generic/GetHandlerForFormRepeatedFieldRemoved.js';

class StoreFormManager {

    defineForm(state, storeKey, formKey, fieldDefinitions, submitHandler) {

        let formDefinition = {
            storeKey: storeKey,
            formKey: formKey,
            fieldDefinitions: fieldDefinitions,
            submitHandler: submitHandler
        };

        if (!state.formDefinitions) {
            state.formDefinitions = {};
        }

        state.formDefinitions[formKey] = formDefinition;
    }

    setFormInitialState(state, formKey) {

        let formDefinition = state.formDefinitions[formKey];

        let formState = {};
        state[formKey] = formState;

        formState.isValid = false;
        formState.showMainError = false;
        formState.fields = {};
        formState.handleOnBackBtnClicked = getHandlerForFormBackBtnClicked(formDefinition.storeKey, formDefinition.formKey);
        formState.handleOnSubmitBtnClicked = getHandlerForFormSubmitBtnClicked(formDefinition.storeKey, formDefinition.formKey);
        formState.submitBtnClickedSinceLastTimeFormWasValid = false;

        this.forEachFieldDefinition(formDefinition, (fieldDefinition, fieldDefinitionKey) => {
            if (fieldDefinition.isRepeatedField) {
                formState.fields[fieldDefinitionKey] = [];
            }
        });

        this.forEachField(formDefinition, (fieldDefinition, fieldKey, fieldDefinitionKey, indexOfRepeatedField) => {
            // Remove additionally added fields on setFormInitialState because
            // initial state doesn't have newly added fields so we have to remove them
            if (fieldDefinition.removeThisFieldOnSetInitialState) {
                Reflect.deleteProperty(formDefinition.fieldDefinitions, fieldDefinitionKey);
            } else {
                this.setFieldInitialState(
                    state,
                    formDefinition.storeKey,
                    formDefinition.formKey,
                    fieldDefinition,
                    fieldKey,
                    fieldDefinitionKey,
                    indexOfRepeatedField
                );
            }
        });
    }

    addAditionalFields(state, formKey, newformFieldDefinitions) {

        let formDefinition = state.formDefinitions[formKey];

        for (let newFieldKey in newformFieldDefinitions) {
            let index = 0;
            for (let existingFieldKey in formDefinition.fieldDefinitions) {
                if (existingFieldKey.indexOf(newFieldKey) >= 0) {
                    index++;
                }
            }

            let modifiedFieldKeyName = index > 0 ? newFieldKey + index.toString() : newFieldKey;

            formDefinition.fieldDefinitions[modifiedFieldKeyName] = newformFieldDefinitions[newFieldKey];
            // On set initialState will remove additional fields because they are not set in form definition in initial state.
            formDefinition.fieldDefinitions[modifiedFieldKeyName]['removeThisFieldOnSetInitialState'] = true;
        }

        this.forEachFieldDefinition(formDefinition, (fieldDefinition, fieldDefinitionKey) => {
            if (fieldDefinition.isRepeatedField && newformFieldDefinitions[fieldDefinitionKey]) {
                state[formKey].fields[fieldDefinitionKey] = [];
            }
        });

        this.forEachField(formDefinition, (fieldDefinition, fieldKey, fieldDefinitionKey, indexOfRepeatedField) => {
            if (!state[formKey].fields[fieldDefinitionKey]) {
                this.setFieldInitialState(
                    state,
                    formDefinition.storeKey,
                    formDefinition.formKey,
                    fieldDefinition,
                    fieldKey,
                    fieldDefinitionKey,
                    indexOfRepeatedField
                );
            }
        });

    }

    onFieldValueChanged(state, action, sharedStoreState) {
        let validateWhileTyping = sharedStoreState.authInfo.topMenuParameters.configuration.style.validateWhileTyping;

        // If form is not defined in FormStore initial state
        if (!state.formDefinitions) {
            return;
        }
        Object.keys(state.formDefinitions).forEach((formKey) => {
            if (action.formKey === formKey) {
                let formDefinition = state.formDefinitions[formKey];

                if (action.storeKey === formDefinition.storeKey) {
                    let formState = state[formDefinition.formKey];

                    let valueActuallyChanged = false;

                    this.forEachField(formDefinition, (fieldDefinition, fieldKey) => {
                        let field = formState.fields[fieldKey];

                        if (fieldKey === action.fieldKey) {

                            let oldValue = field.value;
                            let newValue = action.newValue;

                            valueActuallyChanged =  oldValue || newValue
                                ? newValue !== oldValue
                                : false;

                            // Assign same value to grouped inputs
                            if (field.groupName) {
                                this.forEachField(formDefinition, (groupFieldDefinition, groupFieldKey) => {
                                    let groupField = formState.fields[groupFieldKey];

                                    if (groupField.groupName && groupField.groupName == field.groupName) {
                                        groupField.value = newValue;
                                    }
                                });
                            }

                        }
                    });


                    if (valueActuallyChanged) {

                        this.forEachField(formDefinition, (fieldDefinition, fieldKey) => {
                            let field = formState.fields[fieldKey];
                            let forceToValidate = false;

                            if (fieldDefinition.validators) {
                                for (let key in fieldDefinition.validators) {
                                    if ('validateOnOtherFieldChange' in fieldDefinition.validators[key] || (typeof fieldDefinition.validators[key] == 'function' && fieldDefinition.validators[key].name == 'passwordValidator')) {
                                        forceToValidate = true;
                                        break;
                                    }
                                }
                            }

                            if (fieldKey === action.fieldKey) {
                                // Dirty:
                                field.dirty = true;

                                // Run through all input masks:
                                let maskedValue = this.runThroughInputMasks(fieldDefinition, action.newValue, formState);
                                if (maskedValue) {
                                    action.newValue = maskedValue;
                                }

                                // Run through all restrictors:
                                field.value = this.runThroughRestrictions(fieldDefinition, action.newValue);

                                field.additionalInfo = action.additionalInfo;

                                if (validateWhileTyping || forceToValidate) {
                                    // Run through all validators
                                    this.runThroughValidators(fieldDefinition, field, formState);
                                } else {
                                    this.clearValidatorErrors(field, true);
                                }
                            } else {
                                if (validateWhileTyping || forceToValidate) {
                                    // Run through only the ones, which specifically said, that need to be validated on this field change
                                    let needsToBeValidated = (v) => v.validateOnOtherFieldChange === action.fieldKey;

                                    if ((field.groupName || field.validateOnAnyFieldChange) && field.value) {
                                        needsToBeValidated = null;
                                    }

                                    this.runThroughValidators(fieldDefinition, field, formState, needsToBeValidated);
                                } else {
                                    this.clearValidatorErrors(field, true);
                                }
                            }
                        });

                        this.displayErrorsIfNeededAndMarkDownFormState(formDefinition, formState);
                    }
                }

            }
        });
    }

    onSubmitBtnClicked(state, action, sharedStoreState, ...optionalArgs) {
        // If form is not defined in FormStore initial state
        if (!state.formDefinitions) {
            return;
        }

        Object.keys(state.formDefinitions).forEach((formKey) => {
            if (action.formKey === formKey) {
                let formDefinition = state.formDefinitions[formKey];

                if (action.storeKey === formDefinition.storeKey) {
                    let formState = state[formKey];

                    formState.submitBtnClickedSinceLastTimeFormWasValid = true;

                    this.forEachField(formDefinition, (fieldDefinition, fieldKey) => {
                        let field = formState.fields[fieldKey];

                        let validatorNeedsToBeValidated = field.isVisible
                            ? null // No condition - if field is visible, run through all of the validators
                            : () => false; // If field is not visible, no validators need to be validated
                        this.runThroughValidators(fieldDefinition, field, formState, validatorNeedsToBeValidated);
                    });

                    this.displayErrorsIfNeededAndMarkDownFormState(formDefinition, formState);

                    if (formState.isValid && formDefinition.submitHandler) {
                        let values = {};

                        this.forEachField(formDefinition, (fieldDefinition, fieldKey, fieldDefinitionKey) => {

                            let field = formState.fields[fieldKey];

                            if (field.isVisible) {
                                let val = field.value;

                                if (val && typeof val === 'string') {
                                    val = val.trim();
                                }

                                if (fieldDefinition.isRepeatedField) {
                                    if (values[fieldDefinitionKey]) {
                                        values[fieldDefinitionKey].push(val);
                                    } else {
                                        values[fieldDefinitionKey] = [val];
                                    }
                                } else {
                                    values[fieldKey] = val;
                                }
                            }
                        });
                        this.clearInvisibleFieldValues(state, formKey);

                        formDefinition.submitHandler(state, values, sharedStoreState, ...optionalArgs);
                    }
                }
            }
        });
    }

    onRepeatedFieldAdded(state, action) {
        // If form is not defined in FormStore initial state
        if (!state.formDefinitions) {
            return;
        }

        Object.keys(state.formDefinitions).forEach((formKey) => {
            if (action.formKey === formKey) {
                let formDefinition = state.formDefinitions[formKey];

                if (action.storeKey === formDefinition.storeKey) {

                    this.forEachFieldDefinition(formDefinition, (fieldDefinition, fieldDefinitionKey) => {

                        if (fieldDefinitionKey === action.fieldDefinitionKey) {

                            let newfieldIndex = fieldDefinition.repeatedFieldCount;
                            let fieldKey = fieldDefinitionKey + String(newfieldIndex);

                            fieldDefinition.repeatedFieldCount++;

                            this.setFieldInitialState(state,
                                formDefinition.storeKey,
                                formDefinition.formKey,
                                fieldDefinition,
                                fieldKey,
                                fieldDefinitionKey,
                                newfieldIndex);
                        }
                    });
                }
            }
        });
    }

    onRepeatedFieldRemoved(state, action) {
        // If form is not defined in FormStore initial state
        if (!state.formDefinitions) {
            return;
        }
        Object.keys(state.formDefinitions).forEach((formKey) => {
            if (action.formKey === formKey) {
                let formDefinition = state.formDefinitions[formKey];

                if (action.storeKey === formDefinition.storeKey) {
                    let formState = state[formDefinition.formKey];

                    this.forEachField(formDefinition, (fieldDefinition, fieldKey) => {
                        let field = formState.fields[fieldKey];

                        if (fieldKey === action.fieldKey) {
                            field.isVisible = false;
                        }
                    });
                }
            }
        });
    }

    onFieldRemoved(state, action) {
        // If form is not defined in FormStore initial state
        if (!state.formDefinitions) {
            return;
        }

        Object.keys(state.formDefinitions).forEach((formKey) => {
            if (action.formKey === formKey) {
                let formDefinition = state.formDefinitions[formKey];

                Reflect.deleteProperty(state[formDefinition.formKey].fields, action.fieldKey);
                Reflect.deleteProperty(formDefinition.fieldDefinitions, action.fieldKey);
            }
        });
    }

    clearInvisibleFieldValues(state, formKey) {

        let formDefinition = state.formDefinitions[formKey];
        let formState = state[formKey];

        this.forEachField(formDefinition, (fieldDefinition, fieldKey) => {
            let field = formState.fields[fieldKey];

            if (!field.isVisible && !field.groupName) {
                field.value = fieldDefinition.defaultValue;
                field.errors = { hasErrors: false };
            }
        });
    }

    displayErrorsIfNeededAndMarkDownFormState(formDefinition, formState) {

        let needToShowAnyError = false;

        this.forEachField(formDefinition, (fieldDefinition, fieldKey) => {
            let field = formState.fields[fieldKey];

            field.showError = field.errors.hasErrors
                && (field.dirty || formState.submitBtnClickedSinceLastTimeFormWasValid);

            if (field.showError) {

                needToShowAnyError = true;
            }
        });

        formState.showMainError = needToShowAnyError && formState.submitBtnClickedSinceLastTimeFormWasValid;

        if (!formState.showMainError) {
            formState.submitBtnClickedSinceLastTimeFormWasValid = false;
        }

        formState.isValid = !needToShowAnyError;
    }

    runThroughRestrictions(fieldDefinition, fieldValue) {

        if (fieldDefinition.restrictors
            && fieldDefinition.restrictors.length
            && fieldDefinition.restrictors.length > 0) {

            fieldDefinition.restrictors.forEach((restrictor) => {
                fieldValue = restrictor(fieldValue);
            });
        }

        return fieldValue;
    }

    runThroughInputMasks(fieldDefinition, fieldValue, formState) {
        let maskedFieldValue = null;

        if (fieldDefinition.masks
            && fieldDefinition.masks.length
            && fieldDefinition.masks.length > 0) {

            maskedFieldValue = fieldValue;

            fieldDefinition.masks.forEach((mask) => {
                maskedFieldValue = mask(maskedFieldValue, formState);

            });
        }

        return maskedFieldValue;
    }

    clearValidatorErrors(field, onlyWhenHasValue = false) {
        if (onlyWhenHasValue) {
            if (field.value && field.value.length) {
                field.errors = {
                    list: [],
                    hasErrors: false
                };
            }
        } else {
            field.errors = {
                list: [],
                hasErrors: false
            };
        }
    }

    runThroughValidators(fieldDefinition, field, formState, condition) {

        // Make a copy of an array:
        let fullListOfValidators = fieldDefinition.validators ? fieldDefinition.validators.slice() : [];

        // For each field automatically add one "system" validator, which will take care of validation from additionalInfo
        // field (which may be prefilled by custom controls)
        fullListOfValidators.push(() => {

            let isValid = field.additionalInfo ? field.additionalInfo.isValid : true;
            return isValid ? null : { key: "componentCustomValidation" };
        });

        let validatorsThatNeedToBeValidated = [];

        for (let validatorMetaInfo of fullListOfValidators) {

            let shouldBeValidated = condition
                ? condition(validatorMetaInfo)
                : true;

            if (shouldBeValidated) {

                let validator = validatorMetaInfo.validator ? validatorMetaInfo.validator : validatorMetaInfo;

                validatorsThatNeedToBeValidated.push(validator);
            }
        }

        let willBeValidated = validatorsThatNeedToBeValidated.length > 0;

        let clearValidationErrors = !field.isVisible || willBeValidated;

        if (clearValidationErrors) {
            this.clearValidatorErrors(field);
        }

        if (willBeValidated) {
            for (let validator of validatorsThatNeedToBeValidated) {

                let error = validator(field.value, formState);

                if (error && error.key) {
                    field.errors.list.push(error);
                    field.errors.hasErrors = true;

                    if (!fieldDefinition.showAllErrors) {
                        break;
                    }
                }
            }
        }
    }

    setFieldInitialState(state, storeKey, formKey, fieldDefinition, fieldKey, fieldDefinitionKey, indexOfRepeatedField) {
        let formState = state[formKey];

        let isSensitiveInformation = fieldDefinition.isSensitiveInformation === true;

        let field = {
            groupName: fieldDefinition.groupName || null,
            validateOnAnyFieldChange: fieldDefinition.validateOnAnyFieldChange || false,
            value: fieldDefinition.defaultValue,
            handleOnChange: getHandlerForFormFieldValueChanged(storeKey, formKey, fieldKey, isSensitiveInformation, null),
            getOnChangeHndler: (valueGetter) => {
                return getHandlerForFormFieldValueChanged(storeKey, formKey, fieldKey, isSensitiveInformation, valueGetter);
            },
            handleOnEnterKeyDownToSubmitForm: function(event) {
                const enterKeyCode = 13;
                if (event.keyCode === enterKeyCode) {
                    event.preventDefault()
                    formState.handleOnSubmitBtnClicked();
                }
            },
            handleOnFieldRemoved: getHandlerForFormFieldRemoved(storeKey, formKey, fieldKey, fieldDefinitionKey, indexOfRepeatedField),
            errors: {},
            dirty: false,
            isVisible: !fieldDefinition.hideByDefault
        };

        if (fieldDefinition.isRepeatedField) {

            field.handleOnRepeatedFieldAdded = getHandlerForFormRepeatedFieldAdded(storeKey, formKey, fieldKey, fieldDefinitionKey, indexOfRepeatedField);
            field.handleOnRepeatedFieldRemoved = getHandlerForFormRepeatedFieldRemoved(storeKey, formKey, fieldKey, fieldDefinitionKey, indexOfRepeatedField);

            formState.fields[fieldDefinitionKey].push(field);
        }

        formState.fields[fieldKey] = field;
    }

    forEachField(formDefinition, action) {
        Object.keys(formDefinition.fieldDefinitions).forEach((fieldDefinitionKey) => {

            let fieldDefinition = formDefinition.fieldDefinitions[fieldDefinitionKey];

            if (fieldDefinition.isRepeatedField) {

                let repeatedFieldCount = fieldDefinition.repeatedFieldCount ? fieldDefinition.repeatedFieldCount : 1;

                for (let i = 0; i < repeatedFieldCount; i++) {
                    let fieldKey = fieldDefinitionKey + String(i);
                    action(fieldDefinition, fieldKey, fieldDefinitionKey, i);
                }
            } else {
                let fieldKey = fieldDefinitionKey;
                action(fieldDefinition, fieldKey, fieldDefinitionKey, null);
            }
        });
    }


    forEachFieldDefinition(formDefinition, action) {
        Object.keys(formDefinition.fieldDefinitions).forEach((fieldDefinitionKey) => {
            let fieldDefinition = formDefinition.fieldDefinitions[fieldDefinitionKey];
            action(fieldDefinition, fieldDefinitionKey);
        });
    }
}

let storeFormManager = new StoreFormManager();

export default storeFormManager;
