import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let backToAllOrdersListBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.BACK_TO_ALL_ORDERS_LIST_BTN_CLICKED
    });
};

export default backToAllOrdersListBtnClicked;
