import BaseStore from './BaseStore.js';
import ActionTypes from '../../actions/ActionTypes';
import storeFormManager from '../validation/StoreFormManager.js';

const GET_FORM_FIELD_VALUE_CHANGED_CFG = 'getFieldValueChangedHandlerCfg';
const GET_FORM_SUBMIT_BTN_CLICKED_CFG = 'getSubmitButtonClickedHandlerCfg';
const GET_FORM_REPEATED_FIELD_ADDED_CFG = 'getRepeatedFieldAddedHandlerCfg';
const GET_FORM_REPEATED_FIELD_REMOVED_CFG = 'getRepeatedFieldRemovedHandlerCfg';
const GET_FORM_FIELD_REMOVED_CFG = 'getFormFieldRemovedHandlerCfg';

class FormStore extends BaseStore {

    [ActionTypes.FORM_FIELD_VALUE_CHANGED](state, action, sharedStoreState) {
        storeFormManager.onFieldValueChanged(state, action, sharedStoreState);
        this.initializeHandlerIfRequired(GET_FORM_FIELD_VALUE_CHANGED_CFG, state, action, sharedStoreState);
    }

    [ActionTypes.FORM_SUBMIT_BTN_CLICKED](state, action, sharedStoreState) {
        storeFormManager.onSubmitBtnClicked(state, action, sharedStoreState, this);
        this.initializeHandlerIfRequired(GET_FORM_SUBMIT_BTN_CLICKED_CFG, state, action, sharedStoreState);
    }

    [ActionTypes.FORM_REPEATED_FIELD_ADDED](state, action, sharedStoreState) {
        storeFormManager.onRepeatedFieldAdded(state, action);
        this.initializeHandlerIfRequired(GET_FORM_REPEATED_FIELD_ADDED_CFG, state, action, sharedStoreState);
    }

    [ActionTypes.FORM_REPEATED_FIELD_REMOVED](state, action, sharedStoreState) {
        storeFormManager.onRepeatedFieldRemoved(state, action, sharedStoreState);
        this.initializeHandlerIfRequired(GET_FORM_REPEATED_FIELD_REMOVED_CFG, state, action, sharedStoreState);
    }

    [ActionTypes.FORM_FIELD_REMOVED](state, action, sharedStoreState) {
        storeFormManager.onFieldRemoved(state, action, sharedStoreState);
        this.initializeHandlerIfRequired(GET_FORM_FIELD_REMOVED_CFG, state, action, sharedStoreState);
    }

    initializeHandlerIfRequired(handlerName, state, action, sharedStoreState) {

        let instance = this;

        let handlerCfg = this[handlerName]
            ? this[handlerName]()
            : {};

        if (Array.isArray(handlerCfg) && handlerCfg.length) {
            handlerCfg.forEach(function(cfg) {
                if (cfg.storeKey && cfg.formKey && cfg.handler && action.storeKey && action.formKey) {
                    if (cfg.storeKey === action.storeKey && cfg.formKey === action.formKey) {
                        let cfgHandlerWithCorrectThis = cfg.handler.bind(instance);
                        cfgHandlerWithCorrectThis(state, action, sharedStoreState);
                    }
                }
            });

        }
    }
}

export default FormStore;
