import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let getHandlerForFormBackBtnClicked = function(storeKey, formKey) {
    return function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.FORM_BACK_BTN_CLICKED,
            storeKey: storeKey,
            formKey: formKey
        });
    };
};

export default getHandlerForFormBackBtnClicked;

