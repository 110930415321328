import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let saveVirtualCardStatus = function (orgId, orderId, data) {
    postToBackend(`/cards/save-virtual-card-status/${orgId}/${orderId}`,
        ActionTypes.SAVE_VIRTUAL_CARD_STATUS_SUCCESS,
        ActionTypes.SAVE_VIRTUAL_CARD_STATUS_ERROR,
        data
    );
};
export default saveVirtualCardStatus;
