import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';
import dispatcher from "../../../Dispatcher";

let showPin = function (orderId, data) {
    dispatcher.dispatch({
        type: ActionTypes.PIN_LOADING,
        orderId: orderId
    });
    postToBackend(`/cards/show-pin/${orderId}`,
        ActionTypes.SHOW_PIN_SUCCESS,
        ActionTypes.SHOW_PIN_ERROR,
        data
    );
};
export default showPin;
