import constants from '../Constants.js';

let ensureSlashAtTheStart = function(url) {
    return url && url.length > 0 && url[0] === '/'
        ? url
        : '/' + url;
};

let prepareAbsoluteUrl = function(url) {
    return constants.backendApplicationLocation + ensureSlashAtTheStart(url);
};

let prepareRouteUrl = function(routePath, skipHashAtTheStart, organizationCode) {

    // Currently hashHistory is turned on in router, that is why hash is added here:
    let prefix = skipHashAtTheStart ? '' : '/#';

    return organizationCode
        ? prefix + '/' + organizationCode + ensureSlashAtTheStart(routePath)
        : prefix + ensureSlashAtTheStart(routePath);
};

let prepareAbsoluteFrontendUrl = function(sharedStoreState, relativeUrl) {
    if (!sharedStoreState.authInfo || !sharedStoreState.authInfo.otherUiInfo.frontendUrl) {
        return '/';
    }

    let frontendUrl = sharedStoreState.authInfo.otherUiInfo.frontendUrl;

    let fullUrl = frontendUrl + relativeUrl;

    // By convention, full url in configs ends with '.../#/' and relativeUrl in frontend starts with '/', therefore this hack is needed:
    fullUrl = fullUrl.replace('#//', '#/');
    fullUrl = fullUrl.replace('#/#/', '#/');

    return fullUrl;
};

let getSignInRelativeUrl = function(returnUrl, signInEmail) {

    if (!returnUrl) {
        returnUrl = '/';
    }

    let baseUrl = constants.signInUrl + returnUrl;

    return signInEmail
        ? baseUrl + '&username=' + signInEmail
        : baseUrl;
};

let getSignOutRelativeUrl = function(returnUrl) {
    if (!returnUrl) {
        returnUrl = '/';
    }

    return constants.signOutUrl + returnUrl;
};

let getStrongAuthRelativeUrl = function(uiConfiguration, currentUserId, returnUrl, returnEvenInCaseOfError) {
    if (!returnUrl) {
        returnUrl = '/';
    }
    let pathToStrongAuth = uiConfiguration.servicePortal.strongAuthPage;
    pathToStrongAuth = pathToStrongAuth.replace('[USER_ID]', currentUserId);
    let path = pathToStrongAuth + returnUrl;
    if (returnEvenInCaseOfError) {
        path += '&redirect_even_in_case_of_error=True';
    }
    return path;
};

let getStrongAuthForAnonymousPath = function(uiConfiguration, returnUrl, dokobit=false) {
    let path = uiConfiguration.servicePortal.strongAuthenticationForAnonymous.strongAuthPage;

    if (dokobit) {
        path = uiConfiguration.servicePortal.strongAuthenticationForAnonymous.dokobitStrongAuthPage;
    }

    if (returnUrl) {
        path += `&redirect_uri_for_initiator=${returnUrl}`;
    }
    return path;
}

let getSmsAuthPath = function(uiConfiguration, returnUrl, username=false) {
    let path = uiConfiguration.servicePortal.smsAuthentication.smsLoginPage;
    if (returnUrl) {
        path += `?redirect_uri_for_initiator=${returnUrl}`;
    }

    if (username) {
        path += returnUrl ? `&username=${username}` : `?username=${username}`;
    }

    return path;
}

let openUrlInPopUpWindow = function(event, url, location='yes', height=window.innerHeight, width=800, scrollbars='yes', status='yes') {
    event.preventDefault();
    window.open(url, '_blank', 'location=' + location + ',height='+ height + ',width=' + width + ',scrollbars=' + scrollbars + ',status=' + status);
};


export default {
    prepareAbsoluteUrl: prepareAbsoluteUrl,
    prepareRouteUrl: prepareRouteUrl,
    prepareAbsoluteFrontendUrl: prepareAbsoluteFrontendUrl,
    getSignInRelativeUrl: getSignInRelativeUrl,
    getSignOutRelativeUrl: getSignOutRelativeUrl,
    getStrongAuthRelativeUrl: getStrongAuthRelativeUrl,
    getStrongAuthForAnonymousPath: getStrongAuthForAnonymousPath,
    openUrlInPopUpWindow: openUrlInPopUpWindow,
    getSmsAuthPath: getSmsAuthPath
};
