import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage} from 'react-intl';
import confirmOrderSignInBtnClicked from '../../actions/actionCreators/cards/ConfirmOrderSignInBtnClicked.js';
import constants from '../../Constants.js';
import Logo from '../../components/common/Logo.jsx';

class CardReviewLandingPage extends StoreComponent {

    renderWhenReady() {

        let displayButtonTextOfSignedInUser = this.state.cardReviewLandingPageStore.showButtonTextForSignedInUser;
        let manualIdSignInMessageId = displayButtonTextOfSignedInUser ? "profile.confirmOrder.signInWithSMSCode" : "profile.confirmOrder.signInWithManualId";

        return (
            <div className="mt-6 p-6 bg-light">
                <div className="p-5 bg-white">
                    <div className="logo-for-message mb-5 mt-3">
                        <Logo configuration={this.state.cardReviewLandingPageStore.configuration} />
                    </div>
                    <div>
                        <div>
                            <p>
                                <FormattedMessage
                                    id="profile.confirmOrder.secureIdentificationRequired"
                                    defaultMessage="When your company orders a new ID06 card for you, secure identification of cardholder is required through Bank ID or passport"
                                />.
                            </p>
                        </div>
                        <div className="mt-3">
                            <p className="mb-0">
                                <FormattedMessage
                                    id="profile.confirmOrder.clickSignIn"
                                    defaultMessage="Click „Sign in with BankID“ to identify yourself using BankID (or “Sign in with e-ID (non-Swedish users)”) and access your card order"
                                />.
                                &nbsp;
                                <FormattedMessage
                                    id="profile.confirmOrder.afterSuccessfullIdentification"
                                    defaultMessage="After successful identification you will be presented with your card order information and asked to confirm the order"
                                />.
                            </p>
                        </div>
                        <div className="mt-3">
                            <p className="mb-0">
                                <FormattedMessage
                                    id="profile.confirmOrder.ifYouDontHaveBankId"
                                    defaultMessage="If you don't have BankID (or other supported e-ID), please visit one of the Manual ID verification partners"
                                />.
                                &nbsp;
                                <FormattedMessage
                                    id="profile.confirmOrder.goToManual"
                                    values={{buttonText: <FormattedMessage id={manualIdSignInMessageId} defaultMessage="Sign in" />}}
                                    defaultMessage="After your successful identity verification and account registration, you will be able to sign in by clicking {buttonText}"
                                />.
                            </p>
                        </div>
                        <div className="my-4">
                            <button id="sign_in_to_confirm_order_with_gluu" className="btn btn-primary mt-3" type="button" role="button" onClick={(e) => confirmOrderSignInBtnClicked(e, constants.personVerificationSources.gluuProvision)}>
                                <FormattedMessage id="profile.confirmOrder.signInWithGluu" defaultMessage="Continue to Sign in" />
                            </button>
                        </div>
                        <div>
                            <p className="mb-0">
                                <FormattedMessage
                                    id="profile.confirmOrder.personalDataCollected"
                                    defaultMessage="Your personal data is collected, stored and processed in a safe and secure manner"
                                />.
                            </p>
                            <p className="mb-0">
                                <FormattedMessage
                                    id="profile.confirmOrder.read"
                                    defaultMessage="Read"
                                />
                                &nbsp;
                                <a href={this.state.cardReviewLandingPageStore.personDataPolicyLink} id="profile_confirm_order_personal_data_policy" target="_blank" rel="noopener noreferrer">
                                    <FormattedMessage
                                        id="profile.confirmOrder.personalDataPolicy"
                                        defaultMessage="ID06 General Terms and Conditions"
                                    />
                                </a>.
                            </p>
                        </div>
                        <div className="mt-3">
                            <p>
                                <FormattedMessage
                                    id="profile.confirmOrder.moreInfo"
                                    defaultMessage="More information about the new ID06 card -"
                                />
                                &nbsp;
                                <a href={this.state.cardReviewLandingPageStore.moreInfoLink} id="profile_confirm_order_more_info" target="_blank" rel="noopener noreferrer">{this.state.cardReviewLandingPageStore.moreInfoLink}</a>.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default CardReviewLandingPage;
