import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let reactivateCardOrder = function (orderId, data) {
    postToBackend(`/cards/reactivate-card-order/${orderId}`,
        ActionTypes.REACTIVATE_CARD_ORDER_SUCCESS,
        ActionTypes.REACTIVATE_CARD_ORDER_ERROR,
        data
    );
};
export default reactivateCardOrder;
