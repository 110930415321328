import ActionTypes from '../ActionTypes.js';
import {getFromBackend} from './generic/CallBackend.js';

let getConfig = function () {
    getFromBackend('/general/config',
        ActionTypes.CONFIG_GET_SUCCESS,
        ActionTypes.CONFIG_GET_ERROR);
};

export default getConfig;
