import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let languageSelectBtnClicked = function(lang) {
    dispatcher.dispatch({
        type: ActionTypes.LANGUAGE_SELECT_BTN_CLICKED,
        lang: lang
    });
};

export default languageSelectBtnClicked;