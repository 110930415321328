import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let terminateCardOrder = function (orderId, data) {
    postToBackend(`/cards/terminate-card-order/${orderId}`,
        ActionTypes.TERMINATE_CARD_ORDER_SUCCESS,
        ActionTypes.TERMINATE_CARD_ORDER_ERROR,
        data
    );
};
export default terminateCardOrder;
