import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import { PageHeader, FormRow, FormColumn, FormGroup, TextInput, ValidationMessages  } from '@vaultit/stv-utils-frontend';
import bactToPendingOrdersBtnClicked from '../../actions/actionCreators/cards/BactToPendingOrdersBtnClicked.js';
import bactToAllOrdersBtnClicked from '../../actions/actionCreators/cards/BackToAllOrdersListBtnClicked.js';

class CardStatusChangePage extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let cardRejectForm = this.state.pendingCardOrdersStore.cardRejectForm;
        let cardRejectFields = cardRejectForm ? cardRejectForm.fields : null;
        let currentOrderAction = this.state.pendingCardOrdersStore.currentOrderAction;
        const messages = defineMessages({
            rejectionReasonPlaceholder: {
                id: currentOrderAction + 'CardOrder.reason',
                description: 'Card status change reason field placeholder',
            },
            currentActionTitle: {
                id: currentOrderAction + "CardOrder.reasonTitle",
                description: "Input title"
            }
        });


        return (
            <div>
                <PageHeader
                    pageTitleMessageId={currentOrderAction + "CardOrder.title"}
                    sharedStore={this.state.shared}
                />

                {cardRejectForm
                ?
                    <form name="rejectCardOrderForm" noValidate="">
                        <FormRow>
                            <FormColumn width="12">

                                <FormRow>
                                    <FormColumn width="12">
                                        <FormGroup>
                                            <ValidationMessages field={cardRejectFields.rejectionReason}>
                                                <TextInput
                                                    id="change_card_status_reason"
                                                    field={cardRejectFields.rejectionReason}
                                                    messageId={formatMessage(messages.currentActionTitle)}
                                                    placeholder={formatMessage(messages.rejectionReasonPlaceholder) }
                                                    required={true}
                                                    disabled={false}
                                                />
                                            </ValidationMessages>
                                        </FormGroup>
                                    </FormColumn>
                                </FormRow>

                                <hr />

                                <FormRow>
                                    <FormColumn width="12">
                                        <FormGroup className="mt-3">
                                            <button
                                                id="change_card_status_button"
                                                className="btn btn-primary btn-lg mr-3"
                                                role="button"
                                                onClick={cardRejectForm.handleOnSubmitBtnClicked}
                                            >
                                                <FormattedMessage id={currentOrderAction + "CardOrder.rejectOrder"} defaultMessage="Reject" />
                                            </button>
                                            {currentOrderAction === 'reject'
                                            ?
                                                <button
                                                    id="change_card_status_back"
                                                    className="btn btn-default btn-lg mr-3"
                                                    role="button"
                                                    onClick={bactToPendingOrdersBtnClicked}
                                                >
                                                    <FormattedMessage id="rejectCardOrder.backToList" defaultMessage="Back" />
                                                </button>
                                            :
                                                <button
                                                    id="change_card_status_back"
                                                    className="btn btn-default btn-lg mr-3"
                                                    role="button"
                                                    onClick={bactToAllOrdersBtnClicked}
                                                >
                                                    <FormattedMessage id="rejectCardOrder.backToList" defaultMessage="Back" />
                                                </button>
                                            }
                                        </FormGroup>
                                    </FormColumn>
                                </FormRow>

                            </FormColumn>
                        </FormRow>
                    </form>
                : null
                }

            </div>
        );
    }

}
export default CardStatusChangePage;
