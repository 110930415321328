import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let backToWaitingOrdersBtnClicked = function() {
    dispatcher.dispatch({
        type: ActionTypes.BACK_TO_WAITING_ORDERS_BTN_CLICKED
    });
};

export default backToWaitingOrdersBtnClicked;
