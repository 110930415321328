import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let getHandlerForFormFieldRemoved = function(storeKey, formKey, fieldKey, fieldDefinitionKey, index) {
    return function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.FORM_FIELD_REMOVED,
            storeKey: storeKey,
            formKey: formKey,
            fieldKey: fieldKey,
            fieldDefinitionKey: fieldDefinitionKey,
            index: index
        });
    };
};

export default getHandlerForFormFieldRemoved;

