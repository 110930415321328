const constants = {
    backendApplicationLocation: '../api',
    errorMessageIdPrefix: 'errors.',
    generalErrorMessageId: 'general',
    badRequestErrorMessageId: 'badRequest',
    signInUrl: '/authentication/sign-in?redirect_uri=',
    signOutUrl: '/authentication/sign-out?redirect_uri=',
    frontEndRequestCsrfHeaderName: 'X-Csrf-Token',
    countryCodeSuffixForDuplicatedCodesAtTheTop: '_additional',
    countryCodeNOR: 'no',
    countryCodeBGR: 'bg',
    countryCodeHRV: 'hr',
    countryCodeCZE: 'cz',
    countryCodeDNK: 'dk',
    countryCodeFIN: 'fi',
    countryCodeNLD: 'nl',
    countryCodeSVK: 'sk',
    countryCodeROU: 'ro',
    countryCodeESP: 'es',
    countryCodeEST: 'ee',
    countryCodeSWE: 'se',
    countryCodePL: 'pl',
    countryCodeLV: 'lv',
    countryCodeLT: 'lt',
    defaultMaxFieldLength: 300,
    usersGlobalSearchMinLength: 3,
    registrationFindCompanyForESTOrgCodeLength: 8,
    registrationFindCompanyForLATVrgCodeLength: 11,
    registrationFindCompanyForPOLrgCodeLength: 10,
    passwordRegexp: "^.{PLACEHOLDER_FOR_PASSWORD_MIN_DIGITS,PLACEHOLDER_FOR_PASSWORD_MAX_DIGITS}$",
    passwordValidationMinimumDigits: 8,
    passwordValidationMaximumDigits: 64,
    emailRegexp: "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
    dateRegexp: "^([0-9]{4})-(0[1-9]|1[0-2])-([0-9]{2})$",
    nationalityCodeLength: 2,
    maxNoOfUsersThatCanBeAddedAtTime: 100,
    milisecondsToWaitAfterRegistrationSucessMessageDissapears: 5000,
    milisecondsToWaitForLoadingIndicatorDisplay: 100,
    tietoBillingOperatorCode: "003701011385",
    organizationsLimitInDropdownList: 5,
    chatScriptUrl: "",
    chatScriptId: "giosg-chat",
    hubSpotScriptId: "hs-script-loader",
    hubSpotScriptUrl: "//js.hs-scripts.com/1824358.js",
    swedishOrganizationCodeRegexpPatterns: [
        /^[\d]{6}-[\d]{4}$/,
        /^([\d]{10})$/,
        /^([\d]{12})$/,
        /^([\d]{8}-[\d]{4})$/
    ],
    swedishPersonalNumberRegexpPatterns: [
        /^([\d]{8}-[\d]{4})$/
    ],
    cookieNames: {
        redirectNoticePageWhereShowing: 'redirect_notice_page_where_showing',
        redirectNoticeBackToServiceUrl: 'redirect_notice_back_to_service_url',
        redirectNoticeRedirectedFrom: 'redirect_notice_redirected_from',
        csrfToken: 'csrf_token',
        signatoryRegisteredThroughEmailInvitation: 'signatory_registered_through_email_invitation',
        cardSupplierKey: 'redirect_back_to_card_supplier_url',
        userAuthenticatedNotSignatory: 'user_authenticated_not_signatory',
        userVisitedManualIdPartnerPage: 'user_visited_manual_id_partner_page',
        usedUiLanguage: 'used_ui_language'
    },
    invoicingTypes: {
        electronic: 'electronic',
        email: 'email',
        paper: 'paper'
    },
    invoicingEmailTypes: {
        newEmail: 'new',
        sameEmail: 'same'
    },
    invoicingPaperTypes: {
        newPaperInvoiceAddress: 'new',
        samePaperInvoiceAddress: 'same'
    },
    userTypes: {
        main: 'main',
        basic: 'basic',
        inactive: 'inactive'
    },
    permissions: {
        raportti: 'raportti_user',
        valvoja: 'valvoja_user',
        ilmoita: 'ilmoita_user',
        resellerManagement: 'rmt_user',
        global: 'global'
    },
    polishOrganisationNumberRegexpPattern: "^([0-9]{10})$",
    estonianOrganisationNumberRegexpPattern: "^([0-9]{8})$",
    latvianOrganisationNumberRegexpPattern: "^([0-9]{11})$",
    bulgarianOrganisationNumberRegexpPattern: "^([0-9]{9})$",
    croatianOrganisationNumberRegexpPattern: "^([0-9]{11})$",
    czechianOrganisationNumberRegexpPattern: "^([0-9]{8})$",
    danishOrganisationNumberRegexpPattern: "^([0-9]{8})$",
    netherlandsOrganisationNumberRegexpPattern: "^([0-9]{8})$",
    norwegianOrganisationNumberRegexpPattern: "^([0-9]{9})$",
    romanianOrganisationNumberRegexpPattern: "^([0-9]{7}|[0-9]{8})$",
    slovakOrganisationNumberRegexpPattern: "^([0-9]{8})$",
    spanishOrganisationNumberRegexpPattern: "^([a-zA-Z]{1}[0-9]{8})$",
    emails: {
        trustedPartnerInfo: 'asiakaspalvelu@tilaajavastuu.fi',
        customerService: 'customerservice@tilaajavastuu.fi'
    },
    pricingListLinks: {
        en: 'https://www.tilaajavastuu.fi/en/our-services/pricelist/',
        et: 'https://www.tilaajavastuu.fi/et/meie-teenused/hinnakiri/',
        fi: 'https://www.tilaajavastuu.fi/fi/palvelumme/hinnasto/',
        sv: 'https://www.tilaajavastuu.fi/sv/vara-tjanster/prislista/'
    },
    applicationStates: {
        pending: 'pending',
        active: 'active',
        terminated: 'terminated',
        payment_overdue: 'payment_overdue'
    },
    reliablePartner: {
        fi: 'http://kampanja.tilaajavastuu.fi/luotettava-kumppani-palvelusivu',
        sv: 'http://kampanja.tilaajavastuu.fi/palitlig-partner-tjansten',
        en: 'http://kampanja.tilaajavastuu.fi/reliable-partner-service',
        et: 'http://kampanja.tilaajavastuu.fi/usaldusvaarne-partner'
    },
    strongAuthErrorTypes: {
        currentNINAlreadyVerified: 'failed_current_nin_already_verified',
        strongAuthFailed: 'failed_strong_auth',
        mismatchBetweenValues: 'failed_nin_validation'
    },
    strongAuthSuccessType: 'nin_is_validated',
    allowToRegistryOrgInRegistry: [
        'ELIGIBLE_NOT_REGISTERED',
        'NOT_REGISTERED_ORG_FOUND'
    ],
    orgSuccessfullyFoundInRegistry: [
        'ELIGIBLE_NOT_REGISTERED',
        'NOT_REGISTERED_ORG_FOUND',
        'LEGACY_REGISTERED_PENDING',
        'LEGACY_REGISTERED_ACTIVE',
        'REGISTERED_PENDING'
    ],
    orgFoundAsNotActiveInRegistry: [
        'NOT_ELIGIBLE',
        'LEGACY_REGISTERED_TERMINATED',
        'REGISTERED_TERMINATED'
    ],
    orgFoundAsAlreadyRegistered: [
        'REGISTERED_ACTIVE'
    ],
    orgNotFound: [
        'NOT_FOUND'
    ],
    orgNotEligibleNoSignatories: [
        'NOT_ELIGIBLE_NO_SIGNATORIES'
    ],
    orgHasPaymentOverdue: [
        'REGISTERED_PAYMENT_OVERDUE',
        'LEGACY_REGISTERED_PAYMENT_OVERDUE'
    ],
    orgNotEligibleNoFTaxt: [
        'NOT_ELIGIBLE_NO_FTAX'
    ],
    personVerificationSources: {
        manualIDVerification: '365ID',
        bankID: 'bankid',
        bankIDProvision: 'bankid_provision',
        sms2FA: 'sms_2fa',
        dokobitIdentitygw: 'dokobit_identitygw',
        dokobitIdentitygwProvision: 'dokobit_identitygw_provision',
        frejaEid: 'freja_eid',
        frejaEidProvision: 'freja_eid_provision',
        gluu: 'gluu',
        gluuProvision: 'gluu_provision',
    },
    idVerificationType: {
        socialSecurityNumber: 'ssn',
        passportNumber: 'passport_number',
        idCardNumber: 'id_card_number',
    },
    bankIdSignTypes: {
        bankIdMobile: 'bank_id_mobile',
        bankIdSameDevice: 'bank_id_same_device'
    },
    orgSearchStatus: {
        anyStatus: '',
        invoicingDetails: 'status_invoicing_details',
        signContract: 'status_sign_contract',
        registered: 'status_registered'
    },
    cardStatuses: {
        waitingForHolderApproval: 'approved_by_personalizer',
        statusApprovedByHolder: 'ready_to_personalize',
        statusCancelledByHolder: 'cancelled_by_holder',
        readyToBeActivatedByHolder: 'personalized',
        statusCancelledByPersonalizer: 'rejected_by_personalizer',
        activated: 'activated',
        suspended: 'suspended',
        terminated: 'terminated',
        expired: 'expired'
    },
    status_types: {
        card: [
            'activated',
            'suspended',
            'terminated',
            'expired'
        ],
        order: [
            'ordered',
            'approved_by_orderer',
            'cancelled_by_orderer',
            'approved_by_personalizer',
            'ready_to_personalize',
            'printed',
            'printing_failed',
            'cancelled_by_holder',
            'cancelled_by_id_verifier',
            'personalized',
            'personalization_failed',
            'rejected_by_personalizer'
        ]
    },
    statusColors: {
        'ready_to_personalize': 'info',
        'cancelled_by_holder': 'danger',
        'cancelled_by_orderer': 'danger',
        'personalization_failed': 'danger',
        'printing_failed': 'danger',
        'cancelled_by_id_verifier': 'danger',
        'personalized': 'success',
        'rejected_by_personalizer': 'danger',
        'activated': 'success',
        'suspended': 'danger',
        'terminated': 'muted'
    },
    cardTypesToDisplay: {
        'ID06_Nordic2': 'ID06',
        'ID06_Nordic2a': 'ID06',
        'ID06_Nordic2/Visitor': 'ID06',
        'ID06_Nordic2/Temporary': 'ID06',
        'LMA_card': 'LMA'
    },
    whitelistedSignatorySavedByManualIdVerificationType: 'manual_id_verification',
    moreInformationCardLink: 'https://id06.se/support/kan-jag-sparra-och-eller-avsluta-mitt-id06-kort/'
};

export default constants;
