import prepareUrl from '../../../helpers/PrepareUrl.js';
import redirectManager from '../../../singletons/RedirectManager.js';

let redirectToErrorPage = function (msgId, organizationCode) {
    // No need to dispatch any action now, just navigate to error page as it was user action.
    // When entering the route, appropriate action will be dispatched by the router.
    let fullPath = prepareUrl.prepareRouteUrl('error/' + encodeURIComponent(msgId), false, organizationCode);
    redirectManager.registerNeededRedirect(fullPath, true);
};

export default redirectToErrorPage;
