import cookie from 'react-cookie';
import constants from '../Constants.js';

let csrfToken = {
    get: () => {
        return cookie.load(constants.cookieNames.csrfToken);
    }
};

export default csrfToken;

